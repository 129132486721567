import { styled } from "linaria/react";

export const AltimeterContainer = styled.div`
  margin-left: 20px;
  height: 250px;
  width: 60px;
  background-color: rgb(56, 50, 59);
  border-radius: 40px;
  padding: 0 10px;
  position: relative;
  overflow: hidden;
`;

export const AltimeterWrap = styled.div`
  position: absolute;
  z-index: 1;
  top: 70px;
`;

export const AltimeterAvatar = styled.div`
  position: absolute;
  left: 25px;
`;

export const AltimeterNotchRow = styled.div`
  position: relative;
  left: 5px;
`;

export const AltimeterNotchText = styled.div``;

export const AltimeterBigNotch = styled.div`
  height: 4px;
  width: 30px;
  background-color: white;
  margin-right: 3px;
`;

export const AltimeterSmallNotch = styled.div`
  height: 2px;
  width: 20px;
  background-color: white;
`;
