import { styled } from "linaria/react";

export const textColor = {
  default: "#343a40",
  success: "#28a745",
  danger: "#dc3545",
  primary: "#186dde",
  secondary: "#6c757d"
};

export const backgroundColor = {
  default: "#343a40",
  success: "#28a745",
  danger: "#dc3545",
  primary: "#186dde",
  secondary: "#6c757d"
};

export const borderColor = {
  default: "#e9ecef",
  success: "#28a745",
  danger: "#dc3545",
  primary: "#186dde",
  secondary: "#6c757d"
};

export const fontSize = {
  default: "14px",
  xs: "11px",
  sm: "12px",
  md: "14px",
  lg: "16px",
  xl: "18px"
};

export const SDKActions = styled.div`
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SDKButton = styled.button<{
  buttonType: "success" | "danger" | "info" | "outlined" | "text";
  wide?: boolean;
}>`
  border-radius: ${(props) => (props.wide ? "6px" : "60px")};
  padding: ${(props) => (props.wide ? "7px 24px" : "7px 18px")};
  color: ${(props) => (["text", "outlined"].includes(props.buttonType) ? "#000" : "#fff")};
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => (props.buttonType === "outlined" ? "#eee" : "transparent")};
  background-color: ${(props) =>
    props.buttonType === "success"
      ? "#0acc95"
      : props.buttonType == "info"
      ? "#0d6efd"
      : props.buttonType === "outlined"
      ? "#fff"
      : props.buttonType === "text"
      ? "transparent"
      : "#ef5350"};
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  margin-top: 5px;
  & + button {
    margin-left: 10px;
  }
`;

export const SDKList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SDKListItem = styled.div`
  cursor: pointer;
  color: black;
  overflow: hidden;
  height: auto;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-item: center;
  justify-content: center;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  margin-bottom: 5px;

  &:hover {
    background-color: rgba(#000, 0.1);
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Textarea = styled.textarea`
  display: block;
  margin: 10px 0;
  width: calc(100% - 20px);
  min-height: 100px;
  padding: 10px;
  max-width: 600px;
  border: 1px solid #c6c8ca;
  box-sizing: border-box;
`;

export const FormInput = styled.input`
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 5px 8px;
  border: 1px solid #c6c8ca;
  text-align: left;
  box-sizing: border-box;
`;

export const FormTextarea = styled.textarea`
  display: block;
  margin: 10px 0;
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #c6c8ca;
  box-sizing: border-box;
`;

export const Alert = styled.div<{
  alertType: "success" | "danger" | "info";
}>`
  max-width: 100%;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  background-color: ${(props) =>
    props.alertType === "success" ? "#d4edda" : props.alertType == "info" ? "#cce5ff" : "#f8d7da"};
  color: ${(props) => (props.alertType === "success" ? "#155724" : props.alertType == "info" ? "#004085" : "#721c24")};
`;

export const InputLabel = styled.label`
  margin-bottom: 5px;
  color: rgb(33, 37, 41);
  font-size: 16px;
  line-height: 24px;
  margin-top: 15px;
  display: block;
`;

export const Input = styled.input`
  display: block;
  width: calc(100% - 20px);
  margin: 0 0 22px;
  padding: 5px 8px;
`;
export const InlineInput = styled.input``;
export const SubmitButton = styled.button<{
  buttonType: "success" | "danger" | "info";
}>`
  width: 100%;
  padding: 7px 18px;
  color: #fff;
  background-color: ${(props) =>
    props.buttonType === "success" ? "#0acc95" : props.buttonType == "info" ? "#186dde" : "#ef5350"};
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  margin-top: 0;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: all !important;
  }
  & + button {
    margin-left: 10px;
  }
`;

export const Button = styled.button<{ active: boolean }>`
  text-align: center;
  height: 40px;
  border-radius: 20px;
  background-color: ${(props) => (!props.active ? "#0acc95" : "#ef5350")};
  border: 0;
  color: white;
  padding: 0 10px;
  cursor: pointer;
  margin-left: 10px;
`;

export const BlurView = styled.div`
  height: 100%;
  width: 100%;
  backdrop-filter: blur(15px);
  position: absolute;
  z-index: 1;
`;

export const BlackVisibilityView = styled.div`
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  h2,
  h3 {
    font-weight: bold;
    color: white;
    text-align: center;
  }
`;

export const StripesVisibilityView = styled.div`
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 4px;
  backdrop-filter: blur(15px);
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(255, 255, 255, 0.2) 10px,
    rgba(255, 255, 255, 0.2) 20px
  );
  font-size: 12px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const TopRight = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  > div + div {
    margin-left: 10px;
  }
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const BottomRight = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 3;
  display: flex;
  align-items: center;
  svg {
    fill: white;
  }
`;

export const SwitchWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const SwitchItem = styled.div`
  width: 50%;
  height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  white-space: nowrap;
  > div {
    margin-right: 10px;
  }
`;

export const CircleButton = styled.div<{
  buttonType: "primary" | "default" | "danger";
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-left: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  position: relative;
  border-color: ${(props) =>
    props.buttonType === "primary" ? "#007bff" : props.buttonType === "danger" ? "#dc3545" : "#eee"};
  background-color: ${(props) =>
    props.buttonType === "primary" ? "#007bff" : props.buttonType === "danger" ? "#dc3545" : "#fff"};
  svg {
    fill: ${(props) => (props.buttonType === "default" ? "#000" : "#fff")};
  }
`;

export const DropDownList = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: absolute;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #eee;
  width: 150px;
  background: white;
  top: 45px;
  left: 3px;
  max-height: 250px;
  overflow: auto;
  > div + div {
    margin-top: 5px;
    font-size: 16px;
  }
`;
export const DropDownItem = styled.div`
  cursor: pointer;
  color: black;
  padding: 0.25rem 0.5rem;
  height: 25px;
  vertical-align: middle;
  display: inline-block;
  line-height: 25px;
  position: relative;
  :hover {
    background: #e9ecef;
  }
`;

export const FlexCenterRow = styled.div<{ justify: "start" | "space-between" | "end" | "center" }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.justify || "start"};
`;

export const RelativeDiv = styled.div`
  position: relative;
`;

export const ItalicSpan = styled.span`
  font-style: italic;
`;

export const UnderlinedSpan = styled.span`
  text-decoration: underline;
`;

export const BadgeCount = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  line-height: 12px;
  border-radius: 50%;
  background-color: red;
  font-size: 12px;
  right: 0;
  top: 0;
  color: white;
  text-align: center;
`;
