import React, { FunctionComponent, useState } from "react";
import { ConveyApi, IAgencySettingPayload, ConveySocket } from "@utils";
import {
  AdminCRUDForm,
  CRUDSubmit,
  CRUDWrap,
  LogoColumn,
  CRUDLabel,
  FieldColumn,
  InputColumn,
  CRUDTab
} from "./adminCRUDStyles";
import Switch from "react-switch";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { writeExpire } from "@utils/helpers";
import "react-phone-number-input/style.css";
import { FormInput } from "@components/styles";
import { Spinner } from "@components/Spinner";
import { SwitchWrap, SwitchItem } from "@components/styles";
import { ADMIN_CRUD_LAYOUT } from "./constants";
import { CRUD } from "@components/CRUD";

interface IAdminCRUDProps {
  app: IRootState["app"];
  agency: IRootState["agency"];
  callback?: (eventData: Record<any, any>) => void;
}

const XAdminCRUD: FunctionComponent<IAdminCRUDProps> = ({ callback, agency }: IAdminCRUDProps) => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Conversations & Messaging");
  const [name, setName] = useState(agency?.agency_name);
  const [logo, setLogo] = useState<string | ArrayBuffer>(agency?.settings?.agencyLogo);
  const [domain, setDomain] = useState(agency?.agency_domain);
  const [twoFA, setTwoFA] = useState(!!agency?.settings?.twoFAForAll);
  const [autoEnroll, setAutoEnroll] = useState(!!agency?.settings?.autoEnrollUserByEmail);
  const [payload, setPayload] = useState<IAgencySettingPayload[]>([]);

  const submit = async () => {
    setLoading(true);
    const updateSettingsResponse = await ConveyApi.updateAgencySettings({
      payload: [
        ...[
          { name: "agency_name", value: name, component: "agency" },
          { name: "agency_domain", value: domain, component: "agency" },
          { name: "agencyLogo", value: logo, component: "agency" },
          { name: "twoFAForAll", value: twoFA ? "true" : "false", component: "agency" },
          { name: "autoEnrollUserByEmail", value: autoEnroll ? "true" : "false", component: "agency" }
        ],
        ...payload
      ]
    });
    await ConveyApi.updateAgency({
      agency_name: name,
      agency_domain: domain
    });
    const basicDataKey = `basicData-${agency.agency_id}`;
    const basicData = await ConveyApi.getBasicData();
    const basicDataExpiry = new Date().getTime() + 30 * 60 * 1000;
    writeExpire(basicDataKey, basicData, basicDataExpiry);
    callback && callback(updateSettingsResponse);
    setLoading(false);
  };

  const uploadHandler = () => {
    const files = (document.getElementById("logoFile") as HTMLInputElement).files;

    if (files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogo(e.target.result);
      };
      reader.readAsDataURL(files[0]);

      ConveySocket.getInstance()
        .getManager()
        .emit(
          ConveySocket.UPLOAD_FILE,
          files[0],
          `logos/${agency.agency_id}/${files[0].name}`,
          files[0].type,
          async (status, fileUrl) => {
            if (status === true) {
              setLogo(fileUrl);
            }
          }
        );
    }
  };

  const openUploader = () => {
    document.getElementById("logoFile").click();
  };

  const convertLogo = (logo: string | ArrayBuffer): string => {
    if (typeof logo === "string") {
      return logo;
    }

    const arrayBufferView = new Uint8Array(logo);
    const blob = new Blob([arrayBufferView], { type: "image/jpeg" });
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(blob);
  };

  return (
    <AdminCRUDForm
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <CRUDWrap>
        <LogoColumn>
          {logo && <img src={convertLogo(logo)} style={{ width: 220 }} />}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              openUploader();
            }}
          >
            Change Agency Logo
          </a>
          <input
            id="logoFile"
            hidden={true}
            type="file"
            onChange={uploadHandler}
            accept="image/png, image/gif, image/jpeg"
          />
        </LogoColumn>
        <FieldColumn>
          <CRUDWrap>
            <InputColumn>
              <CRUDLabel>Tenant Name</CRUDLabel>
              <FormInput
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Tenant Name"
                value={name}
                style={{
                  boxSizing: "border-box",
                  padding: 6,
                  width: "100%",
                  maxWidth: 600,
                  borderRadius: 4,
                  border: "1px solid hsl(0, 0%, 80%)",
                  minHeight: 38,
                  flex: 1
                }}
              />
            </InputColumn>
            <InputColumn>
              <SwitchWrap>
                <SwitchItem style={{ marginLeft: 20, width: "100%", marginTop: 50 }}>
                  <Switch
                    onChange={(checked) => {
                      setTwoFA(checked);
                    }}
                    checked={twoFA}
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                  Enable Two Factor Authentication (2FA)
                </SwitchItem>
              </SwitchWrap>
            </InputColumn>
          </CRUDWrap>
          <CRUDWrap>
            <InputColumn>
              <CRUDLabel>Agency Domain</CRUDLabel>
              <FormInput
                onChange={(e) => {
                  setDomain(e.target.value.slice(0, 5));
                }}
                placeholder="Agency Domain"
                value={domain}
                style={{
                  boxSizing: "border-box",
                  padding: 6,
                  width: "100%",
                  maxWidth: 600,
                  borderRadius: 4,
                  border: "1px solid hsl(0, 0%, 80%)",
                  minHeight: 38,
                  flex: 1
                }}
              />
            </InputColumn>
            <InputColumn>
              <SwitchWrap>
                <SwitchItem style={{ marginLeft: 20, width: "100%", marginTop: 50 }}>
                  <Switch
                    onChange={(checked) => {
                      setAutoEnroll(checked);
                    }}
                    checked={autoEnroll}
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                  Automatically add users by domain
                </SwitchItem>
              </SwitchWrap>
            </InputColumn>
          </CRUDWrap>
        </FieldColumn>
      </CRUDWrap>
      <div>
        <CRUDWrap>
          {ADMIN_CRUD_LAYOUT.tabs.map((tab) => {
            return (
              <CRUDTab
                key={tab.title}
                active={activeTab === tab.title}
                onClick={() => {
                  setActiveTab(tab.title);
                }}
              >
                {tab.title}
              </CRUDTab>
            );
          })}
        </CRUDWrap>
        {ADMIN_CRUD_LAYOUT.tabs.map((tab) => {
          return (
            <CRUD
              key={tab.title}
              active={activeTab === tab.title}
              tab={tab}
              onBlur={(_title, data) => {
                setPayload([...payload, ...[data]]);
              }}
              defaultValues={agency?.settings}
            />
          );
        })}
      </div>
      <CRUDSubmit type="submit" disabled={loading}>
        Save Settings
        {loading && <Spinner />}{" "}
      </CRUDSubmit>
    </AdminCRUDForm>
  );
};

const mapStateToProps = ({ app, agency }: IRootState) => ({
  app,
  agency
});

export const AdminCRUD = connect(mapStateToProps, {})(XAdminCRUD);
