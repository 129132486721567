import { createRoot, RootType } from "react-dom/client";
import React from "react";
import { VideoRoom, Provider } from "@components";
import { IDialectEntity, IVideoRoomResponse, IVideoRoomParticipant, IConveyVideoRoom } from "@utils/types";
import { getStore } from "@utils";
import { appendAsChild } from "@lib/utils/helpers";
import { appendContainer } from "@actions";

export interface IConveyVideoOpts {
  $container: string;
  $transcriptionContainer?: string;
  videoToken: string;
  roomName: string;
  conversationId: string;
  identity: string;
  dialects: IDialectEntity[];
  agencyId: string;
  recipientId: string;
  recipientNumber: string;
  phoneNumber: string;
  currentRooms: IVideoRoomResponse[];
  roomId: string;
  correlationId: string;
  blur: boolean;
  enableAudio: boolean;
  enableVideo: boolean;
  enableTranscribe: boolean;
  recipientFlow: boolean;
  root?: RootType;
  onError: (error: Error) => void;
  onVideoRoomEnded: (room: IConveyVideoRoom) => void;
  onVideoRoomParticipantRemoved: (participant: IVideoRoomParticipant) => void;
  onVideoRoomParticipantAdded: (participant: IVideoRoomParticipant) => void;
}

interface IConveyVideoProps {
  $element: HTMLElement;
  $transcriptionContainer?: string;
  videoToken: string;
  roomName: string;
  conversationId: string;
  identity: string;
  dialects: IDialectEntity[];
  agencyId: string;
  recipientId: string;
  phoneNumber: string;
  currentRooms: IVideoRoomResponse[];
  roomId: string;
  correlationId: string;
  blur: boolean;
  enableAudio: boolean;
  enableVideo: boolean;
  enableTranscribe: boolean;
  recipientFlow: boolean;
  root?: RootType;
  onError: (error: Error) => void;
  onVideoRoomEnded: (room: IConveyVideoRoom) => void;
  onVideoRoomParticipantRemoved: (participant: IVideoRoomParticipant) => void;
  onVideoRoomParticipantAdded: (participant: IVideoRoomParticipant) => void;
}

export class ConveyVideo implements IConveyVideoProps {
  $element: HTMLElement;
  $transcriptionContainer?: string;
  videoToken: string;
  roomName: string;
  conversationId: string;
  identity: string;
  dialects: IDialectEntity[];
  agencyId: string;
  recipientId: string;
  phoneNumber: string;
  recipientNumber: string;
  currentRooms: IVideoRoomResponse[];
  roomId: string;
  correlationId: string;
  blur: boolean;
  enableAudio: boolean;
  enableVideo: boolean;
  enableTranscribe: boolean;
  recipientFlow: boolean;
  leaveRoomExec: number;
  root?: RootType;
  ref: typeof VideoRoom | null;
  mounted: boolean;
  onError: (error: Error) => void;
  onVideoRoomEnded: (room: IConveyVideoRoom) => void;
  onVideoRoomParticipantRemoved: (participant: IVideoRoomParticipant) => void;
  onVideoRoomParticipantAdded: (participant: IVideoRoomParticipant) => void;

  constructor(params: IConveyVideoOpts) {
    const $element = document.getElementById(params.$container);
    this.mounted = false;

    const { fullId, $newElement } = appendAsChild(params.phoneNumber, $element, params.$container, "video");

    getStore().dispatch(
      appendContainer({ phoneNumber: params.phoneNumber, id: fullId, status: "visible", experience: "video" })
    );

    this.leaveRoomExec = 0;
    if ($newElement && params.videoToken) {
      this.agencyId = params.agencyId;
      this.$element = $newElement;
      this.$transcriptionContainer = params.$transcriptionContainer;
      this.videoToken = params.videoToken;
      this.phoneNumber = params.phoneNumber;
      this.roomName = params.roomName;
      this.dialects = params.dialects;
      this.conversationId = params.conversationId;
      this.identity = params.identity;
      this.roomId = params.roomId;
      this.recipientId = params.recipientId;
      this.recipientNumber = params.recipientNumber;
      this.currentRooms = params.currentRooms;
      this.correlationId = params.correlationId;
      this.root = params.root;
      this.blur = params.blur;
      this.enableAudio = params.enableAudio;
      this.enableVideo = params.enableVideo;
      this.enableTranscribe = params.enableTranscribe;
      this.recipientFlow = params.recipientFlow;
      this.onError = params.onError;
      this.onVideoRoomEnded = params.onVideoRoomEnded;
      this.onVideoRoomParticipantAdded = params.onVideoRoomParticipantAdded;
      this.onVideoRoomParticipantRemoved = params.onVideoRoomParticipantRemoved;
      this.initVideo();
    }
  }

  endVideoCall() {
    // @ts-ignore
    window.ConveyCurrentVideoRooms[this.conversationId].execEndRoom &&
      // @ts-ignore
      window.ConveyCurrentVideoRooms[this.conversationId].execEndRoom(this.conversationId);
    // @ts-ignore
    return window.ConveyCurrentVideoRooms[this.conversationId]?.execEndRoom;
  }

  leaveVideoCall() {
    // @ts-ignore
    window.ConveyCurrentVideoRooms[this.conversationId].execLeaveRoom &&
      // @ts-ignore
      window.ConveyCurrentVideoRooms[this.conversationId].execLeaveRoom(this.conversationId);
    // @ts-ignore
    return window.ConveyCurrentVideoRooms[this.conversationId]?.execLeaveRoom;
  }

  initVideo() {
    if (!this.root || !this.mounted) {
      this.root = createRoot(this.$element!);
      this.mounted = true;
    }

    this.root.render(
      <Provider>
        <VideoRoom
          $transcriptionContainer={this.$transcriptionContainer}
          videoToken={this.videoToken}
          roomName={this.roomName}
          dialects={this.dialects}
          conversationId={this.conversationId}
          identity={this.identity}
          agencyId={this.agencyId}
          phoneNumber={this.phoneNumber}
          recipientId={this.recipientId}
          recipientNumber={this.recipientNumber}
          correlationId={this.correlationId}
          blur={this.blur}
          enableAudio={this.enableAudio}
          enableVideo={this.enableVideo}
          enableTranscribe={this.enableTranscribe}
          recipientFlow={this.recipientFlow}
          roomId={this.roomId}
          onError={this.onError}
          onVideoRoomEnded={this.onVideoRoomEnded}
          onVideoRoomParticipantAdded={this.onVideoRoomParticipantAdded}
          onVideoRoomParticipantRemoved={this.onVideoRoomParticipantRemoved}
          ref={(element) => {
            // @ts-ignore
            window.ConveyCurrentVideoRooms ||= {};
            // @ts-ignore
            window.ConveyCurrentVideoRooms[this.conversationId] = element;
          }}
        />
      </Provider>
    );
  }

  destroy() {
    if (this.root) {
      this.root.unmount();
      this.mounted = false;
      this.root = null;
      this.agencyId = null;
      this.$element = null;
      this.$transcriptionContainer = null;
      this.videoToken = null;
      this.phoneNumber = null;
      this.roomName = null;
      this.dialects = null;
      this.conversationId = null;
      this.identity = null;
      this.roomId = null;
      this.recipientId = null;
      this.recipientNumber = null;
      this.currentRooms = null;
      this.correlationId = null;
      this.blur = null;
      this.enableAudio = null;
      this.enableVideo = null;
      this.enableTranscribe = null;
      this.recipientFlow = null;
    }
  }

  getRoot() {
    return this.root;
  }
}
