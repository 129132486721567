import queryString from "query-string";
import { CEndpoints, IConveyApiOpts, IDialectEntity, IMessageEntity, IConversationEntity } from "@utils/types";
import { getStore, getStoreState } from "@utils";
import { store } from "@utils/helpers";
import { setAgency, setConversationEntity, setOperatorId } from "@actions";

export interface ICreateConversationParams {
  phoneNumber?: string;
  language?: string;
  message?: string;
  operatorId?: string;
  channel?: string;
  sipId?: string;
  providerNumber?: string;
  direction?: string;
}

interface IPostConversationParams {
  label: string;
  recipients: [
    {
      number: string;
      label: string;
    }
  ];
  message?: string;
  language?: string;
  operator_id?: string;
  channel?: string;
  sip_id?: string;
  number?: string;
  direction?: string;
}

interface IUpdateAgentParams {
  conversationId: string;
  agentId: string;
}

interface IGetAttachmentParams {
  messageId: string;
}

interface IGetVideoRoomsParams {
  conversationId?: string;
  recipient?: string;
  status: string;
}

interface IGetMessagesParams {
  conversationId: string;
}

interface ICreateTextTranslateParams {
  text: string;
  language: string;
}

interface IDeleteVideoRoomParams {
  roomId: string;
}

interface IBindUserAgencyParams {
  sessionId: string;
}

interface ICreateVideoRoomProps {
  conversationId: string;
  recipientId: string;
  transcribe: boolean;
  record: boolean;
  blur: boolean;
  enableAudio: boolean;
  enableVideo: boolean;
}

interface ICreateVideoRoomTokenParams {
  videoRoomId: string;
  participant: string;
}

interface ICreateConversationTokensParams {
  recipientId: string;
  phoneNumber: string;
  conversationId: string;
}

interface ICreateVideoTokensParams {
  videoRoomId: string;
  recipientId?: string;
  phoneNumber?: string;
  conversationId: string;
  invitationMessage: string;
  enableTranscription: boolean;
  enableVideo: boolean;
  enableAudio: boolean;
  blur: boolean;
  performVideoRecord: boolean;
  sendInvitation?: boolean;
  anonymous?: boolean;
}

interface ICreateMessageParams {
  conversationId: string;
  body: string;
  phoneNumber: string;
  recipient?: boolean;
  transcribe?: boolean;
  ttsActive?: boolean;
}

interface IPutRecipientLanguageParams {
  recipientId: string;
  language: string;
}

interface IPutRecipientGeolocationsParams {
  recipientId: string;
  lat: number;
  lng: number;
  latlng_accuracy?: number;
  altitude?: number;
  altitude_accuracy?: number;
}

interface ISpeechSynthesisParams {
  inputText: string;
  outputLanguage: string;
  neuralName?: string;
  correlationId: string;
  talkback: string;
}

interface ILanguageSelectionParams {
  outputLanguage: string;
  neuralName: string;
}

interface ICreateLanguageIdentificationParams {
  fileUrl: string;
  conversationId: string;
  agencyId: string;
  identity: string;
}

interface ILanguageIdentificationParams {
  correlationId: string;
  candidateLanguages: string[];
}

interface ISearchConversationsParams {
  recipient?: string;
  status?: string;
}

interface ICloseConversationParams {
  id: string;
  message?: string;
}

interface ICloseConversationPayload {
  message_body?: string;
}

interface IRequestLocationParams {
  conversationId: string;
  recipientId: string;
  agencyId: string;
  phoneNumber: string;
  message: string;
}

export interface IAgencySettingPayload {
  name: string;
  value: any;
  component: string;
}

interface IPutAgencySettingsParams {
  payload: IAgencySettingPayload[];
}

interface IPutAgencyParams {
  agency_name: string;
  agency_domain: string;
}

interface IDeleteVideoRoomParticipantParams {
  roomId: string;
  participantId: string;
}

export class ConveyApi {
  static agencyId: string;
  static agencySecret: string;
  static accessToken: string;
  static operatorId: string;
  static userJwt: string;
  static voiceDialects: IDialectEntity[];
  static textDialects: IDialectEntity[];

  static init(options: IConveyApiOpts) {
    ConveyApi.agencyId = options.agencyId;
    ConveyApi.agencySecret = options.agencySecret;
    ConveyApi.userJwt = options.userJwt;
  }

  static async getMachineAccessToken() {
    const tokenResponse = await ConveyApi.createAccessToken();
    if (tokenResponse === false) {
      return { error: "Critical Error: Failed to create machine token. Agency not provisioned for SDK access." };
    } else {
      if (tokenResponse?.data?.operator_id) {
        ConveyApi.operatorId = tokenResponse.data.operator_id;
        store().setItem("operatorId", JSON.stringify(ConveyApi.operatorId));
        getStore().dispatch(setOperatorId(ConveyApi.operatorId));
      }
      return tokenResponse?.data?.token;
    }
  }

  static async getBasicData() {
    const agency = await ConveyApi.getAgency();
    if (agency === false) {
      return { error: "Critical Error: Failed to retrieve agency settings. SDK initialization failed." };
    }
    getStore().dispatch(setAgency(agency));
    ConveyApi.voiceDialects = await ConveyApi.getDialects({ voice: "true", text: "false" }).catch(() => []);
    ConveyApi.textDialects = await ConveyApi.getDialects({ voice: "false", text: "true" }).catch(() => []);
    const hasSSO =
      !!agency.settings["sdk:apiAudience"] &&
      !!agency.settings["sdk:ssoHost"] &&
      !!agency.settings["sdk:ssoClientId"] &&
      agency.settings["sdk:apiAudience"] !== "null" &&
      agency.settings["sdk:ssoHost"] !== "null" &&
      agency.settings["sdk:ssoClientId"] !== "null";

    return {
      agencyId: ConveyApi.agencyId,
      agencyName: agency.agency_name,
      voiceDialects: ConveyApi.voiceDialects,
      textDialects: ConveyApi.textDialects,
      sso: hasSSO
        ? {
            apiAudience: agency.settings["sdk:apiAudience"],
            ssoClientId: agency.settings["sdk:ssoClientId"],
            ssoHost: agency.settings["sdk:ssoHost"]
          }
        : undefined
    };
  }

  static async createVideoRoom({
    conversationId,
    recipientId,
    transcribe,
    record,
    blur,
    enableAudio,
    enableVideo
  }: ICreateVideoRoomProps) {
    const response = await fetch(ConveyApi.endpoint("postVideoRooms"), {
      method: "POST",
      headers: ConveyApi.getHeaders(),
      body: JSON.stringify({
        conversation_id: conversationId,
        recipient_id: recipientId,
        transcription: transcribe,
        record,
        blur,
        enable_audio: enableAudio,
        enable_video: enableVideo
      })
    });
    const json = await response.json();
    return json?.data;
  }
  static async getSourceMessages({
    source,
    conversationId
  }: {
    source: string;
    conversationId: string;
  }): Promise<IMessageEntity[]> {
    const append = source ? `?${queryString.stringify({ source, conversationId })}` : ``;
    const response = await fetch(ConveyApi.endpoint("getSourceMessages", {}) + append, {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async getMessages({ conversationId }: IGetMessagesParams) {
    const response = await fetch(ConveyApi.endpoint("getMessages", { id: conversationId }), {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async getVideoRooms(queryParams: IGetVideoRoomsParams) {
    const append = `?${queryString.stringify(queryParams)}`;
    const response = await fetch(ConveyApi.endpoint("getVideoRooms") + append, {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async getVideoRoomParticipants({ roomId }: IDeleteVideoRoomParams) {
    const append = `?${queryString.stringify({
      participantStatus: "connected"
    })}`;
    const response = await fetch(ConveyApi.endpoint("getVideoRoomParticipants", { room_id: roomId }) + append, {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async getVideoRoom({ roomId }: IDeleteVideoRoomParams) {
    const response = await fetch(ConveyApi.endpoint("getVideoRoom", { room_id: roomId }), {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    if (response.ok) {
      const json = await response.json();
      return json?.data;
    } else {
      return null;
    }
  }

  static async deleteVideoRoom({ roomId }: IDeleteVideoRoomParams) {
    const response = await fetch(ConveyApi.endpoint("deleteVideoRooms", { room_id: roomId }), {
      method: "DELETE",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async deleteVideoRoomParticipant({ roomId, participantId }: IDeleteVideoRoomParticipantParams) {
    await fetch(ConveyApi.endpoint("deleteVideoRoomParticipants", { room_id: roomId, participantId: participantId }), {
      method: "DELETE",
      headers: ConveyApi.getHeaders()
    });
  }

  static async createVideoRoomToken({ videoRoomId, participant }: ICreateVideoRoomTokenParams) {
    const response = await fetch(ConveyApi.endpoint("postVideoRoomTokens", { video_room_id: videoRoomId }), {
      method: "PUT",
      headers: ConveyApi.getHeaders(),
      body: JSON.stringify({
        participant
      })
    });
    const json = await response.json();
    return {
      status: response.status,
      data: json?.data
    };
  }

  static async createConversationToken({ recipientId, phoneNumber, conversationId }: ICreateConversationTokensParams) {
    const response = await fetch(ConveyApi.endpoint("postTokens"), {
      method: "POST",
      headers: ConveyApi.getHeaders(),
      body: JSON.stringify({
        service_type: "joinConversation",
        recipient_id: recipientId,
        token_data: {
          conversation_id: conversationId,
          recipient: phoneNumber
        },
        recipient: {
          number: phoneNumber
        }
      })
    });
    const json = await response.json();
    return json?.data;
  }

  static async getToken({ token }) {
    const response = await fetch(ConveyApi.endpoint("getToken", { id: token }), {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    ConveyApi.accessToken = json.data.access_token;
    ConveyApi.agencyId = json.data.agency_id;
    return json?.data;
  }

  static async createVideoTokens({
    videoRoomId,
    recipientId,
    phoneNumber,
    conversationId,
    invitationMessage,
    enableTranscription,
    enableVideo,
    enableAudio,
    blur,
    performVideoRecord,
    sendInvitation = true,
    anonymous = false
  }: ICreateVideoTokensParams) {
    const payload = anonymous
      ? {
          service_type: "createVideoRoomToken",
          token_data: {
            conversation_id: conversationId,
            room_id: videoRoomId,
            recipient: "Authorized Participant",
            anonymous: true
          }
        }
      : {
          service_type: "createVideoRoomToken",
          recipient_id: recipientId,
          send_invitation: sendInvitation,
          invitation_message: invitationMessage,
          token_data: {
            conversation_id: conversationId,
            room_id: videoRoomId,
            recipient: phoneNumber,
            enableTranscription,
            enableVideo,
            enableAudio,
            blur,
            performVideoRecord
          },
          recipient: {
            number: phoneNumber
          }
        };

    const response = await fetch(ConveyApi.endpoint("postTokens", { video_room_id: videoRoomId }), {
      method: "POST",
      headers: ConveyApi.getHeaders(),
      body: JSON.stringify(payload)
    });
    const json = await response.json();
    return json?.data;
  }

  static async postTextTranslate({ text, language }: ICreateTextTranslateParams) {
    const response = await fetch(ConveyApi.endpoint("postTextTranslate"), {
      method: "POST",
      headers: ConveyApi.getHeaders(),
      body: JSON.stringify({
        output_dialect: language,
        input_text: text
      })
    });
    const json = await response.json();
    const results = json?.data
      ? json.data.filter((element) => {
          return element.output_text;
        })
      : [];
    return results[0];
  }

  static async postRecipientGeoLocations({ recipientId, lat, lng }: IPutRecipientGeolocationsParams) {
    const response = await fetch(
      ConveyApi.endpoint("putRecipientGeolocations", {
        id: recipientId
      }),
      {
        method: "POST",
        headers: ConveyApi.getHeaders(true),
        body: JSON.stringify({ lat, lng })
      }
    );
    const json = await response.json();
    return json?.data;
  }

  static async putRecipientLanguage({ recipientId, language }: IPutRecipientLanguageParams) {
    const response = await fetch(
      ConveyApi.endpoint("putRecipientLanguage", {
        recipient_id: recipientId,
        language
      }),
      {
        method: "PUT",
        headers: ConveyApi.getHeaders()
      }
    );
    const json = await response.json();
    return json?.data;
  }

  static async createMessage({
    phoneNumber,
    conversationId,
    body,
    recipient,
    transcribe,
    ttsActive
  }: ICreateMessageParams) {
    const direction = recipient ? "inbound" : "outbound";
    const response = await fetch(ConveyApi.endpoint("postMessages"), {
      method: "POST",
      headers: ConveyApi.getHeaders(),
      body: JSON.stringify({
        direction,
        body,
        conversation_id: conversationId,
        recipient_number: phoneNumber,
        ...(recipient ? { source: "chat" } : {}),
        ...(transcribe ? { source: "transcribe" } : {}),
        ...(ttsActive ? { source: "voice" } : {})
      })
    });
    const json = await response.json();
    return json?.data;
  }

  static async getConversation({ id }) {
    const response = await fetch(ConveyApi.endpoint("getConversation", { id }), {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async getConversationSummary({ id }) {
    const response = await fetch(ConveyApi.endpoint("getConversationSummary", { id }), {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async getConversations({ recipient, status }: ISearchConversationsParams): Promise<IConversationEntity[]> {
    const queryParams: ISearchConversationsParams = {};
    if (recipient) {
      queryParams.recipient = recipient;
    }
    if (status) {
      queryParams.status = status;
    }
    const append = `?${queryString.stringify(queryParams)}`;
    const response = await fetch(ConveyApi.endpoint("createConversation") + append, {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async createConversation({
    phoneNumber,
    language,
    message,
    operatorId,
    channel,
    sipId,
    providerNumber,
    direction
  }: ICreateConversationParams) {
    const body: IPostConversationParams = {
      label: phoneNumber,
      recipients: [
        {
          number: phoneNumber,
          label: phoneNumber
        }
      ]
    };
    if (language) {
      body.language = language;
    }
    if (message) {
      body.message = message;
    }
    if (channel) {
      body.channel = channel;
    }
    if (sipId) {
      body.sip_id = sipId;
    }
    if (providerNumber) {
      body.number = providerNumber;
    }
    if (direction) {
      body.direction = direction;
    }
    const response = await fetch(ConveyApi.endpoint("createConversation"), {
      method: "POST",
      headers: {
        ...ConveyApi.getHeaders(),
        ...(operatorId ? { "X-Operator-ID": operatorId } : {})
      },
      body: JSON.stringify(body)
    });
    const json = await response.json();
    getStore().dispatch(setConversationEntity(json.data.conversation_id, json.data));
    return json?.data;
  }

  static async closeConversation({ id, message }: ICloseConversationParams) {
    const body: ICloseConversationPayload = {};
    if (message) {
      body.message_body = message;
    }
    const response = await fetch(ConveyApi.endpoint("closeConversation", { id }), {
      method: "POST",
      headers: ConveyApi.getHeaders(),
      body: JSON.stringify(body)
    });
    const json = await response.json();
    return json?.data;
  }

  static async requestLocation({
    message,
    conversationId,
    recipientId,
    phoneNumber,
    agencyId
  }: IRequestLocationParams) {
    const body = {
      service_type: "addRecipientGeolocation",
      recipient_id: recipientId,
      send_invitation: true,
      invitation_message: message,
      token_data: {
        agency_id: agencyId,
        conversation_id: conversationId,
        recipient: phoneNumber,
        recipient_id: recipientId
      }
    };
    const response = await fetch(ConveyApi.endpoint("postTokens"), {
      method: "POST",
      headers: ConveyApi.getHeaders(),
      body: JSON.stringify(body)
    });
    const json = await response.json();
    return json?.data;
  }

  static async bindAgencyUser({ sessionId }: IBindUserAgencyParams) {
    const response = await fetch(ConveyApi.endpoint("bindUserToAgency", { id: sessionId }), {
      method: "PUT",
      body: JSON.stringify({
        agency_id: ConveyApi.agencyId
      }),
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async getAttachmentSource({ attachmentId }) {
    const response = await fetch(ConveyApi.endpoint("downloadAttachment", { id: attachmentId }), {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.blob();
    return json;
  }

  static async getAttachments({ messageId }: IGetAttachmentParams) {
    const append = `?${queryString.stringify({
      messageId
    })}`;
    const response = await fetch(ConveyApi.endpoint("getAttachments") + append, {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async getCurrentUser() {
    const response = await fetch(ConveyApi.endpoint("getCurrentUser"), {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json;
  }

  static async getDialects(params) {
    const append = `?${queryString.stringify(params)}`;
    const response = await fetch(ConveyApi.endpoint("getDialects") + append, {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async getAgency() {
    const response = await fetch(ConveyApi.endpoint("getAgency", { agency_id: ConveyApi.agencyId }), {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    if (response.ok) {
      const json = await response.json();
      return json?.data;
    } else {
      return false;
    }
  }

  static async getAgents() {
    const response = await fetch(ConveyApi.endpoint("getSessions", {}) + "?status=active", {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return {
      status: response.status,
      data: json?.data
    };
  }

  static async updateAgent({ conversationId, agentId }: IUpdateAgentParams) {
    const response = await fetch(
      ConveyApi.endpoint("updateAgent", { conversation_id: conversationId, agent_id: agentId }),
      {
        method: "PUT",
        headers: ConveyApi.getHeaders()
      }
    );
    const json = await response.json();
    return {
      status: response.status,
      data: json?.data
    };
  }

  static async createAccessToken() {
    const response = await fetch(ConveyApi.endpoint("createAccessToken", { agency_id: ConveyApi.agencyId }), {
      method: "POST",
      body: JSON.stringify({
        agency_secret: ConveyApi.agencySecret
      }),
      headers: {
        "Content-Type": "application/json",
        ...(ConveyApi.userJwt ? { "X-Authorization": `Bearer ${ConveyApi.userJwt}` } : {})
      }
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      return false;
    }
  }

  static async createLanguageSelection({ outputLanguage, neuralName }: ILanguageSelectionParams) {
    const response = await fetch(ConveyApi.rtpEndpoint("createLanguageSelection"), {
      method: "POST",
      body: JSON.stringify({
        output_language: outputLanguage,
        neural_name: neuralName
      }),
      headers: ConveyApi.getRtpHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async createLanguageIdentification({
    fileUrl,
    agencyId,
    conversationId,
    identity
  }: ICreateLanguageIdentificationParams) {
    const response = await fetch(ConveyApi.rtpEndpoint("createLanguageIdentification"), {
      method: "POST",
      body: JSON.stringify({ fileUrl, agencyId, conversationId, identity }),
      headers: ConveyApi.getRtpHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async createLanguageDetection({ candidateLanguages, correlationId }: ILanguageIdentificationParams) {
    const response = await fetch(ConveyApi.rtpEndpoint("createLanguageDetection"), {
      method: "POST",
      body: JSON.stringify({
        candidate_languages: candidateLanguages,
        correlation_id: correlationId
      }),
      headers: ConveyApi.getRtpHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async createSpeechSynthesis({
    inputText,
    outputLanguage,
    correlationId,
    neuralName,
    talkback
  }: ISpeechSynthesisParams) {
    const response = await fetch(ConveyApi.rtpEndpoint("createSpeechSynthesis"), {
      method: "POST",
      body: JSON.stringify({
        input_text: inputText,
        output_language: outputLanguage,
        neural_name: neuralName,
        correlation_id: correlationId,
        talkback
      }),
      headers: ConveyApi.getRtpHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async updateRecipients({ conversationId, number }) {
    const response = await fetch(ConveyApi.endpoint("updateRecipients", { id: conversationId }), {
      method: "PUT",
      headers: ConveyApi.getHeaders(),
      body: JSON.stringify([
        {
          number
        }
      ])
    });
    const json = await response.json();
    return json?.data;
  }

  static async getTemplates() {
    const response = await fetch(ConveyApi.endpoint("getTemplates", { id: ConveyApi.agencyId }), {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async getCampaignGroup({ campaignId }: { campaignId: string }) {
    const response = await fetch(ConveyApi.endpoint("getCampaignGroup", { id: campaignId }), {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async postCampaignSubscriber({
    campaignId,
    phoneNumber,
    groups,
    language,
    zipCode
  }: {
    campaignId: string;
    phoneNumber: string;
    groups: string[];
    language: string;
    zipCode: string;
  }) {
    const response = await fetch(ConveyApi.endpoint("postCampaignSubscribe", { id: campaignId }), {
      method: "PUT",
      headers: ConveyApi.getHeaders(),
      body: JSON.stringify({
        number: phoneNumber,
        groups: groups,
        language,
        zipcode: zipCode,
        source: window.location.href
      })
    });
    const json = await response.json();
    return json?.data;
  }

  static async deleteCampaignSubscriber({ campaignId, subscriberId }: { campaignId: string; subscriberId: string }) {
    return await fetch(
      ConveyApi.endpoint("deleteCampaignSubscriber", { id: campaignId, subscriber_id: subscriberId }),
      {
        method: "DELETE",
        headers: ConveyApi.getHeaders()
      }
    );
  }

  static async getCampaignSubscriber({ campaignId, subscriberId }: { campaignId: string; subscriberId: string }) {
    const response = await fetch(
      ConveyApi.endpoint("getCampaignSubscriber", { id: campaignId, subscriber_id: subscriberId }),
      {
        method: "GET",
        headers: ConveyApi.getHeaders()
      }
    );
    const json = await response.json();
    return json?.data;
  }

  static async updateCampaignSubscriber({
    campaignId,
    subscriberId,
    code,
    phoneNumber,
    zipCode
  }: {
    campaignId: string;
    subscriberId: string;
    code: string;
    phoneNumber: string;
    zipCode: string;
  }) {
    const response = await fetch(ConveyApi.endpoint("postCampaignSubscribe", { id: campaignId }), {
      method: "PUT",
      headers: ConveyApi.getHeaders(),
      body: JSON.stringify({
        number: phoneNumber,
        verification_code: parseInt(code, 10),
        zipcode: zipCode
      })
    });
    const json = await response.json();
    return json?.data;
  }

  static async getConnectToken() {
    const response = await fetch(ConveyApi.endpoint("getConnectToken", {}), {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async getKeywords() {
    const response = await fetch(ConveyApi.endpoint("getKeywords", {}), {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async updateAgency({ agency_name, agency_domain }: IPutAgencyParams) {
    const response = await fetch(ConveyApi.endpoint("putAgency", { id: ConveyApi.agencyId }), {
      method: "PUT",
      headers: ConveyApi.getHeaders(),
      body: JSON.stringify({ agency_name, agency_domain })
    });
    const json = await response.json();
    return json?.data;
  }

  static async getAgencyPositions() {
    const response = await fetch(ConveyApi.endpoint("getAgencyPositions", { id: ConveyApi.agencyId }), {
      method: "GET",
      headers: ConveyApi.getHeaders()
    });
    const json = await response.json();
    return json?.data;
  }

  static async updateAgencySettings({ payload }: IPutAgencySettingsParams) {
    const response = await fetch(ConveyApi.endpoint("putAgencySettings", { id: ConveyApi.agencyId }), {
      method: "PUT",
      headers: ConveyApi.getHeaders(),
      body: JSON.stringify(payload)
    });
    const json = await response.json();
    return json?.data;
  }

  static writeAccessToken(accessToken) {
    ConveyApi.accessToken = accessToken;
  }

  static readAccessToken() {
    return ConveyApi.accessToken;
  }

  static rtpEndpoint(endpoint: keyof typeof CEndpoints, pathParams?: Record<string, string>) {
    return ConveyApi.endpoint(endpoint, pathParams, process.env.RTP_HOST);
  }

  static endpoint(endpoint: keyof typeof CEndpoints, pathParams?: Record<string, string>, hostName?: string) {
    if (!hostName) {
      hostName = process.env.API_HOST;
    }
    let endpointUri = CEndpoints[endpoint];
    if (pathParams) {
      Object.entries(pathParams).forEach(([key, value]) => {
        endpointUri = endpointUri.replace(`:${key}`, value);
      });
    }
    return hostName + endpointUri;
  }

  static getRtpHeaders() {
    return {
      "Content-Type": "application/json",
      "x-agency-id": ConveyApi.agencyId,
      "x-agency-secret": ConveyApi.agencySecret
    };
  }

  static getHeaders(useRecipientToken = false) {
    const operatorId = store().getItem("operatorId")
      ? JSON.parse(store().getItem("operatorId"))
      : getStoreState().app?.operatorId || ConveyApi.operatorId;
    const accessToken = useRecipientToken ? JSON.parse(store().getItem("recipientJwtToken")) : ConveyApi.accessToken;
    if (operatorId) {
      ConveyApi.operatorId = operatorId;
    }
    return {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "x-agency-id": ConveyApi.agencyId,
      ...(operatorId ? { "X-Operator-ID": operatorId } : {})
    };
  }
}
