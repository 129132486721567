export const SET_APP_READY = "APP/SET_APP_READY";
export const UNSET_APP = "APP/UNSET_APP";
export const SET_SESSION_ID = "APP/SET_SESSION_ID";
export const SET_OPERATOR_ID = "APP/SET_OPERATOR_ID";
export const SET_VOICE_DIALECTS = "APP/SET_VOICE_DIALECTS";
export const SET_TEXT_DIALECTS = "APP/SET_TEXT_DIALECTS";
export const SET_CREDENTIALS = "APP/SET_CREDENTIALS";
export const SET_OPTIONS = "APP/SET_OPTIONS";
export const APPEND_CONTAINER = "APP/APPEND_CONTAINER";
export const SHOW_CONTAINER = "APP/SHOW_CONTAINER";
export const SET_AGENCY_ID_NAME = "APP/SET_AGENCY_ID_NAME";
export const SET_LOADING = "APP/SET_LOADING";
export const SET_NO_LOADING = "APP/SET_NO_LOADING";
export const SET_VIDEO_ROOM_SHOW = "APP/SET_VIDEO_ROOM_SHOW";
export const SET_VIDEO_ROOM_NO_SHOW = "APP/SET_VIDEO_ROOM_NO_SHOW";
export const SET_VIDEO_SHOW = "APP/SET_VIDEO_SHOW";
export const SET_VIDEO_NO_SHOW = "APP/SET_VIDEO_NO_SHOW";
export const SET_TRANSCRIBE_SHOW = "APP/SET_TRANSCRIBE_SHOW";
export const SET_TRANSCRIBE_NO_SHOW = "APP/SET_TRANSCRIBE_NO_SHOW";
export const SET_VIDEO_ROOM_COUNT = "APP/SET_VIDEO_ROOM_COUNT";
export const SET_VIDEO_ROOM_MINIMIZED = "APP/SET_VIDEO_ROOM_MINIMIZED";
export const SET_VIDEO_ROOM_MAXIMIZED = "APP/SET_VIDEO_ROOM_MAXIMIZED";
export const SET_REMOTE_RECIPIENT_CAM_SETTINGS = "APP/SET_REMOTE_RECIPIENT_CAM_SETTINGS";
export const SET_ACTIVE_ROOM_DATA = "APP/SET_ACTIVE_ROOM_DATA";
export const ROOM_JOINED_STATUS = "APP/ROOM_JOINED_STATUS";
export const UPDATE_VISIBILITY_STATUS = "APP/UPDATE_VISIBILITY_STATUS";
export const SET_VIDEO_ROOM_LAST_ACTIVITY = "APP/SET_VIDEO_ROOM_LAST_ACTIVITY";
