import React, { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { Timer } from "./videoStyles";

interface XCallTimerCountProps {
  app: IRootState["app"];
}

const XCallTimer: FunctionComponent<XCallTimerCountProps> = ({ app }: XCallTimerCountProps) => {
  const [seconds, setSeconds] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [hours, setHours] = useState("00");

  const pad = (val: number) => {
    return val > 9 ? val : "0" + val;
  };

  useEffect(() => {
    if (app && app.activeRoom && app.activeRoom.created_at) {
      const callTimeStamp = Math.floor(app?.activeRoom?.created_at / 1000);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      let sec = currentTimestamp - callTimeStamp;

      setInterval(function () {
        setSeconds(pad(Math.floor(++sec % 60)).toString());
        setMinutes(pad(Math.floor((sec / 60) % 60)).toString());
        setHours(pad(Math.floor(sec / 3600)).toString());
      }, 1000);
    }
  }, []);

  return (
    <Timer>
      <span>{hours}</span>
      <span>:</span>
      <span>{minutes}</span>
      <span>:</span>
      <span>{seconds}</span>
    </Timer>
  );
};

const mapStateToProps = ({ agency, app }: IRootState) => ({
  agency,
  app
});

export const CallTimer = connect(mapStateToProps, {})(XCallTimer);
