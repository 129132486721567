import { configureStore, EnhancedStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import RootReducer from "@reducers";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["app"]
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

export const store: EnhancedStore = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export const persistor = persistStore(store);
