import { styled } from "linaria/react";
import { css } from "linaria";
import { fontSize, borderColor, textColor, backgroundColor } from "@components/styles";

export const VideoItemWrap = styled.div`
  padding: 15px;
`;

export const VideoItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const VideoItemLabel = styled.span`
  background-color: #b7f1e0 !important;
  color: #28a745 !important;
  display: inline-block;
  padding: 3px 10px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
`;

export const VideoItemParticipants = styled.div`
  margin: 0 0 16px;
`;

export const VideoContainer = styled.div<{ transcriptionActive: boolean }>`
  position: relative;
  background: #202124;
  z-index: 0;
  width: 100%;
  max-height: ${(props) => (props.transcriptionActive ? "calc(100vh - 180px)" : "calc(100vh - 100px)")} !important;
  aspect-ratio: 4 / 3;

  > div {
    width: 100%;
    height: 100%;
  }

  &.minimized {
    max-height: 0px !important;
  }
`;

export const VideoWrap = styled.div<{ minimized: boolean; backgroundImage: string }>`
  position: relative;
  width: ${(props) => (props.minimized ? "0" : "100%")};
  height: ${(props) => (props.minimized ? "0" : "100%")};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px;
  max-height: calc(100vh - 150px);
  aspect-ratio: 4 / 3;
  background-position: center;
  background-size: auto 50%;
  background-repeat: no-repeat;
  background-image: ${(props) => (props.backgroundImage ? `url(${props.backgroundImage})` : "unset")};
`;

export const VideoActionButton = styled.div<{
  disabled?: boolean;
  variant?: string;
  fontSize?: string;
  rotate?: string;
}>`
  color: ${(props) => textColor[props.variant || "default"]};
  border: 1px solid ${(props) => borderColor[props.variant || "default"]};
  border-radius: 4px;
  padding: 4px 8px;
  font-size: ${(props) => fontSize[props.fontSize || "md"]};
  opacity: ${(props) => (props.disabled ? "0.8" : "1")};
  display: inline-flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  svg {
    fill: ${(props) => textColor[props.variant || "default"]};
    width: 16px;
    height: 16px;
    transform: scale(0.875) rotate(${(props) => props.rotate || "0"});
  }
`;

export const LanguageContainer = styled.div<{ asText: boolean }>`
  display: ${(props) => (props.asText ? "inline-flex" : "flex")};
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.asText ? "transparent" : "#777")};
  color: ${(props) => (props.asText ? "#555" : "white")};
  padding: 0 4px;
  gap: 4px;
  font-size: ${fontSize["md"]};
  svg {
    width: 16px;
    height: 16px;
    fill: white;
    transform: scale(0.75);
  }
`;

export const LanguageToggle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const LanguageList = css`
  max-height: 200px;
  overflow-y: auto;
`;

export const LanguageItem = styled.div`
  cursor: pointer;
  color: black;
`;

export const MediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 100%;
  background: #fff;
  z-index: 1;
  padding: 16px;
  box-sizing: border-box;
  gap: 8px;
`;

export const TranscriptionContainer = styled.div`
  box-sizing: border-box;
  height: 80px;
  overflow: scroll;
  color: white;
  padding: 0 5px;
  width: 100%;
  border-top: 1px solid black;
  background: #202124;

  > div {
    width: 100%;
    min-height: 100%;
    transition: margin-top 100ms;
  }

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .transcript-identity-responder {
    color: #fff;
  }

  .transcript-identity-0 {
    color: #fff;
  }

  .transcript-identity-1 {
    color: #007bff;
  }
  .transcript-identity-2 {
    color: #ffc107;
  }
  .transcript-identity-3 {
    color: #0acc95;
  }

  .transcript-identity--1 {
    color: #0acc95;
  }
`;

export const ParticipantWrap = styled.div<{ vertical: boolean; numberOfParticipants: number }>`
  background: black;
  border: 2px solid white;
  box-sizing: border-box;

  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
    &.remote-participant {
      position: relative;
      height: fit-content;
      z-index: 1;
      width: ${(props) => (props.numberOfParticipants === 2 ? "100%" : "calc(50% - 1px)")};
      height: ${(props) => (props.numberOfParticipants === 2 ? "100%" : "calc(50% - 1px)")};
    }
    &.self-participant {
      position: absolute;
      z-index: 2;
      width: 120px;
      height: 90px;
      border: 2px solid white;
      border-radius: 2px;
      bottom: 8px;
      right: 8px;
    }
  }
  &.inactive {
    width: 0;
    height: 0;
    visibility: hidden;
    border: none;

    &.self-participant {
      position: absolute;
      z-index: 2;
    }
  }
  &.video-on {
    > video {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none !important;
    }
  }
  &.video-off {
    > video {
      display: none;
      pointer-events: none !important;
    }
  }
  > video::-webkit-media-controls-overlay-play-button {
    display: none !important;
  }
`;

export const ParticipantTop = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 4;
  max-width: 100%;
  gap: 4px;
`;

export const ParticipantBottom = styled.div`
  position: absolute;
  bottom: 4px;
  left: 4px;
  z-index: 3;
  display: flex;
  align-items: center;
  gap: 4px;
  > svg {
    fill: white;
    width: 16px;
    height: 16px;
    transform: scale(0.75);
  }
`;

export const VideoBox = styled.div`
  height: 100%;
  width: 100%;
  background: black;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2;

  img {
    max-width: 50%;
    max-height: 50%;
  }
`;

export const FakeTrack = styled.div``;

export const DeviceSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DeviceContainer = styled.div<{ active: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${borderColor["default"]};
  border-radius: 4px;
`;

export const DeviceToggle = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    fill: ${textColor["default"]};
    width: 16px;
    height: 16px;
  }
`;

export const DeviceOpener = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    fill: ${textColor["default"]};
    width: 16px;
    height: 16px;
    transform: scale(0.75);
  }
`;

export const DeviceItem = styled.div<{ selected: boolean }>`
  cursor: pointer;
  color: ${(props) => (props.selected ? textColor["primary"] : textColor["default"])};
  padding: 2px 4px;
  max-width: 250px;
`;

export const Identity = styled.div`
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 2px 4px;
  font-size: ${fontSize["md"]};
`;

export const ActionButton = styled.div`
  background: #186dde;
  border-radius: 4px;
  padding: 0 4px;
  color: white;
  cursor: pointer;
  font-size: ${fontSize["md"]};
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: white;
    width: 12px;
    height: 12px;
  }
`;

export const ActionWrap = styled.div`
  position: relative;
`;

export const ActionList = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: absolute;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #eee;
  width: 250px;
  background: white;
  right: 0;
  z-index: 10;
  top: 35px;
  > div + div {
    margin-top: 5px;
    font-size: 16px;
  }
`;

export const ActionItem = styled.div`
  cursor: pointer;
  color: black;
  overflow-x: hidden;
  height: 20px;
  width: 250px;
`;

export const VideoRoomControl = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
`;

export const Timer = styled.div`
  margin-right: 8px;
  background: #0d6efd1c;
  color: #0d6efd;
  padding: 8px;
  font-weight: 600;
  letter-spacing: 0.1rem;
  border-radius: 4px;
  margin-left: 8px;
`;
