import React, { FunctionComponent } from "react";
import {
  AltimeterContainer,
  AltimeterAvatar,
  AltimeterNotchRow,
  AltimeterNotchText,
  AltimeterSmallNotch,
  AltimeterBigNotch,
  AltimeterWrap
} from "./altimeterStyles";

interface IAltimeterProps {
  padUp: number;
  padDown: number;
  padding: number;
  height: number;
  altitude: number;
}

export const Altimeter: FunctionComponent<IAltimeterProps> = ({
  padding,
  padDown,
  padUp,
  height,
  altitude
}: IAltimeterProps) => {
  const maxHeight = padUp + altitude;
  const minHeight = altitude - padDown;
  const range = (start: number, stop: number, step = 1) =>
    Array(Math.ceil((stop - start) / step))
      .fill(start)
      .map((x, y) => x + y * step);
  const notches = range(minHeight, maxHeight).reverse();
  const notchCount = notches.length;
  const notchBigCount = (notches.length / padding) * 4;
  const notchSmallCount = (notches.length - notches.length / padding) * 2;
  const notchMargin = (height - notchSmallCount - notchBigCount) / notchCount;

  const renderNotches = () => {
    return notches.map((notch) => {
      return notch % padding === 0 ? (
        <AltimeterNotchRow key={`notch-${notch}`} style={{ marginBottom: notchMargin }}>
          <AltimeterBigNotch />
          <AltimeterNotchText>
            <span style={{ color: "white" }}>{`${notch} ft`}</span>
          </AltimeterNotchText>
        </AltimeterNotchRow>
      ) : (
        <AltimeterNotchRow key={`notch-${notch}`} style={{ marginBottom: notchMargin }}>
          <AltimeterSmallNotch />
        </AltimeterNotchRow>
      );
    });
  };

  return (
    <AltimeterWrap>
      <AltimeterContainer>
        {renderNotches()}
        <AltimeterAvatar style={{ bottom: padDown * notchMargin - padDown * 2 }}>
          <img src="https://qa.convey911.com/images/e911caller.png" />
        </AltimeterAvatar>
      </AltimeterContainer>
    </AltimeterWrap>
  );
};
