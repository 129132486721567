import { createRoot, RootType } from "react-dom/client";
import React from "react";
import { SMSChat, Provider } from "@components";
import { IMessageEntity, IDialectEntity } from "@utils";
import { getStore } from "@utils";
import { appendAsChild } from "@lib/utils/helpers";
import { appendContainer } from "@actions";

export interface IConveySMSOpts {
  $container: string;
  conversationId: string;
  identity: string;
  agencyId: string;
  recipientId: string;
  phoneNumber: string;
  dialects: IDialectEntity[];
  root?: RootType;
  ui?: boolean;
  greetingMessage?: string;
  smsLanguage?: string;
  recipient?: boolean;
  locationRequest?: boolean;
  onSMS: (message: IMessageEntity) => void;
  onSMSEnded?: (closed: boolean) => void;
}

export class ConveySMS implements IConveySMSOpts {
  $element: HTMLElement;
  $container: string;
  conversationId: string;
  identity: string;
  agencyId: string;
  recipientId: string;
  phoneNumber: string;
  ui?: boolean;
  greetingMessage?: string;
  smsLanguage?: string;
  dialects: IDialectEntity[];
  root?: RootType;
  recipient?: boolean;
  locationRequest?: boolean;
  mounted: boolean;
  onSMS: (message: IMessageEntity) => void;
  onSMSEnded?: (closed: boolean) => void;

  constructor(params: IConveySMSOpts) {
    const $element = document.getElementById(params.$container);
    this.mounted = false;

    if ($element) {
      const { fullId, $newElement } = appendAsChild(params.phoneNumber, $element, params.$container, "sms");

      getStore().dispatch(
        appendContainer({ phoneNumber: params.phoneNumber, id: fullId, status: "visible", experience: "sms" })
      );
      this.$element = $newElement;
      this.recipient = params.recipient;
      this.conversationId = params.conversationId;
      this.identity = params.identity;
      this.agencyId = params.agencyId;
      this.recipientId = params.recipientId;
      this.phoneNumber = params.phoneNumber;
      this.root = params.root;
      this.dialects = params.dialects;
      this.smsLanguage = params.smsLanguage;
      this.locationRequest = params.locationRequest || false;
      this.greetingMessage = params.greetingMessage;
      this.ui = params.ui === false ? false : true;
      this.onSMS = params.onSMS;
      this.onSMSEnded = params.onSMSEnded;
      this.initRoom();
    }
    return this;
  }

  destroy() {
    if (this.root) {
      this.root.unmount();
      this.mounted = false;
      this.root = null;
      this.$element = null;
      this.$container = null;
      this.conversationId = null;
      this.identity = null;
      this.agencyId = null;
      this.recipientId = null;
      this.phoneNumber = null;
      this.ui = false;
      this.greetingMessage = null;
      this.smsLanguage = null;
      this.dialects = null;
      this.recipient = false;
      this.locationRequest = false;
    }
  }

  initRoom() {
    if (!this.root || !this.mounted) {
      this.root = createRoot(this.$element!);
      this.mounted = true;
    }

    this.root.render(
      <Provider>
        <SMSChat
          recipient={this.recipient}
          conversationId={this.conversationId}
          identity={this.identity}
          agencyId={this.agencyId}
          locationRequest={this.locationRequest}
          recipientId={this.recipientId}
          dialects={this.dialects}
          phoneNumber={this.phoneNumber}
          ui={this.ui}
          smsLanguage={this.smsLanguage}
          greetingMessage={this.greetingMessage}
          onSMS={this.onSMS}
          onSMSEnded={this.onSMSEnded}
          $element={this.$element}
        />
      </Provider>
    );
  }
}
