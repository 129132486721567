import { styled } from "linaria/react";

export const TooltipWrap = styled.div`
  background: #fff;
  border: 1px solid #eeee;
  border-radius: 4px;
  bottom: -16px;
  height: 20px;
  left: -77px;
  padding: 3px;
  position: absolute;
  width: 86px;
  font-size: 11px;
  box-sizing: border-box;
`;
