import React, { FunctionComponent, useState, useCallback } from "react";
import { ChatButton, SendMessageOptionItem, SendMessageOptionsTrigger } from "@components/chatStyles";
import { Spinner } from "@components/Spinner";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
// @ts-ignore
import ChevronUp from "@assets/icons/chevronUp.svg";
// @ts-ignore
import ChevronDown from "@assets/icons/chevronDown.svg";

interface ISendMessageCTAProps {
  isTTSEnabled: boolean;
  ttsActive: boolean;
  disabled?: boolean;
  isLoading: boolean;
  onChangeTTSActive?: (value: boolean) => void;
  onSendMessage: () => void;
}

export const SendMessageCTA: FunctionComponent<ISendMessageCTAProps> = ({
  isTTSEnabled,
  ttsActive,
  disabled,
  isLoading,
  onChangeTTSActive,
  onSendMessage
}: ISendMessageCTAProps) => {

  const [menuVisible, setMenuVisible] = useState(false);
  
  const onChangeOption = useCallback(
    (option: any) => {
      onChangeTTSActive && onChangeTTSActive(option?.key === 'tts' ? true : false);
    },
    [onChangeTTSActive]
  );

  const onVisibleChange = (visible: boolean) => {
    setMenuVisible(visible);
  };

  const sendMessageOptions = (
    <Menu onSelect={onChangeOption}>
      <MenuItem key={'tts'}>
        <SendMessageOptionItem active={ttsActive}>
          TTS
        </SendMessageOptionItem>
      </MenuItem>
      <MenuItem key={'sms'}>
        <SendMessageOptionItem active={!ttsActive}>
          SMS
        </SendMessageOptionItem>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {!isTTSEnabled && (
        <ChatButton
          value="Send"
          data-type="SMSSend"
          disabled={!!disabled}
          onClick={() => {
            onSendMessage();
          }}
        >
          Send
          {isLoading && <Spinner />}
        </ChatButton>
      )}
      {isTTSEnabled && (
        <>
          <ChatButton
            value="Send"
            data-type="TTSSend"
            disabled={!!disabled}
            onClick={() => {
              onSendMessage();
            }}
          >
            Send
            {isLoading && <Spinner />}
          </ChatButton>
          <Dropdown trigger={["click"]} overlay={sendMessageOptions} onVisibleChange={onVisibleChange}>
            <SendMessageOptionsTrigger value="Toggle" data-type="TTSDropdownToggler" disabled={disabled}>
              {menuVisible ? <ChevronUp /> : <ChevronDown />}
            </SendMessageOptionsTrigger>
          </Dropdown>
        </>
      )}
    </>
  );
};