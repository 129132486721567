import type { Reducer } from "redux";
import * as T from "@actionTypes";
import { IConversationsReducer, IReducerConversationAction } from "@utils";

const INITIAL_STATE: IConversationsReducer = {};

export const conversationsReducer: Reducer<IConversationsReducer> = (
  state = INITIAL_STATE,
  action: IReducerConversationAction
): IConversationsReducer => {
  switch (action.type) {
    case T.UPDATE_CONVERSATION_LOCATION:
      return {
        ...state,
        ...{
          [action.payload.id]: {
            ...(state[action.payload.id] || {}),
            location: action.payload.location
          }
        }
      };
    case T.UPDATE_CONVERSATION_ENTITY:
      return {
        ...state,
        ...{
          [action.payload.id]: {
            ...(state[action.payload.id] || {}),
            entity: action.payload.entity
          }
        }
      };
    case T.UPDATE_CONVERSATION_VIDEO:
      return {
        ...state,
        ...{
          [action.payload.id]: {
            ...(state[action.payload.id] || {}),
            video: action.payload.video
          }
        }
      };

    default:
      return state;
  }
};
