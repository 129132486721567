import React from "react";

export const Spinner = ({ stroke }: { stroke?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        marginLeft: "5px",
        shapeRendering: "auto",
        display: "inline-block"
      }}
      // style="margin: auto; background: rgb(241, 242, 243); display: block; shape-rendering: auto;"
      width="12px"
      height="12px"
      viewBox="0 0 100 100"
      stroke={stroke || "white"}
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={stroke || "white"}
        strokeWidth="4"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
};
