import type { Reducer } from "redux";
import * as T from "@actionTypes";
import { IAppReducer, IReducerAction } from "@utils";

const INITIAL_STATE: IAppReducer = {
  ready: false,
  loading: false,
  videoRoomShow: false,
  videoRoomCount: 0,
  videoRoomMinimized: false,
  remoteRecipientCamSettings: {},
  videoShow: false,
  transcribeShow: false,
  sessionId: "",
  voiceDialects: [],
  textDialects: [],
  agencyId: "",
  agencySecret: "",
  agencyName: "",
  operatorId: "",
  initOptions: {
    options: {
      showModal: true,
      verticalVideo: false,
      disableConveyConnect: false,
      disableVideoCall: false,
      disableJoinTranscribe: false,
      disableLocationRequest: false,
      disableVideoTranscribe: false,
      disableReassignAgent: false,
      enableSentimentAnalysis: false,
      enableKeywordBubbles: true,
      enableTTS: false,
      disableShowLiveAudio: false,
      openLastActiveChat: false
    }
  },
  containers: {},
  activeRoom: null,
  roomJoined: false,
  visibilityStatus: {},
  videoRoomLastActivity: ""
};

export const appReducer: Reducer<IAppReducer> = (state = INITIAL_STATE, action: IReducerAction): IAppReducer => {
  switch (action.type) {
    case T.APPEND_CONTAINER: {
      const newState = {
        ...state,
        ...{
          containers: {
            ...state.containers,
            [action.payload.phoneNumber]: {
              ...state.containers[action.payload.phoneNumber],
              [action.payload.experience]: {
                id: action.payload.id,
                status: action.payload.status
              }
            }
          }
        }
      };
      return newState;
    }
    case T.SHOW_CONTAINER: {
      const newContainers = {};
      Object.entries(state.containers).map(([phoneNumber, expData]) => {
        newContainers[phoneNumber] = {};
        Object.entries(expData).map(([experience, data]) => {
          if (experience === action.payload.experience) {
            const status = phoneNumber == action.payload.phoneNumber ? "visible" : "hidden";
            newContainers[phoneNumber][experience] = {
              ...data,
              status
            };
            if (document.getElementById(data.id)) {
              document.getElementById(data.id).style.display = status === "visible" ? "block" : "none";
              document.getElementById(data.id).style.height = status === "visible" ? "100%" : "0px";
            }
          } else {
            newContainers[phoneNumber][experience] = {
              ...data
            };
          }
        });
      });
      const newState = {
        ...state,
        containers: newContainers
      };
      return newState;
    }
    case T.SET_VIDEO_ROOM_COUNT:
      return {
        ...state,
        ...{ videoRoomCount: action.payload.videoRoomCount }
      };
    case T.SET_TRANSCRIBE_NO_SHOW: {
      return {
        ...state,
        transcribeShow: false
      };
    }
    case T.SET_TRANSCRIBE_SHOW: {
      return {
        ...state,
        transcribeShow: true
      };
    }
    case T.SET_VIDEO_NO_SHOW: {
      return {
        ...state,
        videoShow: false
      };
    }
    case T.SET_VIDEO_SHOW: {
      return {
        ...state,
        videoShow: true
      };
    }
    case T.SET_VIDEO_ROOM_NO_SHOW:
      return {
        ...state,
        ...{ videoRoomShow: false }
      };
    case T.SET_VIDEO_ROOM_SHOW:
      return {
        ...state,
        ...{ videoRoomShow: true }
      };
    case T.SET_VIDEO_ROOM_MINIMIZED:
      return {
        ...state,
        videoRoomMinimized: true
      };
    case T.SET_VIDEO_ROOM_MAXIMIZED:
      return {
        ...state,
        videoRoomMinimized: false
      };
    case T.SET_LOADING:
      return {
        ...state,
        ...{ loading: true }
      };
    case T.SET_REMOTE_RECIPIENT_CAM_SETTINGS:
      return {
        ...state,
        remoteRecipientCamSettings: {
          ...state.remoteRecipientCamSettings,
          [action.payload.identity]: { ...action.payload.settings }
        }
      };
    case T.SET_NO_LOADING:
      return {
        ...state,
        ...{ loading: false }
      };
    case T.SET_OPTIONS:
      return {
        ...state,
        ...{ initOptions: action.payload.options }
      };
    case T.SET_APP_READY:
      return {
        ...state,
        ...{ ready: true }
      };
    case T.SET_SESSION_ID:
      return {
        ...state,
        ...{ sessionId: action.payload.sessionId }
      };
    case T.SET_OPERATOR_ID:
      return {
        ...state,
        ...{ operatorId: action.payload.operatorId }
      };
    case T.SET_VOICE_DIALECTS:
      return {
        ...state,
        ...{ voiceDialects: action.payload.dialects }
      };
    case T.SET_TEXT_DIALECTS:
      return {
        ...state,
        ...{ textDialects: action.payload.dialects }
      };
    case T.SET_AGENCY_ID_NAME:
      return {
        ...state,
        ...{ agencyId: action.payload.agencyId, agencyName: action.payload.agencyName }
      };
    case T.SET_CREDENTIALS:
      return {
        ...state,
        ...{ agencyId: action.payload.agencyId, agencySecret: action.payload.agencySecret }
      };
    case T.SET_ACTIVE_ROOM_DATA:
      return {
        ...state,
        ...{ activeRoom: action.payload.data }
      };
    case T.ROOM_JOINED_STATUS:
      return {
        ...state,
        ...{ roomJoined: action.payload.data }
      };
    case T.UNSET_APP:
      return {
        ...INITIAL_STATE
      };
    case T.UPDATE_VISIBILITY_STATUS: {
      return {
        ...state,
        visibilityStatus: action.payload.visibilityStatus
      };
    }
    case T.SET_VIDEO_ROOM_LAST_ACTIVITY: {
      return {
        ...state,
        videoRoomLastActivity: action.payload.activity
      };
    }

    default:
      return state;
  }
};
