import { styled } from "linaria/react";

export const AdminCRUDForm = styled.form`
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
`;

export const CRUDSubmit = styled.button`
  background-color: rgb(0, 123, 255);
  border-radius: 4px;
  color: white;
  height: 38px;
  border: 0;
  margin-top: 25px;
  text-align: center;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CRUDTab = styled.div<{ active: boolean }>`
  padding: 5px 10px;
  border-bottom: ${(props) => (props.active ? "2px solid rgb(33, 37, 41)" : "2px solid #eee")};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const CRUDWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LogoColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  a {
    color: black;
  }
`;

export const FieldColumn = styled.div`
  flex: 2;
`;

export const CRUDLabel = styled.label`
  margin-bottom: 0;
  color: rgb(33, 37, 41);
  font-size: 16px;
  line-height: 24px;
  margin-top: 15px;
  display: block;
`;
