import { createRoot, RootType } from "react-dom/client";
import React from "react";
import { Loading, Provider } from "@components";
import { TContainerExperience } from "./reducerTypes";
import { appendAsChild } from "@lib/utils/helpers";

interface IConveyLoadingOpts {
  $container: string;
  phoneNumber: string;
  root?: RootType;
  experience: TContainerExperience;
}

export class ConveyLoading implements IConveyLoadingOpts {
  $container: string;
  phoneNumber: string;
  experience: TContainerExperience;
  $element: HTMLElement;
  root: RootType;

  constructor(params: IConveyLoadingOpts) {
    const $element = document.getElementById(params.$container);
    if ($element) {
      const { $newElement } = appendAsChild(params.phoneNumber, $element, params.$container, params.experience);
      this.$element = $newElement;
      this.root = params.root;
      this.experience = params.experience;
      this.init();
    }
  }

  init() {
    if (!this.root) {
      this.root = createRoot(this.$element!);
    }

    this.root.render(
      <Provider>
        <Loading />
      </Provider>
    );
  }

  getRoot() {
    return this.root;
  }

  destroy() {
    if (this.root) {
      this.root.unmount();
      this.root = null;
      this.$element = null;
    }
  }
}
