import { combineReducers } from "redux";
import { appReducer } from "./appReducer";
import { agencyReducer } from "./agencyReducer";
import { conversationsReducer } from "./conversationsReducer";

export default combineReducers({
  agency: agencyReducer,
  conversations: conversationsReducer,
  app: appReducer
});
