import React, { FunctionComponent, useState, useEffect } from "react";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { Modal } from "@components/Modal";
import { setTranscribeNoShow } from "@actions";
import { delay } from "@utils/helpers";
interface ITranscribeModalProps {
  conversationId: string;
  phoneNumber: string;
  conversations: IRootState["conversations"];
  app: IRootState["app"];
  setTranscribeNoShow: typeof setTranscribeNoShow;
  $transcribeId: string;
  show: boolean;
}
import { SDK } from "@lib/index";

const XTranscribeModal: FunctionComponent<ITranscribeModalProps> = ({
  conversationId,
  phoneNumber,
  app,
  show,
  $transcribeId,
  setTranscribeNoShow
}: ITranscribeModalProps) => {
  useEffect(() => {
    if (show) {
      (async () => {
        await delay(100);
        SDK.init({
          agencyId: app.agencyId,
          agencySecret: app.agencySecret,
          phoneNumber,
          options: app.initOptions.options
        }).transcribeCall({
          $container: $transcribeId,
          conversationId,
          correlationId: phoneNumber
        });
      })();
    }
  }, [show]);
  return (
    <>
      {show && (
        <Modal
          wideSize={true}
          show={true}
          title="Transcriptions"
          onClose={() => {
            setTranscribeNoShow();
          }}
          bodyStyle={{ position: "relative", height: 524 }}
          modalBody={<div className="ConveyEmbed--ChatWrap-p0" id={$transcribeId} />}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ conversations, app }: IRootState) => ({
  conversations,
  app
});

export const TranscribeModal = connect(mapStateToProps, { setTranscribeNoShow })(XTranscribeModal);
