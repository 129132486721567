import React, { FunctionComponent, ReactNode, useState } from "react";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { TooltipWrap } from "./tooltipStyles";

interface ITooltipProps {
  app: IRootState["app"];
  copy: string;
  children: ReactNode;
}

const XTooltip: FunctionComponent<ITooltipProps> = ({ children, copy }) => {
  const [show, setShow] = useState(false);
  return (
    <div
      style={{ position: "relative" }}
      onMouseOver={() => {
        setShow(true);
      }}
      onMouseOut={() => {
        setShow(false);
      }}
    >
      {children}
      {show && <TooltipWrap>{copy}</TooltipWrap>}
    </div>
  );
};

const mapStateToProps = ({ app }: IRootState) => ({
  app
});

export const Tooltip = connect(mapStateToProps, {})(XTooltip);
