import React, { FunctionComponent, useEffect, useState } from "react";
import { IAgencySettingPayload, ICRUDColumn } from "@utils";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import "react-phone-number-input/style.css";
import { SwitchWrap, SwitchItem, Textarea } from "@components/styles";
import Switch from "react-switch";
import { FormInput } from "@components/styles";
import { CRUDLabel } from "@components/AdminCRUD/adminCRUDStyles";
import { InputColumn } from "@components/AdminCRUD/adminCRUDStyles";

interface ICRUDProps {
  app: IRootState["app"];
  agency: IRootState["agency"];
  onBlur?: (data: IAgencySettingPayload) => void;
  defaultValues: Record<any, any>;
  column: ICRUDColumn;
}

const XCRUDForm: FunctionComponent<ICRUDProps> = ({ onBlur, column, defaultValues }: ICRUDProps) => {
  const [localValue, setLocalValue] = useState<Record<any, any>>(defaultValues);
  useEffect(() => {
    setLocalValue(defaultValues);
  }, [defaultValues]);
  return (
    <div style={{ flex: 1, marginRight: 10 }}>
      {column.fields.map((field, index) => {
        if (field.type === "input") {
          return (
            <InputColumn key={field.name}>
              <CRUDLabel>{field.label}</CRUDLabel>
              <FormInput
                onChange={(e) => {
                  onBlur({ name: field.name, value: e.target.value, component: "agency" });
                  setLocalValue({
                    ...defaultValues,
                    [field.name]: e.target.value
                  });
                }}
                disabled={
                  field.dependsOn ? localValue[field.dependsOn] === "false" || !localValue[field.dependsOn] : false
                }
                value={localValue[field.name]}
                placeholder={field.label}
                style={{
                  boxSizing: "border-box",
                  padding: 6,
                  width: "100%",
                  maxWidth: 600,
                  borderRadius: 4,
                  border: "1px solid hsl(0, 0%, 80%)",
                  minHeight: 38,
                  flex: 1
                }}
              />
            </InputColumn>
          );
        }
        if (field.type === "switch") {
          return (
            <InputColumn key={field.name}>
              <SwitchWrap>
                <SwitchItem style={{ width: "100%", marginTop: index > 0 ? 50 : 20 }}>
                  <Switch
                    onChange={(checked) => {
                      onBlur({ name: field.name, value: checked ? "true" : "false", component: "agency" });
                      setLocalValue({
                        ...defaultValues,
                        [field.name]: checked ? "true" : "false"
                      });
                    }}
                    disabled={
                      field.dependsOn && field.dependsOn !== field.name
                        ? localValue[field.dependsOn] === "false" || !localValue[field.dependsOn]
                        : false
                    }
                    checked={
                      typeof localValue[field.name] === "string"
                        ? localValue[field.name] === "true"
                        : !!localValue[field.name]
                    }
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                  {field.label}
                </SwitchItem>
              </SwitchWrap>
            </InputColumn>
          );
        }
        if (field.type === "numericInput") {
          return (
            <InputColumn key={field.name}>
              <CRUDLabel>{field.label}</CRUDLabel>
              <FormInput
                onChange={(e) => {
                  onBlur({ name: field.name, value: e.target.value, component: "agency" });
                  setLocalValue({
                    ...defaultValues,
                    [field.name]: e.target.value
                  });
                }}
                disabled={
                  field.dependsOn ? localValue[field.dependsOn] === "false" || !localValue[field.dependsOn] : false
                }
                type="number"
                value={localValue[field.name]}
                placeholder={field.label}
                style={{
                  boxSizing: "border-box",
                  padding: 6,
                  width: "100%",
                  maxWidth: 600,
                  borderRadius: 4,
                  border: "1px solid hsl(0, 0%, 80%)",
                  minHeight: 38,
                  flex: 1
                }}
              />
            </InputColumn>
          );
        }
        if (field.type === "textarea") {
          return (
            <InputColumn key={field.name}>
              <CRUDLabel>{field.label}</CRUDLabel>
              <Textarea
                disabled={
                  field.dependsOn ? localValue[field.dependsOn] === "false" || !localValue[field.dependsOn] : false
                }
                placeholder={field.label}
                onChange={(e) => {
                  onBlur({ name: field.name, value: e.target.value, component: "agency" });
                  setLocalValue({
                    ...defaultValues,
                    [field.name]: e.target.value
                  });
                }}
                value={localValue[field.name]}
              />
            </InputColumn>
          );
        }
        return <></>;
      })}
    </div>
  );
};

const mapStateToProps = ({ app, agency }: IRootState) => ({
  app,
  agency
});

export const CRUDForm = connect(mapStateToProps, {})(XCRUDForm);
