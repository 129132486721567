import React, { FunctionComponent, ReactNode } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { Provider as ReduxProvider } from "react-redux";

interface IProviderProps {
  children: ReactNode;
}

export const Provider: FunctionComponent<IProviderProps> = ({ children }: IProviderProps) => {
  return (
    <ReduxProvider store={store} children={<PersistGate persistor={persistor}>{children}</PersistGate>}></ReduxProvider>
  );
};
