import { action } from "typesafe-actions";
import * as T from "@actionTypes";
import { IConversationEntity, IConversationLocation, IConversationVideo } from "@lib/utils";

export const setConversationLocation = (id: string, location: IConversationLocation) =>
  action(T.UPDATE_CONVERSATION_LOCATION, {
    id,
    location
  });

export const setConversationVideo = (id: string, video: IConversationVideo) =>
  action(T.UPDATE_CONVERSATION_VIDEO, {
    id,
    video
  });

export const setConversationEntity = (id: string, entity: IConversationEntity) =>
  action(T.UPDATE_CONVERSATION_ENTITY, { id, entity });
