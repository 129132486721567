import React, { FunctionComponent, useState } from "react";
import { IRootState } from "@utils";
import { connect } from "react-redux";
import {
  TranslateWrap,
  TranscribeNote,
  TranslatedText,
} from "./transcribeStyles";

interface ITranscriptItemProps {
  transcript: Record<any, any>;
  app: IRootState["app"];
}

export const XTranscriptionItem: FunctionComponent<ITranscriptItemProps> = ({
  app,
  transcript
}: ITranscriptItemProps) => {
  const [showTranscript, setShowTranscript] = useState(true);

  const dialectLabel = (code: string) => {
    let label = "";
    try {
      label = app.textDialects.find((o) => o.code === code).label;
    } catch (error) {
      return code;
    }
    return label;
  };

  return (
    <TranslateWrap>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => setShowTranscript(!showTranscript)}
      >
        {/* <span style={{ fontSize: "10px" }}>&#9654;</span> */}
        <TranscribeNote emphasized={true}>
          Translated from {dialectLabel(transcript?.data?.languages?.from)}
        </TranscribeNote>
      </div>
      {showTranscript && <TranslatedText>{transcript?.data?.translations?.from}</TranslatedText>}
    </TranslateWrap>
  );
};

const mapStateToProps = ({ app }: IRootState) => ({ app });

export const TranscriptItem = connect(mapStateToProps, {})(XTranscriptionItem);