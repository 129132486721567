import React, { FunctionComponent } from "react";
import { LoadingDots, LoadingContainer } from "./loadingStyles";
import { connect } from "react-redux";
import { IRootState } from "@utils";

interface ILoadingProps {
  app: IRootState["app"];
}

const XLoading: FunctionComponent<ILoadingProps> = ({ app }) => {
  return app.loading ? (
    <div>
      <LoadingContainer>
        <LoadingDots />
      </LoadingContainer>
    </div>
  ) : null;
};

const mapStateToProps = ({ app }: IRootState) => ({
  app
});

export const Loading = connect(mapStateToProps, {})(XLoading);
