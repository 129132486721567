import type { Reducer } from "redux";
import * as T from "@actionTypes";
import { IAgencyReducer, IReducerAction } from "@utils";

const INITIAL_STATE: IAgencyReducer = {
  environment: "",
  agency_name: "",
  updated_at: "",
  roles: [],
  agency_id: "",
  numbers: [],
  created_at: "",
  region: "",
  agency_domain: "",
  status: "",
  settings: {}
};

export const agencyReducer: Reducer<IAgencyReducer> = (
  state = INITIAL_STATE,
  action: IReducerAction
): IAgencyReducer => {
  switch (action.type) {
    case T.SET_AGENCY:
      return {
        ...state,
        ...action.payload
        // settings: {
        //   ...action.payload.settings,
        //   videoEnabled: true
        // }
      };
    case T.UNSET_AGENCY:
      return {
        ...INITIAL_STATE
      };

    default:
      return state;
  }
};
