import React, { useState, useEffect } from "react";
import Select from "react-select";

interface IDropdownOption {
  label: string;
  value: string;
}

interface IMultiSelectDropdownProps {
  defaultValue?: IDropdownOption[];
  value?: string[];
  options: IDropdownOption[];
  placeholder?: string;
  searchable?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  onChange: (options: IDropdownOption[]) => void;
}

export const MultiSelectDropdown = ({
  defaultValue = [],
  value = [],
  options,
  placeholder = "",
  searchable = true,
  clearable = true,
  disabled = false,
  onChange
}: IMultiSelectDropdownProps) => {
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [search, setSearch] = useState("");
  const currentOptions: IDropdownOption[] = [
    {
      label: "Select All",
      value: "select-all"
    },
    ...options
  ];

  const onChangeValue = (selectedOptions: IDropdownOption[]) => {
    if (selectedOptions.length > 0) {
      const selectedOptionValues = selectedOptions.map((option) => option.value);
      if (selectedOptionValues.includes("select-all")) {
        const currentSelectedValues = currentValue.map((option: IDropdownOption) => option.value);
        const filteredOptions = options.filter((option: IDropdownOption) => {
          const { value, label } = option;
          if (currentSelectedValues.includes(value)) {
            return false;
          } else {
            const searchString = search.toLowerCase();
            const lowerCasedLabel = label.toLowerCase();
            const lowerCasedValue = value.toLowerCase();
            return !searchString || lowerCasedLabel.includes(searchString) || lowerCasedValue.includes(searchString);
          }
        });
        onChange([...currentValue, ...filteredOptions]);
        setCurrentValue([...currentValue, ...filteredOptions]);
      } else {
        onChange(selectedOptions);
        setCurrentValue(selectedOptions);
      }
      setSearch("");
    } else {
      onChange([]);
      setCurrentValue([]);
    }
  };

  const onInputChange = (str: string, event: any) => {
    const { action } = event;
    if (action === "input-change") {
      setSearch(str);
    }
  };

  const filterOption = (option: IDropdownOption, str: string): boolean => {
    const { value, label } = option;

    if (value === "select-all") {
      return true;
    } else if (str) {
      const searchString = str.toLowerCase();
      const lowerCasedLabel = label.toLowerCase();
      const lowerCasedValue = value.toLowerCase();
      return lowerCasedLabel.includes(searchString) || lowerCasedValue.includes(searchString);
    } else {
      return true;
    }
  };

  useEffect(() => {
    const newVal = options.filter(option => value.includes(option.value));
    setCurrentValue(newVal);
  }, [value, options])

  useEffect(() => {
    const newVal = options.filter(option => value.includes(option.value));
    setCurrentValue(newVal);
  }, [value, options])

  return (
    <Select
      isMulti
      classNamePrefix={"ConveyEmbed--CampaignMultiSelect"}
      hideSelectedOptions
      value={currentValue}
      placeholder={placeholder}
      isSearchable={searchable}
      isClearable={clearable}
      isDisabled={disabled}
      options={currentOptions}
      filterOption={filterOption}
      onChange={onChangeValue}
      onInputChange={onInputChange}
    />
  );
};
