import { createRoot, RootType } from "react-dom/client";
import React from "react";
import { CallSimulator, Provider } from "@components";
import { getStore } from "@utils";
import { appendAsChild } from "@lib/utils/helpers";
import { appendContainer } from "@actions";
import { IConveyCallSimulatorProps } from "@utils";

interface IConveyCallSimulatorOpts {
  $container: string;
  root?: RootType;
  conversationParams: IConveyCallSimulatorProps["conversationParams"];
}

export class ConveyCallSimulator implements IConveyCallSimulatorOpts {
  $container: string;
  root?: RootType;
  fullId?: string;
  $element: HTMLElement;
  conversationParams: IConveyCallSimulatorProps["conversationParams"];

  constructor(params: IConveyCallSimulatorOpts) {
    const $element = document.getElementById(params.$container);
    if ($element) {
      const { fullId, $newElement } = appendAsChild("callSimulator", $element, params.$container, "callSimulator");

      getStore().dispatch(
        appendContainer({
          phoneNumber: "callSimulator",
          id: fullId,
          status: "visible",
          experience: "callSimulator"
        })
      );

      this.$element = $newElement;
      this.root = params.root;
      this.fullId = fullId;
      this.$container = params.$container;
      this.conversationParams = params.conversationParams;
      this.init();
    }
  }

  init() {
    if (!this.root) {
      this.root = createRoot(this.$element!);
    }

    this.root.render(
      <Provider>
        <CallSimulator $container={this.$container} conversationParams={this.conversationParams} fullId={this.fullId} />
      </Provider>
    );
  }

  getRoot() {
    return this.root;
  }
}
