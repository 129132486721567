import React, { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IRootState, ITemplateEntity } from "@utils";
import { ConveyApi } from "@utils";
import { SubmitButton, Textarea, Alert } from "@components/styles";
import { Modal } from "@components/Modal";
import { Spinner } from "@components/Spinner";

interface ITemplatePromptProps {
  conversationId: string;
  phoneNumber: string;
  template: ITemplateEntity;
  recipient: boolean;
  agency: IRootState["agency"];
  app: IRootState["app"];
}

const XTemplatePrompt: FunctionComponent<ITemplatePromptProps> = ({
  template,
  conversationId,
  recipient,
  phoneNumber,
  app
}: ITemplatePromptProps) => {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestMessage, setRequestMessage] = useState(template.text);
  const [success, setSuccess] = useState("");

  const request = async () => {
    setLoading(true);
    const requestResponse = await ConveyApi.createMessage({
      body: requestMessage,
      conversationId,
      phoneNumber,
      recipient
    }).catch(() => null);

    if (requestResponse) {
      setLoading(false);
      setSuccess("Request Sent");
      setTimeout(() => {
        setActive(false);
      }, 1.75 * 1000);
    }
  };

  useEffect(() => {
    if (active) {
      setSuccess("");
    }
  }, [active]);

  useEffect(() => {
    if (active && !app.initOptions.options.showModal) {
      request();
    }
  }, [app.initOptions.options.showModal, active]);

  return (
    <>
      {active && (
        <Modal
          show={active && app.initOptions.options.showModal}
          title={template.name}
          onClose={() => {
            setActive(false);
          }}
          modalBody={
            success ? (
              <Alert alertType="success">{success}</Alert>
            ) : (
              <>
                <Textarea
                  onChange={(e) => {
                    setRequestMessage(e.target.value);
                  }}
                  value={requestMessage}
                  autoFocus={true}
                />
                <SubmitButton
                  data-type="TemplatePrompt"
                  disabled={!requestMessage || loading}
                  buttonType="info"
                  onClick={() => {
                    if (requestMessage) {
                      request();
                    }
                  }}
                >
                  Send
                  {loading && <Spinner />}
                </SubmitButton>
              </>
            )
          }
        />
      )}
      <span
        data-type="TemplatePrompt"
        onClick={() => {
          setActive(true);
        }}
        style={{ textWrap: "nowrap" }}
      >
        {template.name}
      </span>
    </>
  );
};

const mapStateToProps = ({ agency, app }: IRootState) => ({
  agency,
  app
});

export const TemplatePrompt = connect(mapStateToProps, {})(XTemplatePrompt);
