import React, { FunctionComponent } from "react";
import { SDKList, SDKListItem } from "@components/styles";
import { Modal } from "@components/Modal";

interface ISelectCameraDeviceProps {
  show: boolean,
  cameraList?: any[],
  onSelect: (data: any) => void,
  onClose: () => void,
  identity: string,
}

export const SelectCameraDevice: FunctionComponent<ISelectCameraDeviceProps> = ({
  show = false,
  onClose,
  onSelect,
  cameraList,
  identity,
}: ISelectCameraDeviceProps) => {
  return (
    <>
      {show && (
        <Modal
          show={show}
          title={identity}
          onClose={onClose}
          modalBody={
            <SDKList>
              {cameraList.map(camera => (
                <SDKListItem
                  key={camera.deviceId}
                  onClick={() => {
                    onSelect({deviceId: camera.deviceId, identity});
                  }}
                >
                  {camera.label}
                </SDKListItem>
              ))}
            </SDKList>
          }
        />
      )}
    </>
  );
};
