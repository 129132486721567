import React, { FunctionComponent, useState, useEffect, useCallback } from "react";
import { ConveyApi } from "@utils";
import { ICampaignEntity } from "@utils";
import { CampaignForm, CampaignSubmit, CampaignLabel, CampaignError, CampaignRecaptcha } from "./campaignStyles";
import Select from "react-select";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
import { Alert, SubmitButton, FlexCenterRow } from "@components/styles";
import { Spinner } from "@components/Spinner";
import { FormInput } from "@components/styles";
import { MultiSelectDropdown } from "./MultiSelectDropdown";
import { TLanguageType } from "@utils";
import ReCAPTCHA from "react-google-recaptcha";

interface ICampaignEmbedProps {
  campaignId: string;
  campaigns: ICampaignEntity[];
  app: IRootState["app"];
  callback?: (eventData: Record<any, any>) => void;
}

const XCampaignEmbed: FunctionComponent<ICampaignEmbedProps> = ({
  campaignId,
  campaigns,
  callback,
  app
}: ICampaignEmbedProps) => {
  const params = new URLSearchParams(window.location.search);
  const nParam = params.get("n");

  const existingNumber = nParam ? atob(nParam) : null;
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showVerified, setShowVerified] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [code, setCode] = useState("");
  const [subscriberId, setSubscriberId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [groups, setGroups] = useState([]);
  const [language, setLanguage] = useState("");
  const [zipCode, setZipcode] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showUnsubscribeNotice, setShowUnsubscribeNotice] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<TLanguageType>({});
  const [showError, setShowError] = useState("");
  const [recaptchaChanged, setRecaptchaChanged] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState("");

  const counties = campaigns.map((c) => ({ value: c.group_id, label: c.group_name }));

  const moveEnEsToFirst = () => {
    const langs = app.textDialects.map((c) => ({ value: c.code, label: c.label }));
    const esEleIndex = langs.findIndex((lang) => lang.value === "es");
    if (esEleIndex !== -1) {
      const [element] = langs.splice(esEleIndex, 1);
      langs.unshift(element);
    }

    const engEleIndex = langs.findIndex((lang) => lang.value === "en");
    if (engEleIndex !== -1) {
      const [element] = langs.splice(engEleIndex, 1);
      langs.unshift(element);
    }

    return langs;
  };

  const languages = moveEnEsToFirst();

  useEffect(() => {
    if (existingNumber) {
      (async () => {
        const response = await ConveyApi.getCampaignSubscriber({ campaignId, subscriberId: existingNumber });
        if (response.campaign_id) {
          setShowUpdateForm(true);
          setPhoneNumber(response.number);
          setZipcode(response.zipcode);
          setGroups(response.groups.map((group) => group.group_id));
          setCurrentLanguage(languages.find((l) => l.value === response.language) || {});
          setLanguage(response.language);
          setSubscriberId(response.subscriber_id);
          setRecaptchaChanged(false);
        }
      })();
    } else {
      const defaultLanguage = navigator.language ? navigator.language.substring(0, 2) : "en";
      setCurrentLanguage(languages.find((l) => l.value === defaultLanguage));
      setRecaptchaChanged(false);
    }
  }, [existingNumber]);

  useEffect(() => {
    if (!showUpdateForm && !showVerified && !showVerify) {
      if (phoneNumber && phoneNumber.length === 12) {
        (async () => {
          const response = await ConveyApi.getCampaignSubscriber({ campaignId, subscriberId: phoneNumber });
          if (response.campaign_id) {
            setShowUpdateForm(true);
            setPhoneNumber(response.number);
            setZipcode(response.zipcode);
            setGroups(response.groups.map((group) => group.group_id));
            setCurrentLanguage(languages.find((l) => l.value === response.language));
            setLanguage(response.language);
            setSubscriberId(response.subscriber_id);
            setRecaptchaChanged(false);
          }
        })();
      }
    }
  }, [phoneNumber, showUpdateForm, showVerified, showVerify]);

  const submit = useCallback(async () => {

    let hasError = false;
    const realPhone = phoneNumber && phoneNumber.length > 2 ? phoneNumber.slice(2) : "";
    if (!phoneNumber) {
      setPhoneError("Please enter a phone number");
      hasError = true;
    } else if (!phoneNumber.startsWith("+1") || realPhone.length !== 10 || /^\d+$/.test(realPhone) === false) {
      setPhoneError("Invalid subscriber phone number");
      hasError = true;
    } else {
      setPhoneError("");
    }

    if (!zipCode) {
      setZipCodeError("Please enter a zip code");
      hasError = true;
    } else if (zipCode.length !== 5 || /^\d+$/.test(zipCode) === false) {
      setZipCodeError("Invalid zip code");
      hasError = true;
    } else {
      setZipCodeError("");
    }

    if (!recaptchaChanged) {
      hasError = true;
      if (!recaptchaError) {
        setRecaptchaError("Please complete the reCAPTCHA verification to proceed.");
      }
    }

    if (hasError) {
      return;
    }

    setLoading(true);

    const createResponse = await ConveyApi.postCampaignSubscriber({
      phoneNumber,
      campaignId,
      groups,
      language: language || currentLanguage?.value || "en",
      zipCode
    });
    setLoading(false);

    if (!createResponse || createResponse.error) {
      setShowError(
        createResponse && createResponse.error ? createResponse.error : "An error has occurred. Please try again."
      );
    } else if (createResponse.status === "pending") {
      setSubscriberId(createResponse.subscriber_id);
      setShowVerify(true);
    } else {
      callback && callback(createResponse);
      setShowVerified(true);
      setSubscriberId(createResponse.subscriber_id);
    }
  }, [phoneNumber, zipCode, recaptchaChanged, recaptchaError]);

  const unsubscribe = useCallback(async () => {
    if (deleteLoading) {
      return;
    }
    setDeleteLoading(true);
    await ConveyApi.deleteCampaignSubscriber({
      campaignId,
      subscriberId
    });
    setShowUnsubscribeNotice(true);
    setTimeout(() => {
      setDeleteLoading(false);
      setShowUnsubscribeNotice(false);
      setShowVerify(false);
      setShowVerified(false);
      setShowUpdateForm(false);
      setPhoneNumber("");
      setZipcode("");
      setGroups([]);
      setCurrentLanguage({});
      setLanguage("");
      setSubscriberId("");
      setCode("");
      setRecaptchaChanged(false);
    }, 1750);
  }, [deleteLoading, subscriberId, campaignId]);

  const verify = useCallback(async () => {
    setLoading(true);
    const verifyResponse = await ConveyApi.updateCampaignSubscriber({
      phoneNumber,
      subscriberId,
      campaignId,
      code,
      zipCode
    });
    setLoading(false);
    setRecaptchaChanged(false);

    if (verifyResponse.status === "verified") {
      callback && callback(verifyResponse);
      setShowVerified(true);
      setShowVerify(false);
    }
  }, [phoneNumber, subscriberId, campaignId, code, zipCode]);

  const onRecaptchaChanged = (value: any) => {
    if (!value) {
      setRecaptchaError("Invalid reCAPTCHA. Please try again.")
      setRecaptchaChanged(false);
    } else {
      setRecaptchaChanged(true);
      setRecaptchaError("")
    }
  };

  const onRecaptchaExpired = () => {
    setRecaptchaError("Your reCAPTCHA verification has expired. Please refresh the page and try again.")
    setRecaptchaChanged(false);
  };

  const onRecaptchaErrored = () => {
    setRecaptchaError("There was a reCAPTCHA error. Please try again later.")
    setRecaptchaChanged(false);
  };

  return (
    <CampaignForm
      onSubmit={(e) => {
        e.preventDefault();
        if (showVerify) {
          verify();
        } else {
          submit();
        }
      }}
    >
      {showError && <Alert alertType="danger">{showError}</Alert>}
      {showVerified && <Alert alertType="success">You have successfully subscribed.</Alert>}
      {(showVerified || !showVerify) && (
        <>
          <CampaignLabel>Phone Number</CampaignLabel>
          <Input
            onChange={(value) => {
              setPhoneNumber(value);
            }}
            readOnly={showUpdateForm || showVerified}
            international={false}
            country="US"
            className="ConveyEmbed--CampaignPhoneInput"
            placeholder="Phone Number"
            value={phoneNumber}
            style={{
              boxSizing: "border-box",
              padding: 6,
              width: "100%",
              maxWidth: 600,
              borderRadius: 4,
              border: "1px solid hsl(0, 0%, 80%)",
              minHeight: 38
            }}
          />
          {phoneError && <CampaignError>{phoneError}</CampaignError>}
          <CampaignLabel>County</CampaignLabel>
          <MultiSelectDropdown
            value={groups}
            placeholder="Choose Multiple Alert Locations"
            options={counties}
            onChange={(options) => {
              setGroups(options.map((option) => option.value));
            }}
          />
          <CampaignLabel>Primary ZIP Code</CampaignLabel>
          <FormInput
            onChange={(e) => {
              setZipcode(e.target.value.slice(0, 5));
            }}
            maxLength={5}
            placeholder="Primary ZIP Code"
            type="number"
            value={zipCode}
            style={{
              boxSizing: "border-box",
              padding: 6,
              width: "100%",
              maxWidth: 600,
              borderRadius: 4,
              border: "1px solid hsl(0, 0%, 80%)",
              minHeight: 38
            }}
          />
          {zipCodeError && <CampaignError>{zipCodeError}</CampaignError>}
          <CampaignLabel>Preferred Language</CampaignLabel>
          <Select
            onChange={(option) => {
              setLanguage(option.value);
              setCurrentLanguage(option);
            }}
            placeholder="What is your preferred language?"
            options={languages}
            classNamePrefix={"ConveyEmbed--CampaignSingleSelect"}
            value={currentLanguage}
          />
          <CampaignRecaptcha>
            <ReCAPTCHA
              style={{ display: "inline-block" }}
              sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
              onChange={onRecaptchaChanged}
              onExpired={onRecaptchaExpired}
              onErrored={onRecaptchaErrored}
            />
            {recaptchaError && <CampaignError>{recaptchaError}</CampaignError>}
          </CampaignRecaptcha>
        </>
      )}
      {showVerify && (
        <>
          <CampaignLabel>Verification Code</CampaignLabel>
          <input
            onChange={(e) => {
              setCode(e.target.value);
            }}
            placeholder="Enter Verification Code"
            value={code}
            className="ConveyEmbed--CampaignVerifyCodeInput"
            style={{
              padding: 6,
              width: "100%",
              borderRadius: 4,
              border: "1px solid hsl(0, 0%, 80%)",
              minHeight: 38
            }}
          />
        </>
      )}
      {showUnsubscribeNotice && (
        <Alert alertType="success" style={{ marginTop: 25 }}>
          You have been unsubscribed.
        </Alert>
      )}
      {!(showUpdateForm || showVerified) ? (
        <CampaignSubmit type="submit" disabled={loading}>
          {showVerify ? "Verify" : "Subscribe"}
          {loading && <Spinner />}
        </CampaignSubmit>
      ) : showUpdateForm || showVerified ? (
        <FlexCenterRow justify="center" style={{ width: "100%", marginTop: 10, textAlign: "center" }}>
          <CampaignSubmit type="submit" disabled={loading}>
            Update
          </CampaignSubmit>
          <SubmitButton
            onClick={unsubscribe}
            buttonType="danger"
            disabled={deleteLoading}
            style={{ marginTop: 25, marginLeft: 10, borderRadius: 4, height: 38, fontSize: 16 }}
          >
            Unsubscribe
          </SubmitButton>
        </FlexCenterRow>
      ) : null}
    </CampaignForm>
  );
};

const mapStateToProps = ({ conversations, app }: IRootState) => ({
  conversations,
  app
});

export const CampaignEmbed = connect(mapStateToProps, {})(XCampaignEmbed);
