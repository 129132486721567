import React, { FunctionComponent, useState, useEffect } from "react";
import { ConveyApi } from "@utils";
import { IConveyCallSimulatorProps } from "@utils";
import { CallSimulatorForm, CallSimulatorSubmit } from "./callSimulatorStyles";
import Select from "react-select";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
import { Alert, FlexCenterRow, InputLabel } from "@components/styles";
import { Spinner } from "@components/Spinner";
import { TLanguageType } from "@utils";
import { UploadWrap } from "@components/Transcribe/transcribeStyles";
import { SDK } from "@lib/index";
import { SwitchWrap, SwitchItem } from "@components/styles";
import Switch from "react-switch";

interface ICallSimulatorProps {
  $container: string;
  conversationParams: IConveyCallSimulatorProps["conversationParams"];
  app: IRootState["app"];
  fullId: string;
}

type TSipOption = {
  value?: string;
  label?: string;
};

const XCallSimulator: FunctionComponent<ICallSimulatorProps> = ({
  conversationParams,
  app,
  $container,
  fullId
}: ICallSimulatorProps) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [language, setLanguage] = useState("");
  const [currentLanguage, setCurrentLanguage] = useState<TLanguageType>({});
  const [error, setError] = useState("");
  const [recipientAudio, setRecipientAudio] = useState<File | null>(null);
  const [agentAudio, setAgentAudio] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState(false);
  const [dualChannel, setDualChannel] = useState(false);
  const [positions, setPositions] = useState([]);
  const [sipId, setSipId] = useState("");
  const [currentSipId, setCurrentSipId] = useState<TSipOption>({});
  const canSubmit = phoneNumber && language && recipientAudio && (dualChannel || agentAudio);

  const moveEnEsToFirst = () => {
    const langs = app.textDialects.map((c) => ({ value: c.code, label: c.label }));
    const esEleIndex = langs.findIndex((lang) => lang.value === "es");
    if (esEleIndex !== -1) {
      const [element] = langs.splice(esEleIndex, 1);
      langs.unshift(element);
    }

    const engEleIndex = langs.findIndex((lang) => lang.value === "en");
    if (engEleIndex !== -1) {
      const [element] = langs.splice(engEleIndex, 1);
      langs.unshift(element);
    }

    return langs;
  };

  const languages = moveEnEsToFirst();

  const initPositions = async () => {
    const response = await ConveyApi.getAgencyPositions();
    setPositions(
      response
        .sort((a,b) => a.position_name.toLowerCase() < b.position_name.toLowerCase())
        .map((p) => ({ value: p.sip_id, label: [p.sip_id, p.position_name].filter(Boolean).join(" ") }))
    );
  };

  useEffect(() => {
    initPositions();
  }, []);

  const submit = async () => {
    if (!phoneNumber) {
      setError("Please enter a phone number.");
      return;
    } else {
      setError("");
    }
    if (!currentLanguage) {
      setError("Please select a language.");
      return;
    } else {
      setError("");
    }
    if (!recipientAudio) {
      setError("Please select a recipient audio file.");
      return;
    } else {
      setError("");
    }
    if (!dualChannel && !agentAudio) {
      setError("Please select an agent audio file.");
      return;
    } else {
      setError("");
    }

    setLoading(true);

    const createResponse = await ConveyApi.createConversation({
      phoneNumber,
      language: language || currentLanguage.value,
      ...conversationParams,
      ...(sipId ? { sipId } : {}),
      providerNumber: "911",
      direction: "inbound"
    });
    setLoading(false);

    if (!createResponse || createResponse.error) {
      setError(
        createResponse && createResponse.error ? createResponse.error : "An erro has occurred. Please try again."
      );
    } else {
      const $selfContainer = document.getElementById(fullId);

      if ($selfContainer) {
        $selfContainer.remove();
      }
      SDK.init({
        agencyId: app.agencyId,
        agencySecret: app.agencySecret,
        phoneNumber,
        options: app.initOptions.options
      }).transcribeCall({
        $container,
        conversationId: createResponse.conversation_id,
        correlationId: phoneNumber,
        recipientAudio,
        ...(agentAudio ? { agentAudio } : {}),
        dualChannel
      });
      setHide(true);
    }
  };

  const openUploader = (id: string) => {
    document.getElementById(id).click();
  };

  return hide ? null : (
    <CallSimulatorForm
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      {error && <Alert alertType="danger">{error}</Alert>}
      <>
        <InputLabel>Caller Phone Number</InputLabel>
        <Input
          onChange={(value) => {
            setPhoneNumber(value);
          }}
          international={false}
          country="US"
          className="ConveyEmbed--CampaignPhoneInput"
          placeholder="Phone Number"
          value={phoneNumber}
          style={{
            boxSizing: "border-box",
            padding: 6,
            width: "100%",
            maxWidth: 600,
            borderRadius: 4,
            border: "1px solid hsl(0, 0%, 80%)",
            minHeight: 38
          }}
        />
        <InputLabel>Caller Language</InputLabel>
        <Select
          onChange={(option) => {
            setLanguage(option.value);
            setCurrentLanguage(option);
          }}
          placeholder="What is your preferred language?"
          options={languages}
          classNamePrefix={"ConveyEmbed--CallSimulatorSingleSelect"}
          value={currentLanguage}
        />
        {positions.length > 0 && (
          <>
            <InputLabel>Position ID</InputLabel>
            <Select
              onChange={(option) => {
                setSipId(option.value);
                setCurrentSipId(option);
              }}
              placeholder="Select SIP ID"
              options={positions}
              classNamePrefix={"ConveyEmbed--CallSimulatorSingleSelect"}
              value={currentSipId}
            />
          </>
        )}
        <SwitchWrap>
          <SwitchItem style={{ marginLeft: 20, width: "100%", marginTop: 50 }}>
            <Switch
              onChange={(checked) => {
                setDualChannel(checked);
              }}
              checked={dualChannel}
              uncheckedIcon={false}
              checkedIcon={false}
            />
            Dual Channel?
          </SwitchItem>
        </SwitchWrap>
        <FlexCenterRow justify="center" style={{ marginTop: 20 }}>
          <UploadWrap>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                openUploader("recipientFile");
              }}
            >
              {recipientAudio ? "Recipient Audio Selected" : "Select Recipient Audio"}
            </a>
            <input
              id="recipientFile"
              hidden={true}
              type="file"
              onChange={() => {
                const files = (document.getElementById(`recipientFile`) as HTMLInputElement).files;
                setRecipientAudio(files[0]);
              }}
            />
          </UploadWrap>
          {!dualChannel && (
            <UploadWrap>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  openUploader("agentFile");
                }}
              >
                {agentAudio ? "Agent Audio Selected" : "Select Agent Audio"}
              </a>
              <input
                id="agentFile"
                hidden={true}
                type="file"
                onChange={() => {
                  const files = (document.getElementById(`agentFile`) as HTMLInputElement).files;
                  setAgentAudio(files[0]);
                }}
              />
            </UploadWrap>
          )}
        </FlexCenterRow>
      </>
      <FlexCenterRow justify="center" style={{ width: "100%", marginTop: 10, textAlign: "center" }}>
        <CallSimulatorSubmit type="submit" disabled={loading || !canSubmit}>
          Create{loading && <Spinner />}
        </CallSimulatorSubmit>
      </FlexCenterRow>
    </CallSimulatorForm>
  );
};

const mapStateToProps = ({ conversations, app }: IRootState) => ({
  conversations,
  app
});

export const CallSimulator = connect(mapStateToProps, {})(XCallSimulator);
