import React, { FunctionComponent, useEffect, useState, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import { ConveyApi, ConveySocket, IRootState } from "@utils";
import { BadgeCount, SDKButton, CircleButton, FlexCenterRow, DropDownItem } from "@components/styles";
import {
  setConversationVideo,
  setVideoRoomCount,
  setVideoShow,
  setVideoNoShow,
  setActiveRoomData,
  setRoomJoinedStatus,
  updateVisibilityStatus
} from "@actions";
import { SDKActions } from "@components/styles";
import { VideoShare } from "@components/Video/VideoShare";
import { VideoCallModal } from "@components/VideoCallModal";
import { CallTimer } from "@components/Video/CallTimer";
import { VideoItemLabel } from "@components/Video/videoStyles";
// @ts-ignore
import HangUp from "@assets/icons/hangup.svg";
// @ts-ignore
import People from "@assets/icons/people.svg";
// @ts-ignore
import User from "@assets/icons/user.svg";
// @ts-ignore
import CloseLg from "@assets/icons/closeLg.svg";
import { Spinner } from "@components/Spinner";

interface IVideoActionProps {
  conversationId: string;
  recipientId: string;
  agencyId: string;
  phoneNumber: string;
  app: IRootState["app"];
  agency: IRootState["agency"];
  setVideoRoomCount: typeof setVideoRoomCount;
  setVideoShow: typeof setVideoShow;
  setVideoNoShow: typeof setVideoNoShow;
  setActiveRoomData: typeof setActiveRoomData;
  setRoomJoinedStatus: typeof setRoomJoinedStatus;
  updateVisibilityStatus: typeof updateVisibilityStatus;
}

interface IParticipant {
  participant: string;
  participant_id: string;
}

const XVideoActions: FunctionComponent<IVideoActionProps> = ({
  conversationId,
  phoneNumber,
  app,
  setVideoShow,
  setRoomJoinedStatus,
  updateVisibilityStatus
}: IVideoActionProps) => {
  const [videoRoomParticipants, setVideoRoomParticipants] = useState<IParticipant[]>([]);
  const roomId = app?.activeRoom?.room_id;

  const [endLoading, setEndLoading] = useState(false);

  const isSameRoomId = useMemo(() => {
    return (
      app.activeRoom?.conversation_id &&
      app.activeRoom.conversation_id === conversationId &&
      app.activeRoom?.room_id &&
      app.activeRoom.room_id === roomId
    );
  }, [app.activeRoom, conversationId, roomId]);

  const showVideoModal = useMemo(() => {
    return isSameRoomId && app.roomJoined && app.videoShow;
  }, [isSameRoomId, app.roomJoined, app.videoShow]);

  const joinVideo = async (roomId?: string) => {
    if (roomId) {
      setVideoShow();
      setRoomJoinedStatus(true);
    }
  };

  const endVideoRoom = async () => {
    setEndLoading(true);
    window.SDK.instances[phoneNumber]?.endVideoCall && window.SDK.instances[phoneNumber]?.endVideoCall();
    ConveySocket.getInstance().socketEmit(ConveySocket.CLOSE_VIDEO_ROOM, { room_id: roomId });
  };

  const removeParticipant = useCallback(
    (data: any) => {
      if (data.participant !== app.agencyName) {
        const participants = [...videoRoomParticipants];
        const removeIndex = participants.findIndex((p) => p.participant_id === data.participant_id);
        if (removeIndex >= 0) {
          participants.splice(removeIndex, 1);
          setVideoRoomParticipants(participants);
        }
      }
    },
    [videoRoomParticipants, app.agencyName]
  );

  const addParticipant = useCallback(
    (data: any) => {
      const participants = [...videoRoomParticipants];
      if (data.participant !== app.agencyName && !participants.find((p) => p.participant_id === data.participant_id)) {
        participants.push({
          participant: data.participant,
          participant_id: data.participant_id
        });
        setVideoRoomParticipants(participants);
      }
    },
    [videoRoomParticipants, app.agencyName]
  );

  const deleteParticipant = useCallback(
    async (data: any) => {
      await ConveyApi.deleteVideoRoomParticipant({ roomId, participantId: data.participant_id });
      removeParticipant(data);
    },
    [removeParticipant]
  );

  const updateVideoStatus = useCallback(
    (data: any) => {
      if (data && data.status !== null) {
        const { callback_event, conversation_id } = data;

        if (callback_event == "participant-connected" && conversationId === conversation_id) {
          const { participant_identity, participant_sid } = data;
          addParticipant({
            participant: participant_identity,
            participant_id: participant_sid
          });
        }

        if (callback_event == "participant-disconnected" && conversationId === conversation_id) {
          const { participant_identity, participant_sid } = data;
          removeParticipant({
            participant: participant_identity,
            participant_id: participant_sid
          });
        }
      }
    },
    [addParticipant, removeParticipant]
  );

  const videoRoomParticipantRemoved = useCallback(
    (data: any) => {
      const { participant_id, participant } = data;
      removeParticipant({ participant, participant_id });
    },
    [removeParticipant]
  );

  const handleVisibilityStatus = useCallback(
    (data: any) => {
      const { identity } = data;
      const newVisibilityStatus = {
        ...app.visibilityStatus,
        [identity]: data.data.visibility
      };
      updateVisibilityStatus(newVisibilityStatus);
    },
    [app.visibilityStatus, updateVisibilityStatus]
  );

  useEffect(() => {
    if (roomId && isSameRoomId) {
      ConveySocket.init(ConveyApi.readAccessToken());
      ConveySocket.getInstance()
        .listen(ConveySocket.VISIBILITY_CHANGE, handleVisibilityStatus)
        .listen(ConveySocket.REMOVE_VIDEO_ROOM_PARTICIPANT, videoRoomParticipantRemoved, true)
        .listen(ConveySocket.UPDATE_VIDEO_STATUS, updateVideoStatus);
    }
    return () => {
      ConveySocket.getInstance()
        .listenOff(ConveySocket.VISIBILITY_CHANGE, handleVisibilityStatus)
        .listenOff(ConveySocket.REMOVE_VIDEO_ROOM_PARTICIPANT, videoRoomParticipantRemoved)
        .listenOff(ConveySocket.UPDATE_VIDEO_STATUS, updateVideoStatus);
    };
  }, [roomId, isSameRoomId, updateVideoStatus, handleVisibilityStatus, videoRoomParticipantRemoved]);

  const videoRoomParticipantList = (
    <Menu>
      {videoRoomParticipants.map((p: IParticipant) => (
        <MenuItem key={p.participant_id}>
          <DropDownItem>
            <FlexCenterRow justify="space-between">
              <FlexCenterRow justify="start">
                <User />
                <span style={{ marginLeft: 4 }}>{p.participant}</span>
              </FlexCenterRow>
              <FlexCenterRow justify="start">
                <a
                  href="#"
                  style={{ lineHeight: "10px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    deleteParticipant(p);
                  }}
                >
                  <CloseLg />
                </a>
              </FlexCenterRow>
            </FlexCenterRow>
          </DropDownItem>
        </MenuItem>
      ))}
    </Menu>
  );

  return isSameRoomId ? (
    <>
      <SDKActions style={{ borderTop: 0 }}>
        <FlexCenterRow justify="start">
          {!app.roomJoined && (
            <SDKButton
              data-type="JoinVideo"
              buttonType="success"
              onClick={() => joinVideo(app.activeRoom.room_id)}
              style={{ marginTop: 0 }}
              title="Join Video Call"
            >
              Join Video
            </SDKButton>
          )}
          <CallTimer />
          {videoRoomParticipants.length > 0 && (
            <Dropdown trigger={["click"]} overlay={videoRoomParticipantList}>
              <CircleButton data-type="OpenParticipantsVideoActions" buttonType="default" style={{ marginRight: 8 }}>
                <People />
                <BadgeCount>{videoRoomParticipants.length}</BadgeCount>
              </CircleButton>
            </Dropdown>
          )}
          <VideoItemLabel>in-progress</VideoItemLabel>
        </FlexCenterRow>
        <FlexCenterRow justify="start">
          <VideoShare conversationId={conversationId} roomId={app.activeRoom?.room_id} />
          <CircleButton
            buttonType="danger"
            data-type={"EndVideoCallVideoActions"}
            onClick={() => endVideoRoom()}
            style={{ marginLeft: "8px" }}
            title="End Video Call"
          >
            {endLoading ? <Spinner /> : <HangUp />}
          </CircleButton>
        </FlexCenterRow>
      </SDKActions>
      <VideoCallModal
        show={showVideoModal}
        $videoId={`videoContainer-${conversationId}`}
        conversationId={conversationId}
        phoneNumber={phoneNumber}
      />
    </>
  ) : (
    <></>
  );
};

const mapStateToProps = ({ agency, app }: IRootState) => ({
  agency,
  app
});

export const VideoActions = connect(mapStateToProps, {
  setConversationVideo,
  setVideoRoomCount,
  setVideoShow,
  setVideoNoShow,
  setActiveRoomData,
  setRoomJoinedStatus,
  updateVisibilityStatus
})(XVideoActions);
