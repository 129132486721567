import { ICRUDLayout } from "@utils";

export const ADMIN_CRUD_LAYOUT: ICRUDLayout = {
  tabs: [
    {
      title: "Conversations & Messaging",
      columns: [
        {
          fields: [
            {
              label: "Automatically Close Conversations",
              name: "autoCloseEnabled",
              type: "switch"
            },
            {
              label: "Auto Close Conversation Time (in minutes)",
              name: "closedConversationTimeout",
              type: "numericInput"
            },
            {
              label: "Closed Conversation Message",
              name: "closedConversationMessage",
              type: "textarea"
            }
          ]
        },
        {
          fields: [
            {
              label: "Agent Assignment",
              name: "enableConversationAssignment",
              type: "switch"
            },
            {
              label: " Enable International Dial code in Phone Number",
              name: "enableInternational",
              type: "switch"
            },
            {
              label: "New Inbound Conversation",
              name: "inboundConversationsEnabled",
              type: "switch"
            },
            {
              label: "Inbound Conversation Receipt Message",
              name: "inboundConversationReceiptMessage",
              type: "textarea"
            }
          ]
        }
      ]
    },
    {
      title: "Video",
      columns: [
        {
          fields: [
            {
              label: "Video Enabled",
              name: "videoEnabled",
              type: "switch"
            },
            {
              label: "Enable Agent Video",
              name: "video:enableAgentVideo",
              type: "switch",
              dependsOn: "videoEnabled"
            },
            {
              label: "Video Transcription",
              name: "enableVideoTranscription",
              type: "switch",
              dependsOn: "videoEnabled"
            },
            {
              label: "Video Recording",
              name: "enableVideoRecording",
              type: "switch",
              dependsOn: "videoEnabled"
            },
            {
              label: "Video Request Help Text",
              name: "videoRequestExplainMsg",
              type: "textarea",
              dependsOn: "videoEnabled"
            }
          ]
        },
        {
          fields: [
            {
              label: "Authorized Participant Sharing",
              name: "video:enableAuthorizedParticipantSharing",
              type: "switch",
              dependsOn: "videoEnabled"
            },
            {
              label: "Enable Remote Camera Control",
              name: "enableVideoRecipientControls",
              type: "switch",
              dependsOn: "videoEnabled"
            },
            {
              label: "Video Request Timeout (in minutes)",
              name: "videoRequestTimeout",
              type: "numericInput",
              dependsOn: "videoEnabled"
            }
          ]
        }
      ]
    },
    {
      title: "Location",
      columns: [
        {
          fields: [
            {
              label: "Location Enabled",
              name: "locationRequestEnabled",
              type: "switch"
            },
            {
              label: "Request Maximum Attempts",
              name: "locationRequestMaxAttempts",
              type: "numericInput",
              dependsOn: "locationRequestEnabled"
            },
            {
              label: "Request Timeout (in minutes)",
              name: "locationRequestTimeout",
              type: "numericInput",
              dependsOn: "locationRequestEnabled"
            },
            {
              label: "Location Request Help Text",
              name: "locationRequestExplainMsg",
              type: "textarea",
              dependsOn: "locationRequestEnabled"
            }
          ]
        }
      ]
    }
  ]
};
