import React, { FunctionComponent, useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { ConveyApi } from "@utils";
import { SubmitButton, CircleButton, Alert, FormInput, FormTextarea } from "@components/styles";
import { Modal } from "@components/Modal";
import { Spinner } from "@components/Spinner";
// @ts-ignore
import Share from "@assets/icons/share.svg";

interface IVideoShareProps {
  conversationId: string;
  roomId: string;
  agency: IRootState["agency"];
}

const XVideoShare: FunctionComponent<IVideoShareProps> = ({ agency, roomId, conversationId }: IVideoShareProps) => {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [number, setNumber] = useState("");
  const [invalidNumber, setInvalidNumber] = useState(false);
  const [tokenUrl, setTokenUrl] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [invitationMessage, setInvitationMessage] = useState(agency?.settings?.videoRequestExplainMsg || "");

  const sendInvite = useCallback(async () => {
    setLoading(true);
    const realNumber = number.includes("+1") ? number : `+1${number}`;
    const recipientResponse = await ConveyApi.updateRecipients({
      number: realNumber,
      conversationId
    });
    if (recipientResponse.length) {
      const recipient = recipientResponse.find((r) => r.number === realNumber);
      if (recipient) {
        // @ts-ignore
        const createVideoRoomTokenResponse = await ConveyApi.createVideoTokens({
          videoRoomId: roomId,
          recipientId: recipient.recipient_id,
          phoneNumber: realNumber,
          conversationId: conversationId,
          invitationMessage: invitationMessage,
          enableTranscription: false
        });
        setTokenUrl(createVideoRoomTokenResponse.token_url);
        setLoading(false);
        setSuccess("Invite Sent");
        setTimeout(() => {
          setActive(false);
        }, 1.75 * 1000);
      }
    }
  }, [number, conversationId, invitationMessage, roomId]);

  const generateLink = useCallback(async () => {
    setGenerating(true);
    // @ts-ignore
    const createVideoRoomTokenResponse = await ConveyApi.createVideoTokens({
      videoRoomId: roomId,
      conversationId: conversationId,
      anonymous: true
    });
    setTokenUrl(createVideoRoomTokenResponse.token_url);
    navigator.clipboard.writeText(createVideoRoomTokenResponse.token_url);
    setGenerating(false);
    setSuccess("Invite URL Copied To Clipboard");
    setTimeout(() => {
      setSuccess("");
    }, 5000);
  }, [conversationId, roomId]);

  const validatePhoneNumber = useCallback(() => {
    if (number) {
      const matched = number.match(/\d/g);
      if (matched && matched.length === 10) {
        setInvalidNumber(false);
        setError("");
      } else {
        setError("Please enter a valid phone number.");
        setInvalidNumber(true);
      }
    } else {
      setError("Please enter a phone number.");
      setInvalidNumber(true);
    }
  }, [number]);

  const onSubmit = useCallback(() => {
    validatePhoneNumber();
    if (!invalidNumber) {
      sendInvite();
    }
  }, [validatePhoneNumber, invalidNumber, sendInvite]);

  const onGenerate = useCallback(() => {
    generateLink();
  }, [generateLink]);

  useEffect(() => {
    setError("");
    setSuccess("");
    setLoading(false);
    setGenerating(false);
    setNumber("");
    setInvalidNumber(false);

    if (!active) {
      setInvitationMessage("");
    } else {
      setInvitationMessage(agency?.settings?.videoRequestExplainMsg || "");
    }
  }, [active]);

  return (
    <>
      {active && (
        <Modal
          show={active}
          title="Invite Participant"
          onClose={() => {
            setActive(false);
          }}
          modalBody={
            <>
              {success && <Alert alertType="success">{success}</Alert>}
              {error && <Alert alertType="danger">{error}</Alert>}{" "}
              <label>
                Phone Number <span className="convey-text-danger">*</span>
              </label>
              <FormInput
                placeholder="Phone Number"
                required={true}
                onChange={(e) => setNumber(e.target.value)}
                onBlur={() => validatePhoneNumber()}
              />
              <label>Message</label>
              <FormTextarea
                placeholder={agency?.settings?.videoRequestExplainMsg}
                onChange={(e) => {
                  setInvitationMessage(e.target.value);
                }}
                value={invitationMessage === agency?.settings?.videoRequestExplainMsg ? "" : invitationMessage}
              />
              <SubmitButton
                data-type="SendVideoShare"
                buttonType="success"
                disabled={loading || invalidNumber}
                onClick={() => onSubmit()}
              >
                Send
                {loading && <Spinner />}
              </SubmitButton>
              <SubmitButton
                data-type="GenerateVideoShare"
                buttonType="info"
                disabled={generating}
                onClick={() => {
                  onGenerate();
                }}
                style={{ marginTop: 10, marginLeft: 0 }}
              >
                Generate Share Link
                {generating && <Spinner />}
              </SubmitButton>
            </>
          }
        />
      )}
      <CircleButton
        id={`OpenVideoShare-${conversationId}`}
        data-type="OpenVideoShare"
        buttonType="primary"
        onClick={() => {
          setActive(true);
        }}
      >
        {loading ? <Spinner /> : <Share />}
      </CircleButton>
    </>
  );
};
const mapStateToProps = ({ agency }: IRootState) => ({
  agency
});

export const VideoShare = connect(mapStateToProps, {})(XVideoShare);
