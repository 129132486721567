import React, { FunctionComponent, useEffect, useState } from "react";
import { ConveyApi } from "@utils";
import { CircleButton } from "@components/styles";
import { Modal } from "@components/Modal";
import { SubmitButton } from "@components/styles";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { Spinner } from "@components/Spinner";
import { IAgentEntity, ConveySocket } from "@utils";
import Select from "react-select";
import { setConversationEntity } from "@actions";
// @ts-ignore
import Transfer from "@assets/icons/transfer.svg";

interface IXTransferAgentProps {
  conversationId: string;
  setConversationEntity: typeof setConversationEntity;
}

interface IAgentRecord {
  label?: string;
  value?: string;
}

const XTransferAgent: FunctionComponent<IXTransferAgentProps> = ({
  conversationId,
  setConversationEntity
}: IXTransferAgentProps) => {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agentId, setAgentId] = useState("");
  const [agents, setAgents] = useState<IAgentRecord[]>([]);
  const [currentAgent, setCurrentAgent] = useState<IAgentRecord>({});

  const updateAgent = async () => {
    setLoading(true);
    const updateResponse = await ConveyApi.updateAgent({ conversationId, agentId }).catch(() => null);
    if (updateResponse.data) {
      setConversationEntity(updateResponse.data.conversation_id, updateResponse.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      const response = await ConveyApi.getAgents();
      if (response.data) {
        setAgents(
          response.data.map((item: IAgentEntity) => {
            return {
              label: [item.fname, item.lname].join(" "),
              value: item.user_id
            };
          })
        );
      }
    })();

    ConveySocket.init(ConveyApi.readAccessToken())
      .listen(
        ConveySocket.ASSIGN_CONVERSATION_AGENT,
        (data) => {
          setConversationEntity(data.conversation_id, data);
        },
        true
      )
      .listen(
        ConveySocket.UNASSIGN_CONVERSATION_AGENT,
        (data) => {
          setConversationEntity(data.conversation_id, data);
        },
        true
      );
  }, []);

  return (
    <>
      {active && (
        <Modal
          midSize={true}
          show={active}
          title="Reassign Agent"
          onClose={() => {
            setActive(false);
          }}
          modalBody={
            <>
              <Select
                onChange={(option) => {
                  if (option.value) {
                    setAgentId(option.value);
                  }
                  setCurrentAgent(option);
                }}
                placeholder="Select Agent"
                options={agents}
                classNamePrefix={"ConveyEmbed--AgentSingleSelect"}
                value={currentAgent}
              />
              <SubmitButton
                data-type="TransferAgentSubmit"
                disabled={!agentId || loading}
                buttonType="danger"
                onClick={() => {
                  if (agentId) {
                    updateAgent();
                  }
                }}
                style={{ marginTop: 15 }}
              >
                Update Agent
                {loading && <Spinner />}
              </SubmitButton>
            </>
          }
        />
      )}
      <CircleButton
        data-type="TransferAgent"
        buttonType="primary"
        onClick={() => {
          setActive(true);
        }}
      >
        {loading ? <Spinner /> : <Transfer />}
      </CircleButton>
    </>
  );
};

const mapStateToProps = ({ agency, app }: IRootState) => ({
  agency,
  app
});

export const TransferAgent = connect(mapStateToProps, { setConversationEntity })(XTransferAgent);
