import { createRoot, RootType } from "react-dom/client";
import React from "react";
import { CampaignEmbed, Provider } from "@components";
import { getStore, ICampaignEntity } from "@utils";
import { appendAsChild } from "@lib/utils/helpers";
import { appendContainer } from "@actions";

interface IConveyCampaignOpts {
  $container: string;
  campaignId: string;
  campaigns: ICampaignEntity[];
  root?: RootType;
  callback?: (eventData: Record<any, any>) => void;
}

export class ConveyCampaign implements IConveyCampaignOpts {
  $container: string;
  campaignId: string;
  campaigns: ICampaignEntity[];
  callback?: (eventData: Record<any, any>) => void;
  root?: RootType;
  $element: HTMLElement;
  mounted: boolean;

  constructor(params: IConveyCampaignOpts) {
    const $element = document.getElementById(params.$container);
    this.mounted = false;

    if ($element) {
      const { fullId, $newElement } = appendAsChild(params.campaignId, $element, params.$container, "campaign");

      getStore().dispatch(
        appendContainer({ phoneNumber: params.campaignId, id: fullId, status: "visible", experience: "campaign" })
      );

      this.$element = $newElement;
      this.root = params.root;
      this.campaigns = params.campaigns;
      this.campaignId = params.campaignId;
      this.callback = params.callback;
      this.init();
    }
  }

  init() {
    if (!this.root || !this.mounted) {
      this.root = createRoot(this.$element!);
      this.mounted = true;
    }

    this.root.render(
      <Provider>
        <CampaignEmbed campaignId={this.campaignId} campaigns={this.campaigns} callback={this.callback} />
      </Provider>
    );
  }

  getRoot() {
    return this.root;
  }

  destroy() {
    if (this.root) {
      this.root.unmount();
      this.mounted = false;
      this.root = null;
      this.$element = null;
      this.campaigns = null;
      this.campaignId = null;
    }
  }
}
