import { createRoot, RootType } from "react-dom/client";
import React from "react";
import { VideoIndex, Provider } from "@components";
import { IVideoRoomResponse } from "@utils/types";
import { appendAsChild } from "@lib/utils/helpers";

export interface IConveyRoomOpts {
  $container: string;
  phoneNumber: string;
  currentRooms: IVideoRoomResponse[];
  root?: RootType;
  onJoin: (roomId: string) => void;
}

interface IConveyRoomProps {
  $element: HTMLElement;
  phoneNumber: string;
  root?: RootType;
  onJoin: (roomId: string) => void;
  currentRooms: IVideoRoomResponse[];
}

export class ConveyRoom implements IConveyRoomProps {
  $element: HTMLElement;
  phoneNumber: string;
  root?: RootType;
  onJoin: (roomId: string) => void;
  currentRooms: IVideoRoomResponse[];

  constructor(params: IConveyRoomOpts) {
    const $element = document.getElementById(params.$container);

    if ($element) {
      const { $newElement } = appendAsChild(params.phoneNumber, $element, params.$container, "video");

      this.$element = $newElement;
      this.currentRooms = params.currentRooms;
      this.root = params.root;
      this.onJoin = params.onJoin;
      this.initRoom();
    }
  }

  initRoom() {
    if (!this.root) {
      this.root = createRoot(this.$element!);
    }

    this.root.render(
      <Provider>
        <VideoIndex currentRooms={this.currentRooms} onJoin={this.onJoin} />
      </Provider>
    );
  }

  getRoot() {
    return this.root;
  }

  destroy() {
    if (this.root) {
      this.root.unmount();
      this.root = null;
      this.$element = null;
      this.currentRooms = null;
    }
  }
}
