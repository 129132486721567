import React, { FunctionComponent, useState } from "react";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { CircleButton, FlexCenterRow } from "@components/styles";
import { TranscribeModal } from "@components/TranscribeModal";
import {
  setConversationVideo,
  setVideoRoomCount,
  setVideoShow,
  setVideoNoShow,
  setActiveRoomData,
  setRoomJoinedStatus,
  setTranscribeShow
} from "@actions";
import { HumanInterpreterCall } from "@components/HumanInterpreterCall";
import { CloseSMS } from "@components/SMS/CloseSMS";
import { RequestLocation } from "@components/RequestLocation";
import { RequestVideo } from "@components/RequestVideo";
import { TransferAgent } from "@components/TransferAgent";
import { Tooltip } from "@components/Tooltip";
// @ts-ignore
import Headset from "@assets/icons/headset.svg";
// @ts-ignore
import TranscribeIcon from "@assets/icons/transcribe.svg";

interface XChatActionsProps {
  conversationId: string;
  recipientId: string;
  agencyId: string;
  phoneNumber: string;
  app: IRootState["app"];
  agency: IRootState["agency"];
  conversations: IRootState["conversations"];
  // setConversationVideo: typeof setConversationVideo;
  setVideoRoomCount: typeof setVideoRoomCount;
  setVideoShow: typeof setVideoShow;
  setVideoNoShow: typeof setVideoNoShow;
  setTranscribeShow: typeof setTranscribeShow;
  setActiveRoomData: typeof setActiveRoomData;
  onSMSClose?: () => void;
  setRoomJoinedStatus: typeof setRoomJoinedStatus;
}

const XChatActions: FunctionComponent<XChatActionsProps> = ({
  conversationId,
  recipientId,
  agencyId,
  phoneNumber,
  agency,
  app,
  onSMSClose,
  setVideoShow,
  setTranscribeShow,
  setRoomJoinedStatus,
  conversations
}: XChatActionsProps) => {
  const currentConversation = conversations[conversationId];
  const [showInterpreter, setShowInterpreter] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);

  const joinVideo = async (roomId?: string) => {
    if (roomId) {
      setVideoShow();
      setRoomJoinedStatus(true);
    }
  };

  const canReassignAgent = currentConversation.entity.operator_id === app.operatorId;

  return (
    <>
      <FlexCenterRow justify="end">
        {canReassignAgent &&
          !app.initOptions.options.disableReassignAgent &&
          agency?.settings?.enableConversationAssignment === true && (
            <Tooltip copy="Reassign Agent">
              <TransferAgent conversationId={conversationId} />
            </Tooltip>
          )}
        {!app.initOptions.options.disableJoinTranscribe && currentConversation?.entity?.channel === "voice" && (
          <Tooltip copy="Join Transcribe">
            <CircleButton
              buttonType="primary"
              onClick={() => setTranscribeShow()}
              title="Join Transcribe"
              data-type={"JoinTranscribe"}
            >
              <TranscribeIcon />
            </CircleButton>
          </Tooltip>
        )}
        {!app.initOptions.options.disableVideoCall &&
          (!app.activeRoom || app.activeRoom?.conversation_id !== conversationId) && (
            <Tooltip copy="Start Video Call">
              <RequestVideo
                conversationId={conversationId}
                recipientId={recipientId}
                agencyId={agencyId}
                phoneNumber={phoneNumber}
                onComplete={joinVideo}
              />
            </Tooltip>
          )}
        {!app.initOptions.options.disableLocationRequest && (
          <Tooltip copy="Request Location">
            <RequestLocation
              conversationId={conversationId}
              recipientId={recipientId}
              agencyId={agencyId}
              phoneNumber={phoneNumber}
            />
          </Tooltip>
        )}
        {!app.initOptions.options.disableConveyConnect && (
          <Tooltip copy="Call Interpreter">
            <CircleButton
              buttonType="primary"
              onClick={() => setShowInterpreter(true)}
              title="Call Interpreter"
              data-type={"CallConveyConnectChatActions"}
            >
              <Headset />
            </CircleButton>
          </Tooltip>
        )}
        <CloseSMS
          conversationId={conversationId}
          onClose={() => {
            onSMSClose && onSMSClose();
          }}
        />
      </FlexCenterRow>
      <HumanInterpreterCall
        showModal={showInterpreter}
        handleClose={() => setShowInterpreter(false)}
        asModal={true}
        defaultCallType="audio"
      />
      <TranscribeModal
        show={app.transcribeShow}
        $transcribeId={`transcribeContainer-${conversationId}`}
        conversationId={conversationId}
        phoneNumber={phoneNumber}
      />
    </>
  );
};

const mapStateToProps = ({ agency, app, conversations }: IRootState) => ({
  agency,
  conversations,
  app
});

export const ChatActions = connect(mapStateToProps, {
  setConversationVideo,
  setVideoRoomCount,
  setVideoShow,
  setVideoNoShow,
  setTranscribeShow,
  setActiveRoomData,
  setRoomJoinedStatus
})(XChatActions);
