import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { setConversationVideo, setVideoRoomCount, setVideoShow, setVideoNoShow, setActiveRoomData } from "@actions";
import Select from "react-select";
import { ConveyApi, TConnectCallType } from "@utils";
import { Spinner } from "@components/Spinner";
import { BoostLingo } from "./BoostLingo";
import { Alert, CircleButton, SDKButton, FlexCenterRow, RelativeDiv } from "@components/styles";
import { CircleProgress } from "@components/CircleProgress";
import {
  InterpreterFooter,
  InterpreterModal,
  InterpreterContainer,
  InterpreterContent,
  InterpreterDetails,
  InterpreterIconWrap,
  InterpreterProgressWrap,
  InterpreterPendingStatus,
  InterpreterClose,
  InterpreterVideo,
  InterpreterWrap
} from "./interpreterStyles";
import { VideoItemLabel } from "@components/Video/videoStyles";
// @ts-ignore
import Close from "@assets/icons/close.svg";
// @ts-ignore
import User from "@assets/icons/user.svg";
// @ts-ignore
import Mic from "@assets/icons/mic.svg";
// @ts-ignore
import MicOff from "@assets/icons/micOff.svg";
// @ts-ignore
import Camera from "@assets/icons/camera.svg";
// @ts-ignore
import CameraOff from "@assets/icons/cameraOff.svg";

interface XHumanInterpreterCallProps {
  handleClose?: any;
  showModal: boolean;
  asModal: boolean;
  defaultCallType: TConnectCallType;
}

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    textAlign: "left",
    cursor: "pointer",
    marginBottom: 8
  }),
  menu: (provided) => ({
    ...provided
  }),
  option: (provided) => ({
    ...provided,
    textAlign: "left"
  })
};

const XHumanInterpreterCall: FunctionComponent<XHumanInterpreterCallProps> = ({
  handleClose,
  showModal,
  asModal,
  defaultCallType = "audio"
}: XHumanInterpreterCallProps) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [active, setActive] = useState(true);

  const [languageOptions, setLanguageOptions] = useState([]);

  const [serviceType] = useState({ id: 25, name: "Medical (non-certified)" });
  const [languageFrom] = useState({ id: 9, name: "English" });
  const [languageTo, setLanguageTo] = useState(null);
  const [callType, setCallType] = useState(defaultCallType === "audio" ? "voice" : "video");
  const [isCalling, setIsCalling] = useState(false);
  const [isCallEnding, setIsCallEnding] = useState(false);
  const [isCallMade, setIsCallMade] = useState(false);
  const [interpreterDetail, setInterpreterDetail] = useState(null);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [progress, setProgress] = useState(0);

  const boostRef = useRef();
  const callRef = useRef();
  const intervalIDRef = useRef(null);

  useEffect(() => {
    if (showModal) {
      (async () => {
        const tokenData = await ConveyApi.getConnectToken();
        let connectToken = null;
        if (tokenData.token) {
          connectToken = tokenData.token;
        } else if (tokenData.provider_account_token && tokenData.provider_account_token.token) {
          connectToken = tokenData.provider_account_token.token;
        } else {
          return;
        }

        if (!connectToken) {
          return;
        }
        boostRef.current = new (BoostLingo as any)(connectToken);
        if (boostRef.current) {
          // @ts-ignore
          boostRef.current.getCallDictionaries().then((res: any) => {
            setLanguageOptions([...res.languages]);
          });
          setIsMinimized(false);
        }
      })();
    }
  }, [showModal]);

  const makeCall = () => {
    setIsAudioEnabled(true);
    if (!languageFrom || !languageTo || !serviceType) {
      setErrorMsg("Language To, Language From, Service Type must be specified");
      return false;
    } else if (languageFrom.id === languageTo.id) {
      setErrorMsg("Language from and Language to should be different");
      return false;
    } else {
      setIsCalling(true);
      setErrorMsg("");
      const callReq = {
        languageFromId: languageFrom ? languageFrom.id : 0,
        languageToId: languageTo ? languageTo.id : 0,
        serviceTypeId: serviceType ? serviceType.id : 0,
        genderId: 0
      };

      if (!boostRef.current) {
        return;
      }

      (callType === "video"
        ? // @ts-ignore
          boostRef.current.makeVideoCall(callReq, document.getElementById("InterpreterVideo"))
        : // @ts-ignore
          boostRef.current.makeVoiceCall(callReq)
      )
        .then((call) => {
          setIsCalling(false);
          setIsCallMade(true);
          setProgress(0);

          callRef.current = call;

          call.on("callConnected", (res) => console.log("Interpreter joined call", res));

          call.on("callCompleted", (cancelled) => {
            console.log("Call completed " + (cancelled ? "before" : "after") + " connect");
            callRef.current = null;
            setInterpreterDetail(null);
            setIsCallMade(false);
          });

          call.on("interlocutorInfo", (info) => {
            if (info) {
              setInterpreterDetail({
                companyName: info.companyName,
                requiredName: info.requiredName
              });
            }
          });

          call.on("localAudioShared", (isEnabled) => {
            console.log("Started sharing local audio", isEnabled);
          });

          call.on("remoteAudioShared", (isEnabled) => console.log("Started sharing remote audio", isEnabled));

          call.on("localAudioUnshared", () => console.log("Local audio unshared"));
          call.on("remoteAudioUnshared", () => console.log("Remote audio unshared"));

          call.on("localAudioEnabled", () => console.log("Local audio enabled"));
          call.on("remoteAudioEnabled", () => console.log("Remote audio enabled"));

          call.on("localAudioDisabled", () => console.log("Local audio disabled"));
          call.on("remoteAudioDisabled", () => console.log("Remote audio disabled"));

          if (call.isVideo()) {
            call.on("localVideoShared", (isEnabled) => {
              console.log("Started sharing local video", isEnabled);
            });
            call.on("remoteVideoShared", (isEnabled) => console.log("Started sharing remote video", isEnabled));

            call.on("localVideoUnshared", () => console.log("Local video unshared"));
            call.on("remoteVideoUnshared", () => console.log("Remote video unshared"));

            call.on("localVideoEnabled", () => console.log("Local video enabled"));
            call.on("remoteVideoEnabled", () => console.log("Remote video enabled"));

            call.on("localVideoDisabled", () => console.log("Local video disabled"));
            call.on("remoteVideoDisabled", () => console.log("Remote video disabled"));
          }
        })
        .catch((error) => {
          setErrorMsg("Error making call" + error);
        });
    }
  };

  const hangup = () => {
    setIsCallEnding(true);
    if (!callRef.current) {
      return;
    }
    callRef.current
      // @ts-ignore
      .hangup()
      .then(() => {
        setIsCallEnding(false);
        setIsCallMade(false);
      })
      .catch((err) => {
        setIsCallEnding(false);
        console.log("error disconnecting call...", err);
      });
  };

  const enableAudio = () => {
    if (callRef.current) {
      // @ts-ignore
      callRef.current.enableAudio();
      setIsAudioEnabled(true);
    }
  };

  const disableAudio = () => {
    if (callRef.current) {
      // @ts-ignore
      callRef.current.disableAudio();
      setIsAudioEnabled(false);
    }
  };

  useEffect(() => {
    if (!isCallMade) {
      return;
    }

    if (!intervalIDRef.current) {
      intervalIDRef.current = setInterval(function () {
        setProgress((prevState) => {
          return prevState === 99 ? 1 : prevState + 1;
        });
      }, 250);
    }
  }, [isCallMade]);

  const WrapElement = asModal ? InterpreterModal : InterpreterWrap;

  if (!active) return null;

  return (
    <WrapElement showModal={showModal} isMinimized={isMinimized}>
      <InterpreterContainer>
        <div>
          <FlexCenterRow justify="space-between">
            <div>
              <VideoItemLabel>Ready</VideoItemLabel>
            </div>
            <div>
              <SDKButton
                onClick={() => {
                  if (handleClose) {
                    handleClose();
                  } else {
                    setActive(false);
                  }
                }}
                data-type="CloseConveyConnect"
                buttonType="text"
              >
                <Close />
              </SDKButton>
            </div>
          </FlexCenterRow>
          <>
            <InterpreterContent isCallMade={isCallMade}>
              <RelativeDiv>
                <InterpreterVideo id="InterpreterVideo" show={isCallMade && !!interpreterDetail} />
                {isCallMade ? (
                  <>
                    {interpreterDetail ? (
                      <FlexCenterRow justify="space-between">
                        <InterpreterDetails>
                          {interpreterDetail?.companyName} {interpreterDetail?.requiredName}
                        </InterpreterDetails>
                        {callType === "voice" && (
                          <InterpreterIconWrap>
                            <User />
                          </InterpreterIconWrap>
                        )}
                        <div>
                          {isAudioEnabled ? (
                            <CircleButton buttonType="primary" onClick={disableAudio} data-type="MuteConveyConnect">
                              <Mic />
                            </CircleButton>
                          ) : (
                            <CircleButton buttonType="danger" onClick={enableAudio} data-type="UnmuteConveyConnect">
                              <MicOff />
                            </CircleButton>
                          )}
                        </div>
                      </FlexCenterRow>
                    ) : (
                      <div>
                        <InterpreterClose>
                          <CircleButton buttonType="danger" onClick={hangup} data-type="CloseCallConveyConnect">
                            <Close />
                          </CircleButton>
                        </InterpreterClose>
                        <InterpreterDetails>
                          <span>Language:</span>
                          <span>{languageFrom?.name || "English"}</span>
                          <span>to</span>
                          <span>{languageTo?.name || "Spanish"}</span>
                        </InterpreterDetails>
                        <InterpreterProgressWrap>
                          <CircleProgress colour={"#008C85"} percentage={progress} width={200} />
                        </InterpreterProgressWrap>
                        <InterpreterPendingStatus>Searching for available Interpreter...</InterpreterPendingStatus>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div>
                      <Select
                        menuPlacement="top"
                        defaultValue={languageTo}
                        onChange={setLanguageTo}
                        getOptionLabel={(option) => option.name}
                        isOptionSelected={(option: any) => (languageFrom === option.id ? true : false)}
                        options={languageOptions}
                        placeholder="Specify Language"
                        styles={customSelectStyles}
                      />
                      {errorMsg && <Alert alertType="danger">{errorMsg}</Alert>}
                    </div>
                    <div>
                      <CircleButton
                        buttonType={"default"}
                        data-type={callType === "voice" ? "EnableCameraConveyConnect" : "DisableCameraConveryConnect"}
                        onClick={() => setCallType(callType === "voice" ? "video" : "voice")}
                      >
                        {callType === "voice" ? <CameraOff /> : <Camera />}
                      </CircleButton>
                    </div>
                  </>
                )}
              </RelativeDiv>
            </InterpreterContent>
            {(!isCallMade || interpreterDetail) && (
              <InterpreterFooter>
                {isCallMade ? (
                  <>
                    {isCallEnding ? (
                      <SDKButton
                        buttonType="danger"
                        wide={true}
                        disabled={true}
                        data-type={"DisconnectingConveyConnect"}
                      >
                        Disconnecting
                        <Spinner />
                      </SDKButton>
                    ) : (
                      <SDKButton buttonType="danger" onClick={hangup} data-type={"DisconnectConveyConnect"}>
                        End Call
                      </SDKButton>
                    )}
                  </>
                ) : (
                  <>
                    {isCalling ? (
                      <SDKButton buttonType="outlined" disabled={true} data-type={"CallingConveyConnect"}>
                        Calling
                        <Spinner stroke="#000" />
                      </SDKButton>
                    ) : (
                      <SDKButton buttonType="success" onClick={makeCall} data-type={"CallConveyConnect"}>
                        Call
                      </SDKButton>
                    )}
                  </>
                )}
              </InterpreterFooter>
            )}
          </>
        </div>
      </InterpreterContainer>
    </WrapElement>
  );
};

const mapStateToProps = ({ agency, app }: IRootState) => ({
  agency,
  app
});

export const HumanInterpreterCall = connect(mapStateToProps, {
  setConversationVideo,
  setVideoRoomCount,
  setVideoShow,
  setVideoNoShow,
  setActiveRoomData
})(XHumanInterpreterCall);
