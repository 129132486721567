import React, { FunctionComponent } from "react";
import { IVideoRoomResponse, ConveyApi } from "@utils";
import { VideoItem } from "./VideoItem";
import { connect } from "react-redux";
import { IRootState } from "@utils";

interface IVideoIndexProps {
  currentRooms: IVideoRoomResponse[];
  onJoin: (roomId: string) => void;
  app: IRootState["app"];
}

const XVideoIndex: FunctionComponent<IVideoIndexProps> = ({ app, currentRooms, onJoin }: IVideoIndexProps) => {
  return app.videoRoomShow ? (
    <div>
      {currentRooms.map((room, index) => {
        return <VideoItem conversationId={room.conversation_id} roomId={room.room_id} key={index} onJoin={onJoin} />;
      })}
    </div>
  ) : null;
};

const mapStateToProps = ({ app }: IRootState) => ({
  app
});

export const VideoIndex = connect(mapStateToProps, {})(XVideoIndex);
