import React, { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { ConveyApi } from "@utils";
import { SubmitButton, FormTextarea, Alert, CircleButton } from "@components/styles";
import { Modal } from "@components/Modal";
import { Spinner } from "@components/Spinner";
// @ts-ignore
import MapMarker from "@assets/icons/mapMarker.svg";

interface IRequestLocationProps {
  conversationId: string;
  recipientId: string;
  agencyId: string;
  phoneNumber: string;
  agency: IRootState["agency"];
  app: IRootState["app"];
  asText?: boolean;
}

const XRequestLocation: FunctionComponent<IRequestLocationProps> = ({
  conversationId,
  recipientId,
  agencyId,
  phoneNumber,
  agency,
  app,
  asText
}: IRequestLocationProps) => {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestMessage, setRequestMessage] = useState(agency?.settings?.locationRequestExplainMsg || "");
  const [success, setSuccess] = useState("");

  const request = async () => {
    setLoading(true);
    const requestResponse = await ConveyApi.requestLocation({
      conversationId,
      recipientId,
      agencyId,
      phoneNumber,
      message: requestMessage
    }).catch(() => null);

    if (requestResponse) {
      setLoading(false);
      setSuccess("Request Sent");
      setTimeout(() => {
        setActive(false);
      }, 1.75 * 1000);
    }
  };

  useEffect(() => {
    if (active) {
      setSuccess("");
    }
  }, [active]);

  useEffect(() => {
    if (active && !app.initOptions.options.showModal) {
      request();
    }
  }, [app.initOptions.options.showModal, active]);

  return (
    <>
      {active && (
        <Modal
          show={active && app.initOptions.options.showModal}
          title="Location Request"
          onClose={() => {
            setActive(false);
          }}
          modalBody={
            success ? (
              <Alert alertType="success">{success}</Alert>
            ) : (
              <>
                <FormTextarea
                  placeholder={agency?.settings?.locationRequestExplainMsg}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setRequestMessage(agency?.settings?.locationRequestExplainMsg);
                    } else {
                      setRequestMessage(e.target.value);
                    }
                  }}
                  value={requestMessage === agency?.settings?.locationRequestExplainMsg ? "" : requestMessage}
                  autoFocus={true}
                />
                <SubmitButton
                  data-type="RequestLocation"
                  disabled={!requestMessage || loading}
                  buttonType="info"
                  onClick={() => {
                    if (requestMessage) {
                      request();
                    }
                  }}
                >
                  Send Location Request
                  {loading && <Spinner />}
                </SubmitButton>
              </>
            )
          }
        />
      )}
      {asText ? (
        "Request Location"
      ) : (
        <CircleButton
          buttonType="primary"
          data-type="RequestLocation"
          onClick={() => {
            setActive(true);
          }}
        >
          {loading ? <Spinner /> : <MapMarker />}
        </CircleButton>
      )}
    </>
  );
};

const mapStateToProps = ({ agency, app }: IRootState) => ({
  agency,
  app
});

export const RequestLocation = connect(mapStateToProps, {})(XRequestLocation);
