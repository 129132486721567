import { styled } from "linaria/react";
import { fontSize, borderColor, textColor, backgroundColor } from "@components/styles";

export const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: 350,
    padding: "0"
  },
  overlay: { backgroundColor: "transparent", position: "static", inset: "none" }
};
export const MidModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: 375,
    padding: "0"
  },
  overlay: { backgroundColor: "transparent", position: "static", inset: "none" }
};
export const WideModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "75vw",
    minHeight: 550,
    padding: "0"
  },
  overlay: { backgroundColor: "transparent", position: "static", inset: "none" }
};

export const FullScreenModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    height: "60vh",
    padding: "0"
  },
  overlay: { backgroundColor: "transparent", position: "static", inset: "none" }
};

export const ModalHeader = styled.div`
  padding: 4px 4px 4px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 16px;
`;

export const ModalTitle = styled.div`
  flex: 1 1 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${textColor["default"]};
  font-size: ${fontSize["lg"]};
  font-weight: bold;
`;

export const ModalActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ModalBody = styled.div`
  padding: 20px 16px 16px;

  > .ConveyEmbed--ChatWrap-p0 > div > .ConveyEmbed--ChatWrap {
    padding: 0 !important;
  }
`;

export const ModalActionButton = styled.div<{ size: number; variant?: string }>`
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${(props) => textColor[props.variant || "default"]};
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    transform: scale(${(props) => 16 / props.size});
  }
`;
