import { action } from "typesafe-actions";
import * as T from "@actionTypes";
import { IDialectEntity, IConveyOpts, TContainerStatus, TContainerExperience } from "@lib/utils";

export const setSessionId = (sessionId: string) => action(T.SET_SESSION_ID, { sessionId });
export const setOperatorId = (operatorId: string) => action(T.SET_OPERATOR_ID, { operatorId });

export const setVoiceDialects = (dialects: IDialectEntity[]) => action(T.SET_VOICE_DIALECTS, { dialects });
export const setTextDialects = (dialects: IDialectEntity[]) => action(T.SET_TEXT_DIALECTS, { dialects });

export const setAgencyIdName = (agencyId: string, agencyName: string) =>
  action(T.SET_AGENCY_ID_NAME, { agencyId, agencyName });

export const setCredentials = (agencyId: string, agencySecret: string) =>
  action(T.SET_CREDENTIALS, { agencyId, agencySecret });

export const setOptions = (options: Pick<IConveyOpts, "options">) => action(T.SET_OPTIONS, { options });

export const setLoading = () => action(T.SET_LOADING);

export const setNoLoading = () => action(T.SET_NO_LOADING);
export const setVideoRoomShow = () => action(T.SET_VIDEO_ROOM_SHOW);
export const setVideoRoomNoShow = () => action(T.SET_VIDEO_ROOM_NO_SHOW);
export const setVideoShow = () => action(T.SET_VIDEO_SHOW);
export const setVideoNoShow = () => action(T.SET_VIDEO_NO_SHOW);
export const setTranscribeShow = () => action(T.SET_TRANSCRIBE_SHOW);
export const setTranscribeNoShow = () => action(T.SET_TRANSCRIBE_NO_SHOW);
export const setVideoRoomCount = (videoRoomCount: number) => action(T.SET_VIDEO_ROOM_COUNT, { videoRoomCount });
export const setVideoRoomMaximized = () => action(T.SET_VIDEO_ROOM_MAXIMIZED);
export const setVideoRoomMinimized = () => action(T.SET_VIDEO_ROOM_MINIMIZED);
export const setRemoteRecipientCamSettings = ({ identity, settings }: { identity: string; settings: any }) =>
  action(T.SET_REMOTE_RECIPIENT_CAM_SETTINGS, { identity, settings });

export const setActiveRoomData = (data) =>
  action(T.SET_ACTIVE_ROOM_DATA, {
    data
  });
export const setRoomJoinedStatus = (data) =>
  action(T.ROOM_JOINED_STATUS, {
    data
  });
export const showContainer = (phoneNumber: string, experience: TContainerExperience) => {
  return action(T.SHOW_CONTAINER, { phoneNumber, experience });
};
export const updateVisibilityStatus = (visibilityStatus: Record<string, boolean>) => {
  return action(T.UPDATE_VISIBILITY_STATUS, { visibilityStatus });
};
export const setVideoRoomLastActivity = (activity: string) => action(T.SET_VIDEO_ROOM_LAST_ACTIVITY, { activity });

export const appendContainer = ({
  phoneNumber,
  id,
  status,
  experience
}: {
  phoneNumber: string;
  id: string;
  experience: TContainerExperience;
  status: TContainerStatus;
}) =>
  action(T.APPEND_CONTAINER, {
    phoneNumber,
    id,
    status,
    experience
  });
