import { styled } from "linaria/react";

export const InterpreterFooter = styled.div`
  color: #6c757d !important;
`;

export const InterpreterWrap = styled.div<{ showModal: boolean; isMinimized: boolean }>`
  display: ${(props) => (props.showModal ? "block" : "none")};
`;

export const InterpreterModal = styled.div<{ showModal: boolean; isMinimized: boolean }>`
  position: absolute;
  right: 10px;
  bottom: 0;
  width: 350px;
  zindex: 1;
  display: ${(props) => (props.showModal ? "block" : "none")};
  height: ${(props) => (props.isMinimized ? "60px" : "auto")};
`;

export const InterpreterContainer = styled.div`
  background: #fff;
  padding: 12px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid #ddd;
`;

export const InterpreterContent = styled.div<{ isCallMade: boolean }>`
  background: ${(props) => (props.isCallMade ? "#153145" : "#eee")};
  color: ${(props) => (props.isCallMade ? "#fff" : "#000")};
  padding: 6px 6px 12px;
`;

export const InterpreterClose = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const InterpreterDetails = styled.div`
  position: absolute;
  top: 5px;
  span {
    margin-right: 4px;
  }
`;

export const InterpreterVideo = styled.div<{ show: boolean }>`
  width: 150px;
  height: 150px;
  display: ${(props) => (props.show ? "block" : "none")};
  video {
    width: 100%;
    margin-top: 27px;
  }
`;

export const InterpreterProgressWrap = styled.div`
  width: 150px;
`;

export const InterpreterPendingStatus = styled.div`
  position: absolute;
  bottom: 5px;
`;

export const InterpreterIconWrap = styled.div`
  border: 5px solid #484f66;
  border-radius: 50%;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transform: scale(3);
  }
`;
