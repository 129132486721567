import React, { FunctionComponent, useEffect, useState, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import { ConveyApi, ConveySocket, IRecipientEntity, IRootState } from "@utils";
import { BadgeCount, SDKButton, CircleButton, FlexCenterRow, DropDownItem } from "@components/styles";
import { setTranscribeShow } from "@actions";
import { SDKActions } from "@components/styles";
import { VideoItemLabel } from "@components/Video/videoStyles";
// @ts-ignore
import People from "@assets/icons/people.svg";
// @ts-ignore
import User from "@assets/icons/user.svg";

interface ITranscribeActionsProps {
  conversationId: string;
  agencyId: string;
  phoneNumber: string;
  app: IRootState["app"];
  agency: IRootState["agency"];
  conversations: IRootState["conversations"];
  setTranscribeShow: typeof setTranscribeShow;
}

const XTranscribeActions: FunctionComponent<ITranscribeActionsProps> = ({
  conversationId,
  app,
  setTranscribeShow,
  conversations
}: ITranscribeActionsProps) => {
  const currentConversation = conversations[conversationId];
  const [conversationRecipients, setConversationRecipients] = useState<IRecipientEntity[]>(
    currentConversation?.entity?.recipients?.filter((r) => r.connection_state === "connected")
  );

  useEffect(() => {
    if (currentConversation?.entity?.recipients) {
      setConversationRecipients(
        currentConversation?.entity?.recipients?.filter((r) => r.connection_state === "connected")
      );
    }
  }, [currentConversation?.entity?.recipients]);

  const showBar =
    currentConversation.entity?.channel === "voice" &&
    currentConversation.entity?.connection_state === "connected" &&
    conversationRecipients.length > 0;

  const removeRecipient = useCallback((data: any) => {
    const recipients = [...conversationRecipients];
    console.log("REMOVE_RECIPIENT_DATA", { data });
    const removeIndex = recipients.findIndex(
      (p) => p.recipient_id === data.recipient_id && data.connection_state === "disconnected"
    );
    if (removeIndex >= 0) {
      recipients.splice(removeIndex, 1);
      setConversationRecipients(recipients);
    }
  }, []);

  const addRecipient = useCallback((data: any) => {
    console.log("ADD_RECIPIENT_DATA", { data });
    const participants = [...conversationRecipients];
    if (!participants.find((p) => p.recipient_id === data.participant_id && data.connection_state === "connected")) {
      participants.push({
        ...data.recipient
      });
      setConversationRecipients(participants);
    }
  }, []);

  useEffect(() => {
    ConveySocket.init(ConveyApi.readAccessToken());
    ConveySocket.getInstance().listen(ConveySocket.ADD_RECIPIENT_CONVERSATION, addRecipient, true);
    ConveySocket.getInstance().listen(ConveySocket.UPDATE_CONVERSATION_RECIPIENT, removeRecipient, true);
    return () => {
      ConveySocket.getInstance().listenOff(ConveySocket.ADD_RECIPIENT_CONVERSATION, addRecipient);
      ConveySocket.getInstance().listenOff(ConveySocket.UPDATE_CONVERSATION_RECIPIENT, removeRecipient);
    };
  }, []);

  const recipientsList = (
    <Menu>
      {conversationRecipients.map((p: IRecipientEntity) => (
        <MenuItem key={p.recipient_id}>
          <DropDownItem>
            <FlexCenterRow justify="space-between">
              <FlexCenterRow justify="start">
                <User />
                <span style={{ marginLeft: 4 }}>{p.number}</span>
              </FlexCenterRow>
            </FlexCenterRow>
          </DropDownItem>
        </MenuItem>
      ))}
    </Menu>
  );

  return showBar ? (
    <>
      <SDKActions style={{ borderTop: 0 }}>
        <FlexCenterRow justify="start">
          {!app.roomJoined && (
            <SDKButton
              data-type="JoinTranscribe"
              buttonType="success"
              onClick={() => setTranscribeShow()}
              style={{ marginTop: 0 }}
              title="Join Transcribe"
            >
              Join Transcribe
            </SDKButton>
          )}
          {conversationRecipients.length > 0 && (
            <Dropdown trigger={["click"]} overlay={recipientsList}>
              <CircleButton data-type="OpenParticipantsVideoActions" buttonType="default" style={{ marginRight: 8 }}>
                <People />
                <BadgeCount>{conversationRecipients.length}</BadgeCount>
              </CircleButton>
            </Dropdown>
          )}
          <VideoItemLabel>in-progress</VideoItemLabel>
        </FlexCenterRow>
      </SDKActions>
    </>
  ) : (
    <></>
  );
};

const mapStateToProps = ({ agency, app, conversations }: IRootState) => ({
  agency,
  conversations,
  app
});

export const TranscribeActions = connect(mapStateToProps, {
  setTranscribeShow
})(XTranscribeActions);
