import { createRoot, RootType } from "react-dom/client";
import React from "react";
import { AdminCRUD, Provider } from "@components";
import { getStore } from "@utils";
import { appendAsChild } from "@lib/utils/helpers";
import { appendContainer } from "@actions";

interface IConveyAdminCRUDOpts {
  $container: string;
  root?: RootType;
  callback?: (eventData: Record<any, any>) => void;
}

export class ConveyAdminCRUD implements IConveyAdminCRUDOpts {
  $container: string;
  callback?: (eventData: Record<any, any>) => void;
  root?: RootType;
  $element: HTMLElement;

  constructor(params: IConveyAdminCRUDOpts) {
    const $element = document.getElementById(params.$container);
    if ($element) {
      const { fullId, $newElement } = appendAsChild("adminCRUD", $element, params.$container, "adminCRUD");

      getStore().dispatch(
        appendContainer({ phoneNumber: "adminCRUD", id: fullId, status: "visible", experience: "adminCRUD" })
      );

      this.$element = $newElement;
      this.root = params.root;
      this.callback = params.callback;
      this.init();
    }
  }

  init() {
    if (!this.root) {
      this.root = createRoot(this.$element!);
    }

    this.root.render(
      <Provider>
        <AdminCRUD callback={this.callback} />
      </Provider>
    );
  }

  getRoot() {
    return this.root;
  }
}
