import React, { FunctionComponent, useEffect, useState } from "react";
import { ConveyApi, ConveySocket } from "@utils";
import { VideoShare } from "./VideoShare";
import { setVideoNoShow, setVideoRoomCount } from "@actions";
import { SDKActions, SDKButton } from "@components/styles";
import { VideoItemLabel, VideoItemWrap, VideoItemTitle, VideoItemParticipants } from "./videoStyles";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { formatPhoneNumber } from "react-phone-number-input";

interface IVideoItemProps {
  conversationId: string;
  roomId: string;
  setVideoNoShow: typeof setVideoNoShow;
  setVideoRoomCount: typeof setVideoRoomCount;
  onJoin: (roomId: string) => void;
}

const XVideoItem: FunctionComponent<IVideoItemProps> = ({
  conversationId,
  roomId,
  onJoin,
  setVideoNoShow,
  setVideoRoomCount
}: IVideoItemProps) => {
  const [active, setActive] = useState(true);
  const [, setVideoRoom] = useState([]);
  const [videoRoomParticipants, setVideoRoomParticipants] = useState([]);

  const fetchVideoRooms = async () => {
    const response = await ConveyApi.getVideoRoom({
      roomId
    });
    setVideoRoom(response);
    const roomResponse = await ConveyApi.getVideoRoomParticipants({
      roomId
    });
    setVideoRoomParticipants(roomResponse);
  };

  const endRoom = async () => {
    ConveySocket.getInstance().socketEmit(ConveySocket.LEAVE_VIDEO_ROOM_CMD, roomId);
    await ConveyApi.deleteVideoRoom({ roomId });
    setActive(false);
    setVideoNoShow && setVideoNoShow();
    (async () => {
      const currentRooms = await ConveyApi.getVideoRooms({
        conversationId: conversationId,
        status: "active"
      });
      setVideoRoomCount(currentRooms.length);
    })();
  };

  const joinRoom = async () => {
    onJoin(roomId);
  };

  useEffect(() => {
    (async () => {
      await fetchVideoRooms();
    })();
  }, []);

  if (!active) {
    return null;
  }

  return (
    <VideoItemWrap>
      <VideoItemTitle>
        <h3>Video Room</h3>
        <VideoItemLabel>in-progress</VideoItemLabel>
      </VideoItemTitle>
      <VideoItemParticipants>
        {videoRoomParticipants.filter((p) => p.identity !== "responder").length === 0
          ? "Waiting for participant to join"
          : `${videoRoomParticipants
              .filter((p) => p.identity !== "responder")
              .map((p) => formatPhoneNumber(p.identity))
              .join(", ")} has joined the video room`}
      </VideoItemParticipants>
      <SDKActions>
        <VideoShare conversationId={conversationId} roomId={roomId} />
        <SDKButton
          data-type="JoinVideoRoom"
          buttonType="success"
          onClick={() => {
            joinRoom();
          }}
        >
          Join Video
        </SDKButton>
        <SDKButton
          data-type="EndVideoRoom"
          buttonType="danger"
          onClick={() => {
            endRoom();
          }}
        >
          End
        </SDKButton>
      </SDKActions>
    </VideoItemWrap>
  );
};

const mapStateToProps = ({ app }: IRootState) => ({
  app
});

export const VideoItem = connect(mapStateToProps, { setVideoNoShow, setVideoRoomCount })(XVideoItem);
