import React, { useState, useEffect } from "react";
import { IDialect } from "@utils";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import { LanguageContainer, LanguageToggle, LanguageList, LanguageItem } from "./videoStyles";
// @ts-ignore
import Globe from "@assets/icons/globe.svg";

interface ILanguageSelectorProps {
  onSelect: (language: IDialect) => void;
  dialects: IDialect[];
  currentDialect: string;
  asText?: boolean;
  disabled?: boolean;
}
export const LanguageSelector = ({ asText, onSelect, dialects, currentDialect, disabled }: ILanguageSelectorProps) => {
  const [localLanguage, setLocalLanguage] = useState("");
  
  const moveEnEsToFirst = () => {
    const langs = [...dialects];
    const esEleIndex = langs.findIndex(lang => lang.code === "es");
    if (esEleIndex !== -1) {
      const [element] = langs.splice(esEleIndex, 1);
      langs.unshift(element);
    }
    const engEleIndex = langs.findIndex(lang => lang.code === "en");
    if (engEleIndex !== -1) {
      const [element] = langs.splice(engEleIndex, 1);
      langs.unshift(element);
    }
    
    return langs;
  }

  const currentDialects = moveEnEsToFirst();

  useEffect(() => {
    dialects.map((language) => {
      if (language.code === currentDialect) {
        setLocalLanguage(language.label);
      }
    });
  }, [currentDialect, dialects]);

  const onSelectLanguage = (option: any) => {
    const { key } = option;
    const selectedLang = dialects.find((lang) => lang.code === key);
    if (selectedLang) {
      onSelect(selectedLang);
      setLocalLanguage(selectedLang.label);
    }
  };

  const onVisibleChange = (visible: boolean) => {};

  const languageList = (
    <Menu className={LanguageList} onSelect={onSelectLanguage}>
      {currentDialects.map((language) => (
        <MenuItem key={language.code}>
          <LanguageItem key={language.code} data-type="LanguageItem" data-label={language.label}>
            {language.label}
          </LanguageItem>
        </MenuItem>
      ))}
    </Menu>
  );

  if (disabled) {
    return (
      <LanguageContainer asText={!!asText}>
        <LanguageToggle data-type="LanguageToggle">
          {!asText && <Globe />}
          {asText ? `(${localLanguage})` : localLanguage}
        </LanguageToggle>
      </LanguageContainer>
    );
  } else {
    return (
      <Dropdown trigger={["click"]} overlay={languageList} onVisibleChange={onVisibleChange}>
        <LanguageContainer asText={!!asText}>
          <LanguageToggle data-type="LanguageToggle">
            {!asText && <Globe />}
            {asText ? `(${localLanguage})` : localLanguage}
          </LanguageToggle>
        </LanguageContainer>
      </Dropdown>
    );
  }
};
