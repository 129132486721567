import React, { FunctionComponent, useReducer, useState } from "react";
import { IAgencySettingPayload, ICRUDTab } from "@utils";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import "react-phone-number-input/style.css";
import { CRUDForm } from "./CRUDForm";
import { CRUDWrap } from "@components/AdminCRUD/adminCRUDStyles";

interface CRUDFormAction {
  type: "update";
  payload: IAgencySettingPayload;
}

interface ICRUDProps {
  app: IRootState["app"];
  agency: IRootState["agency"];
  onBlur?: (title: string, data: IAgencySettingPayload) => void;
  active?: boolean;
  tab: ICRUDTab;
  defaultValues: IRootState["agency"]["settings"];
}

const reducer = (state: IAgencySettingPayload[], action: CRUDFormAction) => {
  switch (action.type) {
    case "update": {
      const { name, value, component } = action.payload;
      return [...state, ...[{ name, value, component }]];
    }
    default: {
      return state;
    }
  }
};

const XCRUD: FunctionComponent<ICRUDProps> = ({ onBlur, defaultValues, active, tab }: ICRUDProps) => {
  const [, dispatch] = useReducer(reducer, []);
  const [localValue, setLocalValue] = useState<Record<any, any>>(defaultValues);

  return active ? (
    <CRUDWrap>
      {tab.columns.map((column, index) => {
        return (
          <CRUDForm
            key={index}
            column={column}
            defaultValues={localValue}
            onBlur={(payload: IAgencySettingPayload) => {
              onBlur(tab.title, payload);
              setLocalValue({
                ...localValue,
                [payload.name]: payload.value
              });
              dispatch({ type: "update", payload });
            }}
          />
        );
      })}
    </CRUDWrap>
  ) : null;
};

const mapStateToProps = ({ app, agency }: IRootState) => ({
  app,
  agency
});

export const CRUD = connect(mapStateToProps, {})(XCRUD);
