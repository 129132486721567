import React, {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  CSSProperties,
  ReactNode
} from "react";
import {
  ModalHeader,
  ModalTitle,
  ModalActionsWrapper,
  ModalActionButton,
  ModalStyle,
  ModalBody,
  FullScreenModalStyle,
  MidModalStyle,
  WideModalStyle
} from "./modalStyles";
import ReactModal from "react-modal";
import Draggable from "react-draggable";
// @ts-ignore
import ModalClose from "@assets/icons/modalClose.svg";

interface IModalProps {
  title: string;
  show: boolean;
  fullScreen?: boolean;
  midSize?: boolean;
  wideSize?: boolean;
  minimized?: boolean;
  bodyStyle?: CSSProperties;
  modalBody: ReactNode;
  disallowClose?: boolean;
  portalClass?: string;
  onClose?: () => void;
}

export const Modal: FunctionComponent<IModalProps> = ({
  title,
  show,
  modalBody,
  bodyStyle,
  onClose,
  fullScreen,
  midSize,
  wideSize,
  disallowClose = false,
  portalClass
}: IModalProps) => {
  const [open, setOpen] = useState(show);
  const [modalStyle, setModalStyle] = useState<any>(ModalStyle);
  const modalRef = useRef(null);
  const [dragBounds, setDragBounds] = useState({ left: 0, right: 0, bottom: 0, top: 0 });

  useEffect(() => {
    if (!disallowClose) {
      setOpen(show);
    }
  }, [show, disallowClose]);

  useEffect(() => {
    if (fullScreen) {
      setModalStyle(FullScreenModalStyle);
    } else if (wideSize) {
      setModalStyle(WideModalStyle);
    } else if (midSize) {
      setModalStyle(MidModalStyle);
    } else {
      setModalStyle(ModalStyle);
    }
  }, [fullScreen, midSize]);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        if (modalRef.current) {
          const { width, height } = modalRef.current.getBoundingClientRect();
          const modalHeight = height;
          setDragBounds({
            top: modalHeight / 2 - document.body.clientHeight / 2,
            left: width / 2 - document.body.clientWidth / 2,
            right: document.body.clientWidth / 2 - width / 2,
            bottom: document.body.clientHeight / 2 - modalHeight / 2,
          });
        }
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [modalRef.current, show]);

  return (
    open && (
      <ReactModal
        isOpen={open}
        ariaHideApp={false}
        style={modalStyle}
        onRequestClose={() => {
          setOpen(false);
        }}
        shouldCloseOnOverlayClick={false}
        overlayElement={(props, contentElement) => {
          return (
            <div {...props}>
              <Draggable
                cancel=".ConveyEmbed--MapWrap, .ConveyEmbed--Input, .ConveyEmbed--Textarea, .ConveyEmbed--Map3DContainer"
                positionOffset={{ x: "-50%", y: "-50%" }}
                bounds={dragBounds}
              >
                {contentElement}
              </Draggable>
            </div>
          );
        }}
        portalClassName={portalClass || "ConveyModalPortal"}
      >
        <div ref={modalRef}>
          <ModalHeader>
            <ModalTitle data-type="Modal-Title-Text">{title}</ModalTitle>
            <ModalActionsWrapper>
              {!disallowClose && (
                <ModalActionButton
                  data-type="Modal-Close"
                  size={16}
                  onClick={() => {
                    setOpen(false);
                    onClose && onClose();
                  }}
                >
                  <ModalClose />
                </ModalActionButton>
              )}
            </ModalActionsWrapper>
          </ModalHeader>
          <ModalBody style={bodyStyle}>{modalBody}</ModalBody>
        </div>
      </ReactModal>
    )
  );
};
