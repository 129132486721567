import {
  ConveyApi,
  ConveySocket,
  ConveyVideo,
  ConveyRoom,
  ConveySMS,
  ConveyLoading,
  ConveyTranscribe,
  ConveyCampaign,
  ConveyConnect,
  IConversationEntity,
  IConveyOpts,
  IConveyProps,
  IConveyVideoCallProps,
  IConveyTranscribeCallProps,
  IDialectEntity,
  IVideoRoomResponse,
  TLoadingTypes,
  TSDKExperience,
  ITextTranslateParams,
  IConveySMSChatProps,
  IMessageEntity,
  IVideoRoomParticipant,
  IConveyHasSMSChatParams,
  IConveyEndSMSChatParams,
  IConveyLocationRequestParams,
  IConveyVideoRoom,
  IConveyJoinSMSChatParams,
  IConveyJoinVideoCallParams,
  TContainerExperience,
  IConveyEmbedCampaignProps,
  ICampaignEntity,
  IConveyConnectCallProps,
  TConnectCallType,
  IJoinVideoParams,
  IConveyAdminCRUDProps,
  ConveyCallSimulator,
  IConveyCallSimulatorProps
} from "@utils";

import { delay, writeExpire, readExpire, store } from "@utils/helpers";
import { getStore, getStoreState } from "@utils";
import {
  setCredentials,
  setVoiceDialects,
  setTextDialects,
  setOperatorId,
  setSessionId,
  setOptions,
  showContainer,
  setAgencyIdName,
  setLoading,
  setNoLoading,
  setVideoRoomNoShow,
  setVideoRoomShow,
  setVideoRoomMaximized,
  setVideoNoShow,
  setVideoRoomCount,
  setActiveRoomData,
  setConversationEntity,
  setRoomJoinedStatus,
  setVideoRoomLastActivity
} from "@actions";
import { createAuth0Client } from "@auth0/auth0-spa-js";
import { jwtDecode } from "jwt-decode";
import { ConveyAdminCRUD } from "./utils/ConveyAdminCRUD";

interface IReadyCallStack {
  method: string;
  args: any[];
}

declare global {
  interface Window {
    SDK: {
      instances: Record<string, any>;
    };
  }
}

interface ISDKMeta {
  version: string;
}

// @ts-ignore
const CONVEY_SDK_VERSION = VERSION;

export class SDK implements IConveyProps {
  correlationId: string;
  agencyId: string;
  agencySecret: string;
  accessToken: string;
  conveyVideo: ConveyVideo;
  phoneNumber: string;
  sessionId: string;
  agencyName: string;
  ready: boolean;
  $container: string;
  $sipContainer: string;
  $smsContainer: string;
  $connectContainer: string;
  $transcriptionContainer: string;
  connectCallType: TConnectCallType;
  conversation: IConversationEntity;
  smsConversation: IConversationEntity;
  videoParams: IConveyVideoCallProps;
  recipientId: string;
  videoRoomId: string;
  videoRoomToken: string;
  videoRoomResourceId: string;
  voiceDialects: IDialectEntity[];
  textDialects: IDialectEntity[];
  currentRooms: IVideoRoomResponse[];
  room: ConveyRoom;
  roomId: string;
  sdkExperience: TSDKExperience;
  onEventCallback: (eventName: string, eventData: any) => void;
  onSMSCallback: (message: IMessageEntity) => void;
  onTranscribeCallback: (eventData: Record<any, any>) => void;
  onReadyCallback: (meta: ISDKMeta) => void;
  onTranslateCallback: (outputText: string) => void;
  onErrorCallback: (error: Error) => void;
  onSMSChatEndedCallback: (closed: boolean) => void;
  onVideoRoomEndedCallback: (room: IConveyVideoRoom) => void;
  onVideoRoomParticipantRemovedCallback: (participant: IVideoRoomParticipant) => void;
  onVideoRoomParticipantAddedCallback: (participant: IVideoRoomParticipant) => void;
  onCampaignSubscribeCallback: (eventData: Record<any, any>) => void;
  onAdminCRUDSaveCallback: (eventData: Record<any, any>) => void;
  onKeywordCallback: (eventData: Record<any, any>, isNew: boolean) => void;
  onSentimentScoreCallback: (eventData: Record<any, any>, isNew: boolean) => void;
  onSummaryCallback: (eventData: string, isNew: boolean) => void;
  onLiveAudioCallback: (eventData: Record<any, any>, dualChannel: boolean) => void;
  loading: Record<TLoadingTypes | string, ConveyLoading>;
  invitationMessage: string;
  transcribe: ConveyTranscribe;
  connect: ConveyConnect;
  transcribeParams: IConveyTranscribeCallProps;
  talkback?: boolean;
  translateParams: ITextTranslateParams;
  outputLanguage: string;
  callerLanguage: string;
  neuralName: string;
  smsParams: IConveySMSChatProps;
  hasSmsChatParams: IConveyHasSMSChatParams & { callback: (exists: boolean, params: IConveyHasSMSChatParams) => void };
  sms: ConveySMS;
  smsUi?: boolean;
  smsLanguage?: string;
  greetingMessage?: string;
  performVideoTranscribe: boolean;
  performVideoRecord: boolean;
  enableAudio: boolean;
  enableVideo: boolean;
  blur: boolean;
  recipientFlow: boolean;
  loaded: boolean;
  operatorId: string;
  lastLoaded: TContainerExperience;
  readyCallStack: IReadyCallStack[];
  campaignId: string;
  $campaignContainer: string;
  campaigns: ICampaignEntity[];
  conveyCampaign: ConveyCampaign;
  transcribeConversationId: string;
  chatConversationId: string;
  joinVideoRoomParams?: IJoinVideoParams;
  userJwt: string;
  chatActive: boolean;
  selfOpenLastActiveChat: boolean;
  recipientNumber: string;
  $adminCRUDContainer: string;
  conveyAdminCRUD: ConveyAdminCRUD;
  showUploader: boolean;
  conveyCallSimulator: ConveyCallSimulator;
  $callSimulatorContainer: string;
  callSimulatorConversationParams: IConveyCallSimulatorProps["conversationParams"];
  $summaryContainer: string;
  $sentimentContainer: string;
  $keywordsContainer: string;
  agentAudio?: File;
  recipientAudio?: File;
  dualChannel?: boolean;
  private static instances: Record<any, SDK>;
  private static ID_TOKEN = "https://api.convey911.com/user_id";
  private static READY = false;
  private static SELF_OPEN_LAST_ACTIVE_CHAT = false;

  public static DEFAULT_VIDEO_PARAMS = {
    invitationMessage: "Tap link to start a video room with first responder",
    transcribe: true,
    record: true,
    blur: true,
    enableAudio: false,
    enableVideo: false,
    $transcriptionContainer: undefined
  };

  constructor(options: IConveyOpts) {
    this.readyCallStack = [];
    this.loading = {};
    this.loaded = false;
    this.chatActive = false;

    getStore().dispatch(
      setOptions({
        options: {
          ...{
            showModal: true,
            verticalVideo: false,
            disableConveyConnect: false,
            disableVideoCall: false,
            disableJoinTranscribe: false,
            disableLocationRequest: false,
            disableVideoTranscribe: false,
            enableSentimentAnalysis: false,
            enableKeywordBubbles: true,
            enableTTS: false,
            disableShowLiveAudio: false,
            disableReassignAgent: false,
            openLastActiveChat: false
          },
          ...(options.options || {})
        }
      })
    );

    if (options.phoneNumber) {
      this.phoneNumber = options.phoneNumber;
    }

    if (options.userJwt) {
      this.userJwt = options.userJwt;
    }

    (async () => {
      if (options.agencyId && options.agencySecret) {
        this.agencyId = options.agencyId;
        this.agencySecret = options.agencySecret;
        const apiResult = await this.initApi();
        if (apiResult) {
          getStore().dispatch(setCredentials(options.agencyId, options.agencySecret));
          this.initSocket();
        }
      } else if (typeof window !== "undefined") {
        if (window.location.pathname.includes("/t/")) {
          this.initRecipientUX();
        }
      }
    })();
  }

  async initApi() {
    ConveyApi.init({
      agencyId: this.agencyId,
      agencySecret: this.agencySecret,
      userJwt: this.userJwt
    });

    const machineAccessTokenKey = `machineAccessToken-${this.agencyId}`;
    const cachedAccessToken = readExpire(machineAccessTokenKey);
    const machineAccessToken = cachedAccessToken ? cachedAccessToken : await ConveyApi.getMachineAccessToken();
    if (machineAccessToken.error) {
      this.handleError(new Error(machineAccessToken.error));
      return;
    }
    if (machineAccessToken) {
      ConveyApi.writeAccessToken(machineAccessToken);
      const tokenData = jwtDecode(machineAccessToken);
      writeExpire(machineAccessTokenKey, machineAccessToken, tokenData.exp * 1000);
    }

    const storedOperatorId = this.read("operatorId");

    if (storedOperatorId) {
      this.operatorId = storedOperatorId;
    }

    const basicDataKey = `basicData-${this.agencyId}`;
    const cachedBasicData = readExpire(basicDataKey);
    const basicData = cachedBasicData ? cachedBasicData : await ConveyApi.getBasicData();
    if (basicData.error) {
      this.handleError(new Error(basicData.error));
      return;
    }
    const basicDataExpiry = new Date().getTime() + 30 * 60 * 1000;
    writeExpire(basicDataKey, basicData, basicDataExpiry);

    this.voiceDialects = basicData.voiceDialects;
    this.textDialects = basicData.textDialects;
    getStore().dispatch(setVoiceDialects(basicData.voiceDialects));
    getStore().dispatch(setTextDialects(basicData.textDialects));
    this.agencyName = basicData.agencyName;
    getStore().dispatch(setAgencyIdName(this.agencyId, this.agencyName));
    if (basicData.sso) {
      const auth0Client = await createAuth0Client({
        domain: basicData.sso.ssoHost,
        clientId: basicData.sso.ssoClientId,
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        cacheLocation: "localstorage"
      })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });

      if (auth0Client) {
        const isAuthenticated = await auth0Client
          .isAuthenticated()
          .then((res) => {
            return res;
          })
          .catch((err) => {
            console.log(err);
            return false;
          });

        if (isAuthenticated) {
          const userAccessToken = await auth0Client.getTokenSilently();
          const decodedToken = jwtDecode(userAccessToken);
          if (decodedToken[SDK.ID_TOKEN]) {
            this.operatorId = decodedToken[SDK.ID_TOKEN];
            getStore().dispatch(setOperatorId(this.operatorId));
          }
          ConveyApi.writeAccessToken(userAccessToken);
          return true;
        }
        const query = window.location.search;
        if (query.includes("code=") && query.includes("state=")) {
          await auth0Client.handleRedirectCallback().catch((e) => {
            if (e) {
              window.location.pathname = "/";
            }
          });
          window.history.replaceState({}, document.title, "/");

          const userAccessToken = await auth0Client.getTokenSilently();
          const decodedToken = jwtDecode(userAccessToken);
          if (decodedToken[SDK.ID_TOKEN]) {
            this.operatorId = decodedToken[SDK.ID_TOKEN];
            getStore().dispatch(setOperatorId(this.operatorId));
          }
          ConveyApi.writeAccessToken(userAccessToken);
          return true;
        } else {
          await auth0Client
            .loginWithRedirect({
              authorizationParams: {
                audience: basicData.sso.apiAudience,
                redirect_uri: window.location.href,
                scope: "offline_access"
              }
            })
            .catch((err: any) => {
              console.log(err);
            });
        }
      }
      return false;
    } else {
      return true;
    }
  }

  async upsertRecipientState(token: string, tokenKeyPrefix: string) {
    this.recipientFlow = true;
    let currentTokenData = this.read(`${tokenKeyPrefix}-${token}`);
    if (!currentTokenData) {
      const tokenData = await ConveyApi.getToken({ token });
      this.write(`${tokenKeyPrefix}-${token}`, tokenData);
      currentTokenData = tokenData;
    }
    this.agencyId = currentTokenData.agency_id;
    ConveyApi.agencyId = currentTokenData.agency_id;
    ConveyApi.writeAccessToken(currentTokenData.access_token);
    const basicData = await ConveyApi.getBasicData();
    if (basicData.error) {
      this.handleError(new Error(basicData.error));
      return;
    }
    this.agencyId = basicData.agencyId;
    this.agencyName = basicData.agencyName;
    this.voiceDialects = basicData.voiceDialects;
    this.textDialects = basicData.textDialects;
    getStore().dispatch(setVoiceDialects(basicData.voiceDialects));
    getStore().dispatch(setTextDialects(basicData.textDialects));
    getStore().dispatch(setAgencyIdName(this.agencyId, this.agencyName));
    this.initSocket();
    const conversationId = currentTokenData.token_data.conversation_id;
    this.conversation = await ConveyApi.getConversation({ id: conversationId });
    this.phoneNumber = currentTokenData.token_data.recipient;
    this.recipientId = currentTokenData.recipient_id;
    this.recipientNumber = currentTokenData.token_data.recipient;
    getStore().dispatch(setConversationEntity(this.conversation.conversation_id, this.conversation));

    this.write("conversation", this.conversation);
    this.write("phoneNumber", this.phoneNumber);

    ConveySocket.getInstance().socketEmit(ConveySocket.JOIN_CONVERSATION_CMD, this.conversation.conversation_id);

    this.write("recipientJwtToken", currentTokenData.access_token);

    return currentTokenData;
  }

  initRecipientUX() {
    const token = window.location.pathname.split("/t/")[1];

    (async () => {
      const currentTokenData = await this.upsertRecipientState(token, "tokenData");
      if (currentTokenData.service_type === "createVideoRoomToken") {
        const videoRoomId = currentTokenData.token_data.room_id;
        this.performVideoTranscribe = currentTokenData.token_data.enableTranscription === "true";
        this.enableAudio = currentTokenData.token_data.enableAudio === "true";
        this.enableVideo = currentTokenData.token_data.enableVideo === "true";
        this.blur = currentTokenData.token_data.blur === "true";
        this.$container = "root";
        document.getElementById("root").style.height = "100vh";
        this.renderLoading("video", this.$container);
        await this.createVideoRoom(videoRoomId);
      } else if (["addRecipientGeolocation", "joinConversation"].includes(currentTokenData.service_type)) {
        this.$smsContainer = "smsContainer";
        document.getElementById("smsContainer").style.height = "100vh";
        this.renderLoading("sms", this.$smsContainer);
        await this.initRecipientSMSChat(currentTokenData.service_type === "addRecipientGeolocation");
      }
    })();
  }

  async initRecipientSMSChat(locationRequest = false) {
    getStore().dispatch(setNoLoading());

    await delay(1);

    this.sms = new ConveySMS({
      recipient: true,
      dialects: [],
      ui: true,
      $container: this.$smsContainer,
      conversationId: this.conversation.conversation_id,
      identity: this.phoneNumber,
      agencyId: this.agencyId,
      recipientId: this.recipientId,
      phoneNumber: this.phoneNumber,
      locationRequest,
      root: this.loading.sms ? this.loading.sms.getRoot() : null,
      onSMS: (message: IMessageEntity) => {
        this.onSMSCallback && this.onSMSCallback(message);
      }
    });

    getStore().dispatch(showContainer(this.phoneNumber, "sms"));
  }

  async initVideo() {
    if (this.conveyVideo) {
      this.conveyVideo.destroy && this.conveyVideo.destroy();
    }
    getStore().dispatch(setVideoRoomNoShow());
    getStore().dispatch(setNoLoading());
    getStore().dispatch(setVideoRoomLastActivity("initVideo"));

    await delay(1);

    this.conveyVideo = new ConveyVideo({
      phoneNumber: this.phoneNumber,
      roomName: this.videoRoomResourceId,
      videoToken: this.videoRoomToken,
      $container: this.$container,
      $transcriptionContainer: this.$transcriptionContainer,
      dialects: this.voiceDialects,
      conversationId: this.conversation.conversation_id,
      identity: this.recipientFlow ? this.phoneNumber : this.agencyName,
      agencyId: this.agencyId,
      recipientId: this.recipientId,
      recipientNumber: this.recipientNumber,
      currentRooms: this.currentRooms,
      correlationId: this.correlationId,
      roomId: this.videoRoomId,
      blur: this.blur === false ? false : true,
      enableAudio: this.enableAudio,
      enableVideo: this.enableVideo,
      enableTranscribe: this.performVideoTranscribe,
      recipientFlow: !!this.recipientFlow,
      root: this.loading.video ? this.loading.video.getRoot() : null,
      onError: async (error: Error) => {
        await this.handleError(error);
      },
      onVideoRoomEnded: async (room: IConveyVideoRoom) => {
        this.onVideoRoomEndedCallback && this.onVideoRoomEndedCallback(room);
      },
      onVideoRoomParticipantRemoved: async (participant: IVideoRoomParticipant) => {
        this.onVideoRoomParticipantRemovedCallback && this.onVideoRoomParticipantRemovedCallback(participant);
      },
      onVideoRoomParticipantAdded: async (participant: IVideoRoomParticipant) => {
        this.onVideoRoomParticipantAddedCallback && this.onVideoRoomParticipantAddedCallback(participant);
      }
    });

    this.sdkExperience = "video";

    getStore().dispatch(showContainer(this.phoneNumber, "video"));
  }

  conveyChat(params: IConveySMSChatProps) {
    if (SDK.READY) {
      this.initSMSChat(params);
    } else {
      this.readyCallStack.push({
        method: "conveyChat",
        args: [params]
      });
    }
    return this;
  }

  smsChat(params: IConveySMSChatProps) {
    return this.conveyChat(params);
  }

  joinConveyChat({ $container }: IConveyJoinSMSChatParams) {
    const currentParams = this.read("smsChatParams") || { ui: true };
    const newParams = {
      ...currentParams,
      phoneNumber: this.phoneNumber,
      $container
    };
    return this.conveyChat(newParams);
  }

  joinSmsChat(params) {
    return this.joinConveyChat(params);
  }

  getConveyChats(callback: (response: any) => void) {
    if (SDK.READY) {
      (async () => {
        const response = await ConveyApi.getConversations({
          status: "active"
        });
        callback && callback(response);
      })();
    } else {
      this.readyCallStack.push({
        method: "getConveyChats",
        args: [callback]
      });
    }
    return this;
  }

  hasConveyChat(
    { phoneNumber }: IConveyHasSMSChatParams,
    callback: (exists: boolean, params: IConveyHasSMSChatParams) => void
  ) {
    if (SDK.READY) {
      (async () => {
        const response = await ConveyApi.getConversations({
          recipient: phoneNumber.replace("+1", ""),
          status: "active"
        });
        callback && callback(response.length > 0, { phoneNumber });
      })();
    } else {
      this.readyCallStack.push({
        method: "hasConveyChat",
        args: [{ phoneNumber }, callback]
      });
    }
    return this;
  }

  hasSmsChat(params, callback) {
    return this.hasConveyChat(params, callback);
  }

  isChatActive() {
    return this.chatActive;
  }

  endConveyChat({ message }: IConveyEndSMSChatParams, callback: (success: boolean) => void) {
    if (SDK.READY) {
      (async () => {
        const conversationResponse = await ConveyApi.getConversations({
          recipient: this.phoneNumber.replace("+1", ""),
          status: "active"
        });
        if (conversationResponse.length > 0) {
          const id = conversationResponse[0].conversation_id;
          await ConveyApi.closeConversation({ id, message }).catch(() => null);
        }
        callback && callback(true);
        this.sms && this.sms.destroy();
        this.chatActive = false;
        this.onSMSChatEndedCallback && this.onSMSChatEndedCallback(true);
        callback && callback(false);
      })();
    } else {
      this.readyCallStack.push({
        method: "endSmsChat",
        args: [{ message }, callback]
      });
    }
    return this;
  }

  endSmsChat(params, callback) {
    return this.endConveyChat(params, callback);
  }

  async initSMSChat(params: IConveySMSChatProps) {
    this.$smsContainer = params.$container;
    this.smsUi = params.ui;
    this.greetingMessage = params.greetingMessage || "";
    this.smsLanguage = params.language || "";
    this.chatConversationId = params.conversationId;
    this.renderLoading("sms", params.$container);

    this.write("smsChatParams", {
      phoneNumber: this.phoneNumber,
      $container: this.$smsContainer,
      ui: this.smsUi,
      greetingMessage: this.greetingMessage,
      language: this.smsLanguage
    });

    (async () => {
      await this.initConversation();
      await this.initSMS();
    })();
  }

  async initSMS() {
    if (this.sms) {
      this.sms.destroy && this.sms.destroy();
    }
    getStore().dispatch(setNoLoading());
    getStore().dispatch(setVideoNoShow());
    getStore().dispatch(setRoomJoinedStatus(false));

    await delay(1);

    this.chatActive = true;
    this.sms = new ConveySMS({
      recipient: false,
      dialects: this.textDialects,
      ui: this.smsUi,
      greetingMessage: this.greetingMessage,
      smsLanguage: this.smsLanguage,
      $container: this.$smsContainer,
      conversationId: this.conversation.conversation_id,
      identity: this.agencyName,
      agencyId: this.agencyId,
      recipientId: this.recipientId,
      phoneNumber: this.phoneNumber,
      root: this.loading.sms ? this.loading.sms.getRoot() : null,
      onSMS: (message: IMessageEntity) => {
        this.onSMSCallback && this.onSMSCallback(message);
      },
      onSMSEnded: (closed: boolean) => {
        this.chatActive = false;
        this.onSMSChatEndedCallback && this.onSMSChatEndedCallback(closed);
      }
    });

    this.sdkExperience = "chat";

    getStore().dispatch(showContainer(this.phoneNumber, "sms"));
  }

  onMessage(callback: () => void) {
    this.onSMSCallback = callback;
    return this;
  }

  onEvent(callback: () => void) {
    this.onEventCallback = callback;
    return this;
  }

  locationRequest({ phoneNumber, message }: IConveyLocationRequestParams, callback: (success: boolean) => void) {
    if (SDK.READY) {
      (async () => {
        const conversationResponse = await ConveyApi.getConversations({
          recipient: phoneNumber.replace("+1", ""),
          status: "active"
        });
        if (conversationResponse.length > 0) {
          const conversationId = conversationResponse[0].conversation_id;
          const recipientId = this.conversation.recipients.filter(Boolean)[0].recipient_id;
          const requestResponse = await ConveyApi.requestLocation({
            conversationId,
            recipientId,
            agencyId: this.agencyId,
            phoneNumber,
            message
          }).catch(() => null);
          if (requestResponse) {
            callback && callback(true);
            return;
          }
        }
        callback && callback(false);
      })();
    } else {
      this.readyCallStack.push({
        method: "locationRequest",
        args: [{ phoneNumber, message }, callback]
      });
    }
    return this;
  }

  videoCall(params: IConveyVideoCallProps) {
    if (SDK.READY) {
      this.initVideoCall(params);
    } else {
      this.readyCallStack.push({
        method: "videoCall",
        args: [params]
      });
    }
    return this;
  }

  endVideoCall(callback: (success: boolean) => void) {
    (async () => {
      const roomId = getStoreState().app?.activeRoom?.room_id;
      if (roomId) {
        ConveySocket.getInstance().socketEmit(ConveySocket.LEAVE_VIDEO_ROOM_CMD, roomId);
        await ConveyApi.deleteVideoRoom({ roomId });
        getStore().dispatch(setVideoNoShow());
        getStore().dispatch(setRoomJoinedStatus(false));
        await this.getVideoRooms();
        callback && callback(true);
      } else {
        callback && callback(false);
      }
    })();
    return this;
  }

  async getVideoRooms() {
    const currentRooms = await ConveyApi.getVideoRooms({
      conversationId: this.conversation.conversation_id,
      status: "active"
    });
    getStore().dispatch(setVideoRoomCount(currentRooms.length));
    getStore().dispatch(setActiveRoomData(null));
  }

  leaveVideoCall(callback: (success: boolean) => void) {
    if (this.conveyVideo?.leaveVideoCall()) {
      callback && callback(true);
      (async () => {
        const currentRooms = await ConveyApi.getVideoRooms({
          conversationId: this.conversation.conversation_id,
          status: "active"
        });
        setVideoRoomCount(currentRooms.length);
      })();
    } else {
      callback && callback(false);
    }
    return this;
  }

  joinVideoCall({ $container }: IConveyJoinVideoCallParams) {
    const currentParams = this.read("videoCallParams") || SDK.DEFAULT_VIDEO_PARAMS;
    const newParams = {
      ...currentParams,
      phoneNumber: this.phoneNumber,
      $container
    };
    return this.videoCall(newParams);
  }

  hasVideoCall({ phoneNumber }: IConveyHasSMSChatParams, callback: (exists: boolean) => void) {
    if (SDK.READY) {
      (async () => {
        const response = await ConveyApi.getVideoRooms({
          status: "active",
          recipient: phoneNumber
        });
        callback && callback(response.length > 0);
      })();
    } else {
      this.readyCallStack.push({
        method: "hasVideoCall",
        args: [{ phoneNumber }, callback]
      });
    }
    return this;
  }

  async initVideoCall(params: IConveyVideoCallProps) {
    this.$container = params.$container;
    this.$transcriptionContainer = params.$transcriptionContainer;
    this.invitationMessage = params.invitationMessage;
    this.enableAudio = params.enableAudio;
    this.enableVideo = params.enableVideo;
    this.blur = params.blur;
    this.performVideoTranscribe = params.transcribe;
    this.performVideoRecord = params.record;
    this.joinVideoRoomParams = params.joinVideoRoomParams || {};
    this.renderLoading("video", params.$container);

    if (this.joinVideoRoomParams.conversationId) {
      this.conversation = await ConveyApi.getConversation({ id: this.joinVideoRoomParams.conversationId });
      this.write("conversation", this.conversation);
      this.write("phoneNumber", this.phoneNumber);
    }

    this.write("videoCallParams", {
      phoneNumber: this.phoneNumber,
      $container: this.$container,
      invitationMessage: this.invitationMessage,
      enableVideo: this.enableVideo,
      enableAudio: this.enableAudio,
      blur: this.blur,
      transcribe: this.performVideoTranscribe,
      $transcriptionContainer: this.$transcriptionContainer,
      record: this.performVideoRecord,
      joinVideoRoomParams: this.joinVideoRoomParams
    });

    (async () => {
      const storedConversationId = this.read("conversation");
      if (storedConversationId) {
        this.currentRooms = await ConveyApi.getVideoRooms({
          conversationId: storedConversationId.conversation_id,
          status: "active"
        });
        if (this.currentRooms.length > 0) {
          await this.joinVideoRoom(this.currentRooms[0].room_id);
        } else {
          await this.initConversation();
          await this.createVideoRoom();
        }
      } else {
        await this.initConversation();
        await this.createVideoRoom();
      }
    })();
  }

  async joinVideoRoom(roomId) {
    this.write("phoneNumber", this.phoneNumber);
    const storedConversationId = this.read("conversation");
    if (storedConversationId) {
      this.conversation = this.read("conversation");
    } else {
      await this.initConversation();
    }
    ConveySocket.getInstance().socketEmit(ConveySocket.JOIN_CONVERSATION_CMD, this.conversation.conversation_id);
    await this.createVideoRoom(roomId);
  }

  async initRoom() {
    getStore().dispatch(setVideoRoomShow());
    getStore().dispatch(setNoLoading());
    getStore().dispatch(setVideoRoomMaximized());

    await delay(1);

    this.room = new ConveyRoom({
      phoneNumber: this.phoneNumber,
      $container: this.$container,
      currentRooms: this.currentRooms,
      root: this.loading.video ? this.loading.video.getRoot() : null,
      onJoin: async (roomId) => {
        ConveySocket.joinConversation(this.conversation.conversation_id);
        await this.createVideoRoom(roomId);
      }
    });
  }

  async createVideoRoom(currentRoomId = null) {
    if (currentRoomId) {
      this.videoRoomId = currentRoomId;
      const videoRoomResponse = await ConveyApi.getVideoRoom({
        roomId: currentRoomId
      });
      getStore().dispatch(setActiveRoomData(videoRoomResponse));
      this.performVideoTranscribe = videoRoomResponse.transcription;
      this.performVideoRecord = videoRoomResponse.record;
      this.enableVideo = videoRoomResponse.init_params?.enable_video || false;
      this.enableAudio = videoRoomResponse.init_params?.enable_audio || false;
      this.blur = videoRoomResponse.init_params?.blur || true;
    } else {
      const videoRoomResponse = await ConveyApi.createVideoRoom({
        recipientId: this.recipientId,
        conversationId: this.conversation.conversation_id,
        transcribe: this.performVideoTranscribe === false ? false : true,
        record: this.performVideoRecord === false ? false : true,
        blur: this.blur === false ? false : true,
        enableAudio: this.enableAudio === false ? false : true,
        enableVideo: this.enableVideo === false ? false : true
      });
      getStore().dispatch(setActiveRoomData(videoRoomResponse));
      this.videoRoomId = videoRoomResponse.room_id;
      await ConveyApi.createVideoTokens({
        videoRoomId: this.videoRoomId,
        recipientId: this.recipientId,
        phoneNumber: this.phoneNumber,
        conversationId: this.conversation.conversation_id,
        invitationMessage: this.invitationMessage,
        enableTranscription: this.performVideoTranscribe === false ? false : true,
        enableAudio: this.enableAudio === false ? false : true,
        enableVideo: this.enableVideo === false ? false : true,
        blur: this.blur === false ? false : true,
        performVideoRecord: this.performVideoRecord === false ? false : true
      });
    }
    const createVideoRoomTokenResponse = await ConveyApi.createVideoRoomToken({
      videoRoomId: this.videoRoomId,
      participant: this.recipientFlow ? this.phoneNumber : this.agencyName
    });
    if (createVideoRoomTokenResponse.status === 200 || createVideoRoomTokenResponse.status === 201) {
      this.videoRoomToken = createVideoRoomTokenResponse.data.access_token;
      this.videoRoomResourceId = createVideoRoomTokenResponse.data.resource_id;
      await this.initVideo();
    } else {
      if (createVideoRoomTokenResponse.status === 400) {
        alert("Video session has ended");
      } else if (createVideoRoomTokenResponse.data.error) {
        alert(`Error joining video room. ${createVideoRoomTokenResponse.data.error}`);
      }
      getStore().dispatch(setVideoRoomNoShow());
      getStore().dispatch(setNoLoading());
    }
  }

  async initConversation() {
    if (this.chatConversationId) {
      this.conversation = await ConveyApi.getConversation({ id: this.chatConversationId });
      if (!this.conversation || this.conversation?.status !== "active") {
        this.conversation = await ConveyApi.createConversation({
          phoneNumber: this.phoneNumber,
          language: this.smsLanguage || "en",
          message: this.greetingMessage,
          operatorId: this.operatorId
        });
      }
      getStore().dispatch(setConversationEntity(this.conversation.conversation_id, this.conversation));
    } else {
      this.conversation = await ConveyApi.createConversation({
        phoneNumber: this.phoneNumber,
        language: this.smsLanguage || "en",
        message: this.greetingMessage,
        operatorId: this.operatorId
      });
    }
    if (!this.recipientId) {
      this.recipientId = this.conversation.recipients.filter(Boolean)[0].recipient_id;
      this.recipientNumber = this.conversation.recipients.filter(Boolean)[0].number;
    }

    this.write("conversation", this.conversation);
    this.write("phoneNumber", this.phoneNumber);
    ConveySocket.getInstance().socketEmit(ConveySocket.JOIN_CONVERSATION_CMD, this.conversation.conversation_id);
  }

  adminCRUD(params: IConveyAdminCRUDProps) {
    if (SDK.READY) {
      this.initAdminCRUD(params);
    } else {
      this.renderLoading("adminCRUD", this.$adminCRUDContainer);
      this.readyCallStack.push({
        method: "adminCRUD",
        args: [params]
      });
    }
    return this;
  }

  initAdminCRUD(params: IConveyAdminCRUDProps) {
    this.$adminCRUDContainer = params.$container;

    (async () => {
      const basicDataKey = `basicData-${this.agencyId}`;
      const basicData = await ConveyApi.getBasicData();
      if (basicData.error) {
        this.handleError(new Error(basicData.error));
        return;
      }
      const basicDataExpiry = new Date().getTime() + 30 * 60 * 1000;
      writeExpire(basicDataKey, basicData, basicDataExpiry);
      this.initCRUDAdmin();
    })();

    return this;
  }

  initCRUDAdmin() {
    getStore().dispatch(setNoLoading());

    this.conveyAdminCRUD = new ConveyAdminCRUD({
      $container: this.$adminCRUDContainer,
      root: this.loading.adminCRUD ? this.loading.adminCRUD.getRoot() : null,
      callback: this.onAdminCRUDSaveCallback
    });

    getStore().dispatch(showContainer("adminCRUD", "adminCRUD"));
  }

  onAdminCRUDSave(callback: (_eventData: Record<any, any>) => void) {
    this.onAdminCRUDSaveCallback = callback;
    return this;
  }

  callSimulator(params: IConveyCallSimulatorProps) {
    if (SDK.READY) {
      this.initCallSimulator(params);
    } else {
      this.readyCallStack.push({
        method: "callSimulator",
        args: [params]
      });
    }
    return this;
  }

  initCallSimulator(params: IConveyCallSimulatorProps) {
    this.$callSimulatorContainer = params.$container;
    this.callSimulatorConversationParams = params.conversationParams;

    (async () => {
      this.renderLoading("callSimulator", this.$campaignContainer);
      this.initSimulator();
    })();
    return this;
  }

  initSimulator() {
    getStore().dispatch(setNoLoading());

    this.conveyCallSimulator = new ConveyCallSimulator({
      $container: this.$callSimulatorContainer,
      conversationParams: this.callSimulatorConversationParams,
      root: this.loading.campaign ? this.loading.campaign.getRoot() : null
    });

    getStore().dispatch(showContainer("callSimulator", "callSimulator"));
  }

  async bindUserToAgency() {
    ConveySocket.getInstance().socketEmit(ConveySocket.CONNECT_AGENCY_FEED_CMD, {
      agencyId: this.agencyId,
      token: this.userJwt
    });
  }

  initSocket() {
    ConveySocket.init(ConveyApi.readAccessToken())
      .listen(ConveySocket.AGENCY_CONNECTED, async () => {
        SDK.READY = true;
        this.onReadyCallback && this.onReadyCallback({ version: CONVEY_SDK_VERSION });

        const currentParams = this.read("smsChatParams");
        const phoneNumberIsCurrent = currentParams && currentParams.phoneNumber === this.phoneNumber;

        this.readyCallStack.forEach((call) => {
          this[call.method](...call.args);
          if (call.method === "conveyChat" && phoneNumberIsCurrent) {
            SDK.SELF_OPEN_LAST_ACTIVE_CHAT = true;
          }
          this.readyCallStack.shift();
        });

        const openLastActiveChat = getStoreState().app?.initOptions?.options?.openLastActiveChat;
        if (openLastActiveChat && !SDK.SELF_OPEN_LAST_ACTIVE_CHAT && phoneNumberIsCurrent) {
          this.conveyChat(currentParams);
          SDK.SELF_OPEN_LAST_ACTIVE_CHAT = true;
        }

        ConveySocket.CHAT_ON_EVENTS.forEach((event) => {
          ConveySocket.init(ConveyApi.readAccessToken()).listen(event, async (data) => {
            if (this.onEventCallback) {
              this.onEventCallback(`chat:${event}`, data);
            }
          });
        });
      })
      .listen(ConveySocket.SESSION, async (socketSessionId) => {
        this.sessionId = socketSessionId;

        getStore().dispatch(setSessionId(socketSessionId));
        await this.bindUserToAgency();
      })
      .listen(ConveySocket.CLOSE_VIDEO_ROOM, async (data: any) => {
        if (this.conveyVideo?.roomId === data?.room_id) {
          getStore().dispatch(setVideoNoShow());
          getStore().dispatch(setRoomJoinedStatus(false));
          await this.getVideoRooms();
          this.conveyVideo?.endVideoCall();
          if (this.recipientFlow) {
            setTimeout(() => {
              alert("Video session has ended");
            }, 1500);
          }
        }
      })
      .listen(ConveySocket.CLOSE_CONVERSATION, async (data: any) => {
        const phoneNumber = data.primary_recipient;
        const sdkInstance = SDK.getInstance({ phoneNumber });
        if (sdkInstance) {
          if (sdkInstance.sdkExperience === "video") {
            sdkInstance.endVideoCall();
          }
          if (sdkInstance.sdkExperience === "chat") {
            sdkInstance.endConveyChat({ message: getStoreState().agency.settings.closedConversationMessage });
          }
        }
      });
  }

  transcribeCall(params: IConveyTranscribeCallProps) {
    if (SDK.READY) {
      this.initTranscribeCall(params);
    } else {
      this.readyCallStack.push({
        method: "transcribeCall",
        args: [params]
      });
    }
    return this;
  }

  initTranscribeCall({
    correlationId,
    $container,
    $summaryContainer,
    $sentimentContainer,
    $keywordsContainer,
    callerLanguage,
    neuralName,
    talkback,
    conversationId,
    showUploader,
    agentAudio,
    recipientAudio,
    dualChannel
  }: IConveyTranscribeCallProps) {
    this.correlationId = correlationId;
    this.$sipContainer = $container;
    this.$summaryContainer = $summaryContainer;
    this.$sentimentContainer = $sentimentContainer;
    this.$keywordsContainer = $keywordsContainer;
    this.neuralName = neuralName;
    this.showUploader = showUploader || false;
    this.talkback = talkback || false;
    this.transcribeConversationId = conversationId;
    this.callerLanguage = callerLanguage;
    this.dualChannel = dualChannel;
    this.agentAudio = agentAudio;
    this.recipientAudio = recipientAudio;
    (async () => {
      if (this.transcribeConversationId) {
        const localConversation = await ConveyApi.getConversation({ id: this.transcribeConversationId });
        getStore().dispatch(setConversationEntity(this.transcribeConversationId, localConversation));
      }
      this.renderLoading("transcribe", $container);
      this.initTranscribe();
    })();
    return this;
  }

  initTranscribe() {
    if (this.transcribe) {
      this.transcribe.destroy && this.transcribe.destroy();
    }
    getStore().dispatch(setNoLoading());

    this.transcribe = new ConveyTranscribe({
      correlationId: this.correlationId,
      root: this.loading.transcribe ? this.loading.transcribe.getRoot() : null,
      $container: this.$sipContainer,
      $summaryContainer: this.$summaryContainer,
      $sentimentContainer: this.$sentimentContainer,
      $keywordsContainer: this.$keywordsContainer,
      talkback: this.talkback,
      callerLanguage: this.callerLanguage,
      neuralName: this.neuralName,
      showUploader: this.showUploader,
      conversationId: this.transcribeConversationId,
      recipientAudio: this.recipientAudio,
      dualChannel: this.dualChannel,
      agentAudio: this.agentAudio,
      callback: this.onTranscribeCallback,
      onKeywordCallback: this.onKeywordCallback,
      onSentimentScoreCallback: this.onSentimentScoreCallback,
      onSummaryCallback: this.onSummaryCallback,
      onLiveAudioCallback: this.onLiveAudioCallback
    });

    this.sdkExperience = "transcribe";

    getStore().dispatch(showContainer(this.correlationId, "transcribe"));
  }

  conveyConnectCall(params: IConveyConnectCallProps) {
    if (SDK.READY) {
      this.initConveyConnectCall(params);
    } else {
      this.readyCallStack.push({
        method: "conveyConnectCall",
        args: [params]
      });
    }
    return this;
  }

  initConveyConnectCall({ $container, callType }: IConveyConnectCallProps) {
    this.$connectContainer = $container;
    this.connectCallType = callType;
    this.renderLoading("connect", $container);
    this.conveyConnect();
    return this;
  }

  conveyConnect() {
    if (this.connect) {
      this.connect.destroy && this.connect.destroy();
    }
    getStore().dispatch(setNoLoading());

    this.connect = new ConveyConnect({
      $container: this.$connectContainer,
      root: this.loading.connect ? this.loading.connect.getRoot() : null,
      callType: this.connectCallType || "audio"
    });

    this.sdkExperience = "connect";

    getStore().dispatch(showContainer(this.correlationId, "connect"));
  }

  onTranscribe(callback: (_eventData: Record<any, any>) => void) {
    this.onTranscribeCallback = callback;
    return this;
  }

  onLiveAudio(callback: (_eventData: Record<any, any>, dualChannel: boolean) => void) {
    this.onLiveAudioCallback = callback;
    return this;
  }

  onKeyword(callback: (_eventData: Record<any, any>, isNew: boolean) => void) {
    this.onKeywordCallback = callback;
    return this;
  }

  onSentimentScore(callback: (_eventData: Record<any, any>, isNew: boolean) => void) {
    this.onSentimentScoreCallback = callback;
    return this;
  }

  onSummary(callback: (_eventData: string, isNew: boolean) => void) {
    this.onSummaryCallback = callback;
    return this;
  }

  onReady(callback: () => void) {
    this.onReadyCallback = callback;
    return this;
  }

  embedCampaign(params: IConveyEmbedCampaignProps) {
    if (SDK.READY) {
      this.initEmbedCampaign(params);
    } else {
      this.campaignId = params.campaignId;
      this.$campaignContainer = params.$container;
      this.renderLoading("campaign", this.$campaignContainer);
      this.readyCallStack.push({
        method: "embedCampaign",
        args: [params]
      });
    }
    return this;
  }

  initEmbedCampaign(params: IConveyEmbedCampaignProps) {
    this.campaignId = params.campaignId;
    this.$campaignContainer = params.$container;
    (async () => {
      const campaignResponse = await ConveyApi.getCampaignGroup({ campaignId: this.campaignId });
      this.campaigns = campaignResponse.filter((c: ICampaignEntity) => c.status === "active");
      this.initCampaign();
    })();
    return this;
  }

  async initCampaign() {
    if (this.conveyCampaign) {
      this.conveyCampaign.destroy && this.conveyCampaign.destroy();
    }
    getStore().dispatch(setNoLoading());

    this.conveyCampaign = new ConveyCampaign({
      $container: this.$campaignContainer,
      campaignId: this.campaignId,
      campaigns: this.campaigns,
      root: this.loading.campaign ? this.loading.campaign.getRoot() : null,
      callback: this.onCampaignSubscribeCallback
    });

    getStore().dispatch(showContainer(this.campaignId, "campaign"));
  }

  onCampaignSubscribe(callback: (_eventData: Record<any, any>) => void) {
    this.onCampaignSubscribeCallback = callback;
    return this;
  }

  textTranslate(params: ITextTranslateParams, callback: (result: string) => void) {
    this.onTranslateCallback = callback;
    if (SDK.READY) {
      this.translateParams = params;
      this.performTranslate();
    } else {
      this.readyCallStack.push({
        method: "textTranslate",
        args: [params, callback]
      });
    }
    return this;
  }

  performTranslate() {
    (async () => {
      const result = await ConveyApi.postTextTranslate({
        text: this.translateParams.inputText,
        language: this.translateParams.outputLanguage
      });
      this.onTranslateCallback && this.onTranslateCallback(result);
    })();
  }

  read(key: string) {
    return JSON.parse(store().getItem(key));
  }

  delete(key: string) {
    return store().removeItem(key);
  }

  write(key: string, value: any) {
    return store().setItem(key, JSON.stringify(value));
  }

  onError(callback: () => void) {
    this.onErrorCallback = callback;
    return this;
  }

  onSMSChatEnded(callback: (boolean) => void) {
    this.onSMSChatEndedCallback = callback;
    return this;
  }

  onVideoRoomEnded(callback: (room: IConveyVideoRoom) => void) {
    this.onVideoRoomEndedCallback = callback;
    return this;
  }

  onVideoRoomParticipantRemoved(callback: (participant: IVideoRoomParticipant) => void) {
    this.onVideoRoomParticipantRemovedCallback = callback;
    return this;
  }

  onVideoRoomParticipantAdded(callback: (participant: IVideoRoomParticipant) => void) {
    this.onVideoRoomParticipantAddedCallback = callback;
    return this;
  }

  async handleError(error: Error) {
    this.onErrorCallback && this.onErrorCallback(error);
  }

  renderLoading(experience: TContainerExperience, $container: string) {
    if (this.loading[experience]) {
      this.loading[experience].destroy && this.loading[experience].destroy();
      this.unsetRoot(experience);
    }
    getStore().dispatch(setLoading());
    this.loading[experience] = new ConveyLoading({
      $container,
      root: null,
      experience,
      phoneNumber: this.phoneNumber || this.correlationId || this.campaignId || this.connectCallType
    });
    this.lastLoaded = experience;
    this.loaded = true;
  }

  unsetRoot(experience: TContainerExperience) {
    switch (experience) {
      case "sms":
        if (this.sms) {
          this.sms.root = null;
        }
        return;
      case "transcribe":
        if (this.transcribe) {
          this.transcribe.root = null;
        }
        return;
      case "video":
        if (this.conveyVideo) {
          this.conveyVideo.root = null;
        }
        return;
      case "campaign":
        if (this.conveyCampaign) {
          this.conveyCampaign.root = null;
        }
        return;
      case "connect":
        if (this.connect) {
          this.connect.root = null;
        }
        return;
    }
  }

  static agentVideoInit() {
    window.SDK ||= { instances: {} };
    window.SDK.instances ||= {};
    // URL Template: /o/:agencyId/:conversationId/:roomId?agencySecret=:agencySecret
    const urlParts = window.location.pathname.split("/");
    const params = new URLSearchParams(window.location.search);
    const agencyId = urlParts[2];
    const conversationId = urlParts[3];
    const videoRoomId = urlParts[4];
    const agencySecret = params.get("agencySecret");
    let phoneNumber = "";
    (async () => {
      ConveyApi.init({
        agencyId,
        agencySecret
      });
      const machineAccessToken = await ConveyApi.getMachineAccessToken();
      if (machineAccessToken.error) {
        throw new Error(machineAccessToken.error);
      }
      ConveyApi.writeAccessToken(machineAccessToken);
      const localVideoRoom = await ConveyApi.getVideoRoom({ roomId: videoRoomId });
      if (!localVideoRoom || localVideoRoom.status !== "in-progress") {
        window.alert("Video room cannot be found or has already ended");
        return;
      }
      const localConversation = await ConveyApi.getConversation({ id: conversationId });
      phoneNumber = localConversation.number;
      window.SDK.instances[phoneNumber] = new SDK({
        agencyId,
        agencySecret,
        phoneNumber
      });
      document.getElementById("ConveyContainer").style.height = "100vh";
      window.SDK.instances[phoneNumber].videoCall({
        $container: "ConveyContainer",
        joinVideoRoomParams: {
          videoRoomId,
          conversationId
        },
        ...SDK.DEFAULT_VIDEO_PARAMS
      });
    })();
  }

  static init(options: IConveyOpts) {
    window.SDK ||= { instances: {} };
    window.SDK.instances ||= {};
    const phoneNumber = options.phoneNumber || "recipientUX";
    if (!window.SDK.instances[phoneNumber]) {
      window.SDK.instances[phoneNumber] = new SDK(options);
    }
    return window.SDK.instances[phoneNumber];
  }

  static getInstance({ phoneNumber }: { phoneNumber: string }) {
    window.SDK ||= { instances: {} };
    window.SDK.instances ||= {};
    if (!window.SDK.instances[phoneNumber]) {
      return null;
    }
    return window.SDK.instances[phoneNumber];
  }

  static provide() {}
}
