import { styled } from "linaria/react";
import { fontSize, borderColor, textColor, backgroundColor } from "@components/styles";

export const VideoCallModalDefaultStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    marginRight: "-50%",
    minWidth: 350,
    maxWidth: 350,
    padding: "0"
  },
  overlay: { backgroundColor: "transparent", position: "static", inset: "none" }
};

export const VideoCallModalFullScreenStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "95vw",
    maxWidth: "95vw",
    padding: "0"
  },
  overlay: { backgroundColor: "transparent", position: "static", inset: "none" }
};

export const VideoCallModalHeader = styled.div`
  padding: 4px 4px 4px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 16px;
`;

export const VideoCallModalTitle = styled.div`
  flex: 1 1 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${textColor['default']};
  font-size: ${fontSize['lg']};
  font-weight: bold;
`;

export const VideoCallModalActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const VideoCallModalBody = styled.div`
  padding: 0;

  >div {
    width: 100% !important;

    >div {
      width: 100% !important;
    }
  }
`;


export const VideoCallModalActionButton = styled.div<{ size: number, variant?: string }>`
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${props => textColor[props.variant || 'default']};
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    transform: scale(${props => 16 / props.size});
  }
`;

export const VideoCallModalViewOption = styled.div`
  padding: 2px 4px;
  cursor: pointer;
`;